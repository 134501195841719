import Vue from 'vue'
import mixin from '@/mixin'
import { SelectedProductElection } from '@/store/modules/enrollmentProducts/types'
import { Person } from '@/components/common/ui/HeaderPlain/headerPlain'
import Drawer from '@/components/common/ui/Drawer/Index.vue'
import TagStatus from '@/components/common/ui/TagStatus/Index.vue'
import ProductRider from '@/views/enrollment/product/details/productElection/productRider/ProductRider.vue'
import BeneficiariesSpans from '@/views/enrollment/product/details/productElection/eligibilitySpans/EligibilitySpans.vue'
import ProductDocuments from './product-documents/ProductDocuments.vue'
import ProductBeneficiaries from './product-beneficiaries/ProductBeneficiaries.vue'

import { DataTableHeader } from 'vuetify'

import { formatDate, base64ToBlob } from '@/helpers'

import { RequestFilter, Operators, PageableComponentInterface } from '@/store/types'
import { DocumentDto } from '@/autogen-interfaces'

export default Vue.extend({
  name: 'ProductElectionDetails',
  components: {
    Drawer,
    TagStatus,
    ProductRider,
    ProductDocuments,
    ProductBeneficiaries,
    BeneficiariesSpans,
  },
  mixins: [mixin],
  data: () => ({
    tabModel: 'tabDocuments',
    documentHeaders: [
      { text: 'Doc ID', sortable: false, width: '20%', value: 'documentID' },
      { text: 'Document Name', sortable: false, width: '40%', value: 'documentName' },
      { text: 'Date Posted', sortable: false, width: '30%', value: 'insertDate' },
      { text: 'Download', sortable: false, width: '10%', value: 'download', align: 'center' },
    ] as DataTableHeader[],
    beneficiariesHeaders: [
      { text: 'Beneficiary Name', sortable: false, width: '30%', value: 'name' },
      { text: 'Relationship', sortable: false, width: '20%', value: 'relationship' },
      { text: 'Percentage', sortable: false, width: '15%', value: 'percentage' },
      { text: 'Date of Birth', sortable: false, width: '15%', value: 'dateOfBirth' },
      { text: 'Beneficiary Type', sortable: false, width: '20%', value: 'beneficiaryType' },
    ] as DataTableHeader[],
    documentsFilter: {
      page: 1,
      pageSize: 10,
      skip: 0,
      take: 10,
      sort: [],
      filter: [],
    } as RequestFilter,
    beneficiariesFilter: {
      page: 1,
      pageSize: 10,
      skip: 0,
      take: 10,
      sort: [],
      filter: [],
    } as RequestFilter,
  }),
  updated() {
    this.tabModel = 'tabDocuments'
  },
  methods: {
    async downloadDocument(doc: DocumentDto) {
      await this.$store.dispatch('enrollment/getDocumentData', { documentId: doc.documentID })
      if (this.$store.state.enrollment.selectedDocument) {
        base64ToBlob(
          this.$store.state.enrollment.selectedDocument.documentData,
          this.$store.state.enrollment.selectedDocument.mimeType,
          doc.documentName || 'document',
          this.$store.state.enrollment.selectedDocument.fileExtension,
        )
      }
    },
    formatDate(date: string) {
      return formatDate(date)
    },
    fetchBeneficiaries() {
      this.$store.dispatch('enrollmentProducts/getMemberBeneficiaries', {
        memberId: this.$store.state.enrollmentProducts.selectedProductElection.product.memberID,
        parameterName: 'PlanElectionID',
        filter: {
          ...this.beneficiariesFilter,
          filter: [
            {
              operator: Operators.EQUAL,
              field: 'PlanElectionID',
              value:
                this.$store.state.enrollmentProducts.selectedProductElection.product.planElectionId,
              logic: '',
              filters: [],
            },
          ],
        },
      })
    },
    fetchDocuments() {
      this.$store.dispatch('enrollmentProducts/getDocuments', {
        parameterId:
          this.$store.state.enrollmentProducts.selectedProductElection.product.planElectionId,
        parameterName: 'PlanElectionID',
        filter: this.documentsFilter,
      })
    },
  },
  computed: {
    selectedProductElection(): SelectedProductElection {
      return this.$store.state.enrollmentProducts.selectedProductElection.product
    },
    person(): Person {
      return this.$store.state.enrollment.person
    },
    documentsPagination(): PageableComponentInterface {
      return {
        totalElements:
          this.$store.state.enrollmentProducts.selectedProductElection.productDocumentsTable
            .totalElements,
        totalPages:
          this.$store.state.enrollmentProducts.selectedProductElection.productDocumentsTable
            .totalPages,
      }
    },
    beneficiariesPagination(): PageableComponentInterface {
      return {
        totalElements:
          this.$store.state.enrollmentProducts.selectedProductElection.productBeneficiariesTable
            .totalElements,
        totalPages:
          this.$store.state.enrollmentProducts.selectedProductElection.productBeneficiariesTable
            .totalPages,
      }
    },
  },
  watch: {
    documentsFilter() {
      this.fetchDocuments()
    },
    beneficiariesFilter() {
      this.fetchBeneficiaries()
    },
  },
})
