import { MutationTree } from 'vuex'
import { Group, GroupUserSelection } from '@/store/modules/groupUserSelection/type'
import { loadableMutation, paginationMutation } from '@/store/mutations'

const mutations: MutationTree<GroupUserSelection> = {
  addGroupItems(state, groupItems: Group[]) {
    state.items = [...groupItems]
  },
  setUserGroupIds(state, userGroupIds: number[]) {
    state.userGroupIds = [...userGroupIds]
  },
  ...loadableMutation,
  ...paginationMutation,
}

export default mutations
