import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '700px',
    },
    contentClass: {
      type: String,
      default: '',
    },
  },
})
