import { RequestFilter, RootState } from '@/store/types'
import { AdvanceSearch, Group, GroupUserSelection } from '@/store/modules/groupUserSelection/type'
import { AppData } from '@/AppData'
import { withAsync } from '@/store/utils/withAsync'
import { ActionTreeAdaptor, ApiError } from '@/store/utils/types'
import { GroupDto } from '@/autogen-interfaces'
import { GroupEntityToDto } from '@/store/modules/groupUserSelection/EntityToDto'
import GroupsApi from '@/api/GroupsApi'

export type Actions = {
  fetchOrSelectUserGroups: (payload: {
    requestFilters: RequestFilter
    input: string
  }) => Promise<void>
  fetchUserAssociation: () => Promise<void>
  fetchUserGroups: (payload: {
    groupIds: Array<number>
    input: string
    requestFilters: RequestFilter
  }) => Promise<void>
  fetchUserGroupsAdvance: (payload: {
    advanceSearch: AdvanceSearch
    requestFilters: RequestFilter
  }) => Promise<void>
}

const actions: ActionTreeAdaptor<Actions, GroupUserSelection, RootState> = {
  async fetchOrSelectUserGroups({ commit, dispatch, state }, { requestFilters, input }) {
    await dispatch('fetchUserAssociation')
    const groupIds = state.userGroupIds
    if (groupIds.length > 1) {
      await dispatch('fetchUserGroups', { groupIds: groupIds, input, requestFilters })
    } else if (groupIds.length === 1) {
      commit('setSelectedUserGroupId', groupIds[0], { root: true })
    }
  },
  async fetchUserAssociation({ commit }) {
    commit('setLoading', true)
    commit('setUserGroupIds', AppData.associations.GroupIds)
    // commit('setUserGroupIds', [11189, 11264, 11044, 11348])
    // TODO we are not getting data from the backend, you can test with casey user and the array above to see group selection component
    commit('setLoading', false)
  },
  async fetchUserGroups({ commit }, { groupIds, input, requestFilters }) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: GroupDto[] } = await withAsync(
      GroupsApi.fetchGroupDetail,
      GroupsApi,
      groupIds,
      input,
      requestFilters,
    )
    if (error) {
      console.error(error)
    } else {
      const groupItems: Group[] = []
      if (data) {
        data.forEach((group) => {
          if (
            input &&
            input !== '' &&
            JSON.stringify(group).toLowerCase().indexOf(input.toLowerCase()) === -1
          ) {
            return
          }
          groupItems.push(GroupEntityToDto.convert({ ...group }))
        })
      }
      commit('addGroupItems', groupItems)
      commit('setPagination', { totalElements: groupItems.length, totalPages: 1 })
      commit('setLoading', false)
    }
  },
  async fetchUserGroupsAdvance({ commit, dispatch, state }, { advanceSearch, requestFilters }) {
    commit('setLoading', true)
    await dispatch('fetchUserAssociation')
    const groupIds = state.userGroupIds
    const { error, data }: { error: ApiError; data: GroupDto[] } = await withAsync(
      GroupsApi.fetchGroupDetailAdvanced,
      GroupsApi,
      groupIds,
      advanceSearch,
      requestFilters,
    )
    if (error) {
      console.error(error)
    } else {
      const groupItems: Group[] = []
      if (data) {
        data.forEach((group) => {
          groupItems.push(GroupEntityToDto.convert({ ...group }))
        })
      }
      commit('addGroupItems', groupItems)
      commit('setPagination', { totalElements: groupItems.length, totalPages: 1 })
      commit('setLoading', false)
    }
  },
}

export default actions
