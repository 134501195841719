import { Api } from '@/api/Api'
import {
  MemberDocumentsDto,
  DocumentDto,
  DocumentDataDto,
  DocumentTypeDto,
} from '@/autogen-interfaces'
import { RequestFilter } from '@/store/types'
import { AxiosPromise } from 'axios'
import { ApiResponse } from '@/store/utils/types'
import { AppData } from '@/AppData'

class DocumentsApi extends Api {
  fetchGroupDocuments(
    groupId: number,
    documentTypeID: number[],
    mimeTypeID: number[],
    requestFilter: RequestFilter,
  ) {
    return this.get('Documents/Get_GroupDocuments', {
      GroupId: groupId,
      DocumentTypeID: documentTypeID,
      MimeTypeID: mimeTypeID,
      ApplicationUser: AppData.associations.UserId,
      GridHelper: requestFilter,
    })
  }

  fetchMemberDocuments(
    memberId: number,
    memberTypeId: number,
    requestFilter: RequestFilter,
  ): AxiosPromise<ApiResponse<MemberDocumentsDto[]>> {
    return this.get('Documents/Get_MemberDocumentDetails', {
      MemberID: memberId,
      MemberTypeID: memberTypeId ? memberTypeId : 40,
      ApplicationUser: AppData.associations.UserId,
      Input: '',
      DocumentTypeID: [],
      MimeTypeID: [],
      GridHelper: requestFilter,
    })
  }

  fetchDocuments(
    parameters: any,
    requestFilter: RequestFilter,
  ): AxiosPromise<ApiResponse<DocumentDto[]>> {
    const payload: any = {
      ApplicationUser: AppData.associations.UserId,
      GridHelper: requestFilter,
    }
    Object.keys(parameters).forEach((key) => {
      payload[key] = parameters[key]
    })
    return this.get('Documents/Get_Documents', payload)
  }

  fetchDocumentData(documentID: number): AxiosPromise<ApiResponse<DocumentDataDto>> {
    return this.get('Documents/Get_DocumentData', {
      DocumentID: documentID,
    })
  }

  /**
   * Fetch document types
   *
   * @returns {AxiosPromise<DocumentTypeDto[]>}
   *
   */
  fetchDocumentTypes(): AxiosPromise<DocumentTypeDto[]> {
    return this.get('Documents/Get_DocumentTypes', { ApplicationUser: AppData.associations.UserId })
  }
}

export default new DocumentsApi()
