











import Vue from 'vue'
import EnrollmentInfoCard from '@/components/common/ui/EnrollmentInfoCard/Index.vue'

export default Vue.extend({
  name: 'EnrollmentMemberInfoMemberInfo',
  components: {
    EnrollmentInfoCard,
  },
})
