var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"col-2 ml-auto"},[_c('filter-single-date',{attrs:{"label":"As of"},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-12"},[_c('expandable-table',{attrs:{"loading":_vm.loading,"items":_vm.groupDivisions,"item-key":"groupDivisionId","headers":_vm.dataTableHeader,"pagination":_vm.pagination},on:{"request-filter":function($event){_vm.filters = $event}},scopedSlots:_vm._u([{key:"item.invoiceSetting",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInvoiceSetting(item.groupInvoiceSettingId).invoiceIdent)+" ")]}},{key:"item.invoiceId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInvoiceSetting(item.groupInvoiceSettingId).invoiceIdentID)+" ")]}},{key:"item.divisionEffDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.divisionEffDate ? _vm.formatDate(item.divisionEffDate) : 'Not provided')+" ")]}},{key:"item.divisionEndDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.divisionEndDate ? _vm.formatDate(item.divisionEndDate) : '')+" ")]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":"12"}},[_c('div',{staticClass:"mx-auto border-top"}),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"col-4 offset-2"},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"col-4 py-1"},[_vm._v("Division ID")]),_c('v-col',{staticClass:"col-auto py-1"},[_vm._v(_vm._s(item.groupDivisionId))])],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"col-4 py-1"},[_vm._v("Division tax ID")]),_c('v-col',{staticClass:"col-auto py-1"},[_vm._v(_vm._s(item.divisionTaxId))])],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"col-4 py-1"},[_vm._v("External ID")]),_c('v-col',{staticClass:"col-auto py-1"},[_vm._v(_vm._s(item.externalDivisionId))])],1)],1),_c('v-col',{staticClass:"col-4"},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"col-4 py-1 pl-10"},[_vm._v("Contact")]),_c('v-col',{staticClass:"col-auto py-1"},[_vm._v(" "+_vm._s(_vm.getGroupContact(item.groupId).contactFirstName)+" "+_vm._s(_vm.getGroupContact(item.groupId).contactLastName)+" ")])],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"col-auto offset-4 py-1"},[_vm._v(" "+_vm._s(_vm.getGroupContact(item.groupId).contactEmailAddress)+" ")])],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"col-4 py-1 pl-10"},[_vm._v("ACH Type")]),_c('v-col',{staticClass:"col-auto py-1"},[_vm._v(_vm._s(_vm.getInvoiceSetting(item.groupId).achPayment ? 'Yes' : 'No'))])],1)],1)],1)],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }