import Vue from 'vue'
import GroupCard from '@/components/common/ui/GroupCard/GroupCard.vue'
import { GroupProductDetail } from '@/store/modules/groupProducts/types'

export default Vue.extend({
  components: { GroupCard },
  computed: {
    groupPlanOption(): GroupProductDetail {
      return this.$store.state.groupProducts.selectedPlan.planDetailsTable.items.find(
        (detail: GroupProductDetail) =>
          detail.groupProductPlanOptionId ===
          this.$store.state.groupProducts.selectedPlan.plan.groupProductPlanOptionId,
      )
    },
  },
})
