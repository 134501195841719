var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-6 ml-auto pb-4 d-flex items-center justify-end"},[_c('v-icon',{attrs:{"color":"lighten-1"}},[_vm._v("mdi-information")]),_c('span',{staticClass:"ml-2 h5-text"},[_vm._v(" Grand Total"),_c('strong',{staticClass:"ml-3"},[_vm._v("("+_vm._s(_vm.formatMoney(_vm.totalSuspense))+")")])])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-12"},[_c('data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"pagination":_vm.pagination},on:{"request-filter":function($event){_vm.filters = $event}},scopedSlots:_vm._u([{key:"item.depositDate",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm.formatDate(item.depositDate)))])]}},{key:"item.depositDesc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.transactionType ? item.transactionType : 'N/A')+" ")]}},{key:"item.coverageBeginDate",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.coverageBeginDate ? _vm.formatDate(item.coverageBeginDate) : 'N/A'))])]}},{key:"item.masCashAmountApplied",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm.formatMoney(item.masCashAmountApplied)))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }