import Vue, { PropType } from 'vue'
import GroupCard from '@/components/common/ui/GroupCard/GroupCard.vue'
import TagStatus from '@/components/common/ui/TagStatus/Index.vue'

import { MemberDto } from '@/autogen-interfaces'

export default Vue.extend({
  name: 'EnrollmentInfoCard',
  components: {
    GroupCard,
    TagStatus,
  },
  props: {
    member: {
      required: true,
      type: Object as PropType<MemberDto>,
    },
  },
  methods: {
    formatDate(dateString: string, useOptions = false): string {
      const date = dateString ? new Date(dateString) : new Date()
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
      return date.toLocaleDateString('en-US', useOptions ? options : undefined)
    },
  },
  computed: {
    memberAge(): number {
      // gets number of years
      const dob = this.member.dateOfBirth ? new Date(this.member.dateOfBirth) : new Date()
      const age_dt = new Date(Date.now() - dob.getTime())
      return Math.abs(age_dt.getUTCFullYear() - 1970)
    },
    memberActive(): boolean {
      const statusDetails = this.member.statusDetails
      const disabled = statusDetails ? statusDetails[0].disabled : false
      return !disabled
    },
  },
})
