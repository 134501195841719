var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('billing-history-details',{attrs:{"show":_vm.showDetails,"invoiceId":_vm.invoiceId},on:{"closeDrawer":function($event){_vm.showDetails = false}}}),_c('v-row',[_c('v-col',{staticClass:"col-2 ml-auto"},[_c('filter-single-date',{attrs:{"label":"bill date","nullable":""},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-12"},[_c('data-table',{attrs:{"loading":_vm.loading,"headers":_vm.dataTableHeader,"items":_vm.items,"pagination":_vm.pagination},on:{"request-filter":function($event){_vm.filters = $event}},scopedSlots:_vm._u([{key:"item.invoiceName",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_vm._v(" "+_vm._s(item.invoiceName)+" ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('span',{staticClass:"body-sm-heavy"},[_vm._v(_vm._s(item.groupDivisionName))])])]}},{key:"item.billDate",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm.formatDate(item.billDate)))])]}},{key:"item.billStatus",fn:function(ref){
var item = ref.item;
return [_c('tag',{attrs:{"success":item.daysPastDue === 0,"error":item.daysPastDue > 0}},[_c('span',{staticClass:"text-capitalize pl-1 pr-2"},[_vm._v(" "+_vm._s(item.daysPastDue === 0 ? 'Paid' : 'Past Due')+" ")])])]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm.formatMoney(item.totalAmount)))])]}},{key:"item.cashAmountApplied",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm.formatMoney(item.cashAmountApplied)))])]}},{key:"item.netDue",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm.formatMoney(item.netDue)))])]}},{key:"item.expand",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showBillDetails(item)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }