import Vue from 'vue'

export default Vue.extend({
  props: {
    text: {
      type: String,
      default:
        'Your latest bill is ready!  You can consectetur adipiscing elit, sed do eiusmod temp or incididunt ut.',
    },
  },
  data: () => ({
    v0: true,
  }),
})
