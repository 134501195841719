import Vue from 'vue'
import Editor from '@/components/common/ui/Editor/Index.vue'
import Flyout from '@/components/common/ui/Flyout/Index.vue'
import Drawer from '@/components/common/ui/Drawer/Index.vue'
import SearchBar from '@/components/common/ui/SearchBar/Index.vue'
import TextField from '@/components/common/ui/TextField/Index.vue'
import Census from '@/components/common/ui/Census/Index.vue'
import CardAttachment from '@/components/common/ui/CardAttachment/Index.vue'
import CardReport from '@/components/common/ui/CardReport/Index.vue'
import Notification from '@/components/common/ui/Notification/Index.vue'
import Tag from '@/components/common/ui/Tag/Index.vue'
import CardAchCreditCard from '@/components/common/ui/CardAchCreditCard/Index.vue'
import CardEnrollment from '@/components/common/ui/CardEnrollment/Index.vue'
import CardContactInfo from '@/components/common/ui/CardContactInfo/Index.vue'
import CardProduct from '@/components/common/ui/CardProduct/Index.vue'
import CardEnrollmentAdvanced from '@/components/common/ui/CardEnrollmentAdvanced/Index.vue'
import EditableTextField from '@/components/common/ui/EditableTextField/Index.vue'
import AttachFile from '@/components/common/ui/AttachFile/Index.vue'
import Datatable from '@/views/styleguide/DatatableExample/Index.vue'

export default Vue.extend({
  components: {
    Editor,
    Flyout,
    Drawer,
    SearchBar,
    TextField,
    Census,
    CardAttachment,
    CardReport,
    Notification,
    Tag,
    CardAchCreditCard,
    CardEnrollment,
    CardContactInfo,
    CardProduct,
    CardEnrollmentAdvanced,
    EditableTextField,
    AttachFile,
    Datatable,
  },
  data: () => ({
    dummyText: '',
    editBoolean: false,
    editorText: '',
    flyoutModel: false,
    drawer: false,
    attachFileDialog: false,
    dateOfBirth: '',
    drawerFullscreen: false,
    fieldsInReport: [
      'Field Name',
      'Field Name',
      'Field Name',
      'Field Name',
      'Field Name',
      'Field Name',
    ],
    cardAttachmentMenu: [{ title: 'Item' }, { title: 'Item 2' }],
    achCreditCard: {
      creditCard: 'Visa ****8978',
      isActive: true,
      addedBy: 'Robert Bob Robertson',
      date: '12/20/2020',
    },
    cardContactInfo: {
      address: '198594 State Highway 207 <br/>#980 <br/>Johnson City, TX 78499<br/> United States',
      phone1: '(512) 899-5989',
      phone2: '(512) 899-5989',
      email: 'rebeccajorgenson@cityofurbana.gov',
    },
    cardProductInfo: {
      headerInfo: {
        title: 'Signature HSA GAP',
        subtitle:
          'HSA High Gap Deductible $1400 Individual/$2800 Family Inpatient Max Benefit $4000',
        active: true,
        effective: 'Jan 1, 2021',
        paid: 'Jul 1, 2021',
      },
      carrier: 'Kemper FSL',
      coverage: {
        name: 'Employee + Spouse',
        value: '$4000 Max Coverage',
      },
      gppoId: '89724',
      extPlanCode: '89724',
    },
    cardEnrollment: {
      isActive: true,
      enrollmentMethod: 'Paper Submission',
      enrollmentStatus: 'Closed',
      billConfiguration: 'Advance',
      initialEnrollmentDate: '11/09/2020 - 11/23/2020',
      annualEnrollmentDate: '11/09/2020 - 11/23/2020',
      initEnrollmentDate: '11/09/2020 ',
    },
    cardEnrollmentAdvanced: {
      isActive: true,
      title: 'Full-Time Employee',
      fromDate: 'Jan 1, 2021',
      toDate: 'present',
      division: 'All Employees',
      employeeClass: 'All Full Time Employees',
      benefitGroup: 'Benefit Group Name goes here',
      salary: '75,000',
      statusNote:
        'Status reason could go here? Not sure what kind of information that is. Or other notes? Either way, this is a place that those sorts of typed notes could live.',
      audit: {
        addedBy: 'NT SERVICE/SQLSERVERAGENT',
        updatedBy: 'NT SERVICE/SQLSERVERAGENT',
        addedDate: 'Jan 10, 2020',
        updatedDate: 'Jul 11, 2021',
      },
    },
  }),
  methods: {
    dummyMethod() {
      console.log('dummy method')
    },
    uploadFiles(fileArray: File[]) {
      console.log('Method to upload')
      console.log(fileArray)
      this.attachFileDialog = false
    },
  },
})
