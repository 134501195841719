import { Dashboard } from './types'

export const state: Dashboard = {
  currentBalance: 1409,
  lastAmountPaid: 98,
  remaining: 1234,
  paymentStatus: 'pending',
  ACHStatus: 'Unapproved',
  paymentStatusTooltip: {
    pending:
      'A payment or partial payment has been received, and is in the process of being applied to the bill.',
    'past due': 'No payment is in process, and the bill is past due.',
    paid: 'The bill is paid in full',
  },
  achTooltip: {
    Unapproved: 'An ACH withdrawal has not been initiated.',
    Approved: 'A withdrawal has been initiated, and is in process.',
    Completed: 'The ACH has been completed and applied to the bill.',
  },
  showBanner: true,
  bannerText: 'Your latest bill is ready!',
}
