import Vue from 'vue'

interface vTextField extends HTMLFormElement {
  computedId: string
}

export default Vue.extend({
  name: 'TextField',
  inheritAttrs: false,
  props: {
    errorMessages: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    optional: {
      type: String,
    },
    customClass: {
      type: String,
      default: '',
    },
    actionText: {
      type: String,
      default: null,
    },
    appendText: {
      type: String,
      default: null,
    },
    value: String,
  },
  data: () => ({
    vTextFieldId: '',
  }),
  mounted() {
    this.vTextFieldId = this.vTextField.computedId
  },
  computed: {
    vTextField: function (): vTextField {
      return this.$refs.vTextField as vTextField
    },
  },
})
