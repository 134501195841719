import { RootState } from '@/store/types'
import { Dashboard } from './types'
import { Module } from 'vuex'
import { state } from './state'
import { mutations } from './mutations'

export const dashboard: Module<Dashboard, RootState> = {
  namespaced: true,
  state,
  mutations,
}
