import { RouteConfig } from 'vue-router'
import ColorPalette from '@/views/styleguide/ColorPalette/Index.vue'
import Buttons from '@/views/styleguide/Buttons/Index.vue'
import Icons from '@/views/styleguide/Icons.vue'
import Components from '@/views/styleguide/Components/Index.vue'
import Typography from '@/views/styleguide/Typography/Index.vue'
import ComponentsLarge from '@/views/styleguide/ComponentsLarge/Index.vue'

const styleguideRoutes: Array<RouteConfig> = [
  {
    path: '/styleguide/color-palette',
    name: 'ColorPalette',
    component: ColorPalette,
  },
  {
    path: '/styleguide/buttons',
    name: 'Buttons',
    component: Buttons,
  },
  {
    path: '/styleguide/icons',
    name: 'Icons',
    component: Icons,
  },
  {
    path: '/styleguide/components',
    name: 'Components',
    component: Components,
  },
  {
    path: '/styleguide/components-large',
    name: 'ComponentsLarge',
    component: ComponentsLarge,
  },
  {
    path: '/styleguide/typography',
    name: 'Typography',
    component: Typography,
  },
]

export default styleguideRoutes
