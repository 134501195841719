import Vue from 'vue'
import { DataTableHeader } from 'vuetify'
import { ProductElectionRiderItem } from '@/store/modules/enrollmentProducts/types'
import { PageableComponentInterface, RequestFilter } from '@/store/types'
import DataTable from '@/components/common/ui/DataTable/Index.vue'

export default Vue.extend({
  components: { DataTable },
  async created() {
    await this.$store.dispatch('enrollmentProducts/getMemberProductElection', {
      filter: this.filters,
    })
  },
  data: () => ({
    dataTableHeader: [
      {
        text: 'Rider Name and Description',
        value: 'riderNameAndDescription',
        sortable: false,
        width: '30%',
      },
      { text: 'Coverage', value: 'coverage', sortable: false, width: '20%' },
      {
        text: 'Date Effective',
        value: 'effectiveDate',
        sortable: false,
        width: '20%',
      },
      { text: 'Rates', value: 'rates', sortable: false, width: '30%' },
    ] as DataTableHeader[],
    filters: {
      page: 1,
      pageSize: 10,
      skip: 0,
      take: 1,
      sort: [],
      filter: [],
    } as RequestFilter,
  }),
  computed: {
    items(): ProductElectionRiderItem[] {
      return this.$store.state.enrollmentProducts.selectedProductElection.productRiderTable.items
    },
    loading(): boolean {
      return this.$store.state.enrollmentProducts.loading
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements:
          this.$store.state.enrollmentProducts.selectedProductElection.productRiderTable
            .totalElements,
        totalPages:
          this.$store.state.enrollmentProducts.selectedProductElection.productRiderTable.totalPages,
      }
    },
  },
  watch: {
    filters() {
      this.$store.dispatch('enrollmentProducts/getMemberProductElection', { filter: this.filters })
    },
  },
})
