import { ActionTree } from 'vuex'
import { PageableComponentInterface, RequestFilter, RootState } from '@/store/types'
import { BillingPage, BillingStatementsPayments } from '@/store/modules/billing/types'
import BillingApi from '@/api/BillingApi'
import { ApiError } from '@/store/utils/types'
import { withAsync } from '@/store/utils/withAsync'
import {
  BillingPageInfoDto,
  BillingStatementDetailsDto,
  DocumentDataDto,
  DocumentDto,
  GroupPaymentHistoryDto,
} from '@/autogen-interfaces'
import BillingStatementDetailsToDto from '@/store/modules/billing/EntityToDto'
import DocumentsApi from '@/api/DocumentsApi'
import { AppData } from '@/AppData'

export const actions: ActionTree<BillingPage, RootState> = {
  async getBillingStatements({ commit }, filters: RequestFilter) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: BillingPageInfoDto } = await withAsync(
      BillingApi.fetchBillingInformation,
      BillingApi,
      filters,
    )
    if (error) {
      console.log(error)
    } else {
      commit('setBillingStatements', data.billingStatementsGrid)
      commit('setBillingGroups', data.billingStatementsGrid)
      commit('setBillingDivisions', data.billingDivisons)
      const elementsLength = data.billingStatementsGrid ? data.billingStatementsGrid.length : 1
      const pagination: PageableComponentInterface = {
        totalElements: elementsLength,
        totalPages: Math.ceil(elementsLength / 10),
      }
      commit('setBillingStatementsPagination', pagination)
      commit('setLoading', false)
    }
  },

  async getBillingStatementDetails({ commit, state }, filters: RequestFilter) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: BillingStatementDetailsDto[] } =
      await withAsync(
        BillingApi.fetchBillingStatementDetail,
        BillingApi,
        state.billingStatements.selectedItem.invoiceNumber,
        filters,
      )
    if (error) {
      console.log(error)
    } else {
      const details: BillingStatementsPayments[] = []
      for (const item of data) {
        details.push(BillingStatementDetailsToDto.convert(item))
      }
      const pagination: PageableComponentInterface = {
        totalElements: details.length,
        totalPages: 1,
      }
      commit('setSelectedBillingStatementDetail', details)
      commit('setBillingStatementsPaymentsPagePagination', pagination)
      commit('setLoading', false)
    }
  },

  async getBillingSuspense({ commit }, filters: RequestFilter) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: GroupPaymentHistoryDto[] } = await withAsync(
      BillingApi.fetchBillingSuspense,
      BillingApi,
      filters,
    )
    commit('setLoading', false)
    if (error) {
      console.log(error)
    } else {
      commit('setBillingSuspense', data)
      const pagination: PageableComponentInterface = {
        totalElements: data.length,
        totalPages: Math.ceil(data.length / 10),
      }
      commit('setBillingSuspensePagination', pagination)
    }
  },

  async fetchInvoiceData({ dispatch }, invoiceId: number) {
    const documentId = await dispatch('getInvoiceDocumentId', invoiceId)
    if (documentId) await dispatch('fetchDocumentData', documentId)
  },

  async getInvoiceDocumentId({ commit }, invoiceId: number): Promise<number | undefined> {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: DocumentDto[] } = await withAsync(
      DocumentsApi.fetchDocuments,
      DocumentsApi,
      { GroupId: AppData.associations.GroupIds[0], InvoiceNumber: invoiceId },
      { filter: [], page: 1, pageSize: 1, skip: 0, sort: [], take: 1 },
    )
    if (error) {
      console.error(error)
      commit('setLoading', false)
    } else {
      commit('setLoading', false)
      if (data && data.length > 0) {
        return (data[0] as DocumentDto).documentID
      }
    }
    return undefined
  },

  async fetchDocumentData({ commit }, documentId: number) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: DocumentDataDto } = await withAsync(
      DocumentsApi.fetchDocumentData,
      DocumentsApi,
      documentId,
      {},
    )
    if (error) {
      console.error(error)
    } else {
      commit('setDownloadInvoiceDate', data)
    }
    commit('setLoading', false)
  },
}
