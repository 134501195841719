





















import Vue, { PropType } from 'vue'

import DataTable from '@/components/common/ui/DataTable/Index.vue'

import { DataTableHeader } from 'vuetify'
import { DocumentDto } from '@/autogen-interfaces'
import { RequestFilter, PageableComponentInterface } from '@/store/types'

import mixin from '@/mixin'

export default Vue.extend({
  name: 'ProductDocuments',
  data() {
    return {
      filters: {
        page: 1,
        pageSize: 10,
        skip: 0,
        take: 1,
        sort: [],
        filter: [],
      } as RequestFilter,
    }
  },
  components: { DataTable },
  mixins: [mixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array as PropType<DataTableHeader[]>,
      default: [],
    },
    items: {
      type: Array as PropType<DocumentDto[]>,
      default: [],
    },
    pagination: {
      type: Object as PropType<PageableComponentInterface>,
      default: () => {
        return {
          totalElements: 10,
          totalPages: 1,
        }
      },
    },
  },
  watch: {
    filters() {
      this.$emit('filter-change', this.filters)
    },
  },
})
