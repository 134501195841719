import { Module } from 'vuex'
import { RootState } from '@/store/types'
import {
  contactsMutation,
  groupVendorsMutation,
} from '@/store/modules/groupContactsVendors/mutations'
import { GroupContacts, GroupVendors } from '@/store/modules/groupContactsVendors/types'
import { groupContactsState, groupVendorsState } from '@/store/modules/groupContactsVendors/state'
import { contactActions, groupVendorsActions } from '@/store/modules/groupContactsVendors/actions'

export const groupContacts: Module<GroupContacts, RootState> = {
  namespaced: true,
  state: groupContactsState,
  mutations: contactsMutation,
  actions: contactActions,
}

export const groupVendors: Module<GroupVendors, RootState> = {
  namespaced: true,
  state: groupVendorsState,
  mutations: groupVendorsMutation,
  actions: groupVendorsActions,
}
