














import Vue from 'vue'

export default Vue.extend({
  props: {
    colorName: String,
    colorHex: String,
    className: {
      type: String,
      default: '',
    },
    text: String,
  },
})
