import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { state } from '@/store/modules/enrollmentStatus/state'
import { mutations } from '@/store/modules/enrollmentStatus/mutations'
import { actions } from '@/store/modules/enrollmentStatus/actions'
import { EnrollmentStatusPage } from '@/store/modules/enrollmentStatus/types'

export const enrollmentStatus: Module<EnrollmentStatusPage, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}
