import Vue from 'vue'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import DropCursor from '@tiptap/extension-dropcursor'
import { Indent } from './Assets/indent'
import SDialog from '@/components/common/ui/SDialog/Index.vue'
import TextField from '@/components/common/ui/TextField/Index.vue'

export default Vue.extend({
  components: {
    EditorContent,
    SDialog,
    TextField,
  },
  props: {
    minWidth: {
      type: Number,
      default: 650,
    },
    minHeight: {
      type: Number,
      default: 100,
    },
    label: {
      type: String,
      default: 'Reply',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: null as unknown as Editor,
      addLinkDialog: false,
      url: '',
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        DropCursor,
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Indent.configure({
          types: ['listItem', 'paragraph'],
          indentLevels: [1, 2, 3, 4, 5, 6, 7, 8],
          defaultIndentLevel: 1,
        }),
        Placeholder.configure({
          placeholder: 'Add comment...',
          emptyNodeClass: 'body-md-heavy',
        }),
        Link.configure({
          openOnClick: true,
        }),
      ],
      content: this.value,
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      },
    })
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value
      if (isSame) {
        return
      }
      this.editor.commands.setContent(value, false)
    },
  },
  methods: {
    openLinkDialog(): void {
      this.url = this.editor.getAttributes('link').href
      this.addLinkDialog = true
    },
    addLink(): void {
      // cancelled
      if (this.url === null) {
        this.addLinkDialog = false
        return
      }
      // empty
      if (this.url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
        this.addLinkDialog = false
        return
      }
      // update link
      this.editor.chain().focus().extendMarkRange('link').setLink({ href: this.url }).run()
      this.addLinkDialog = false
    },
  },
  beforeDestroy() {
    this.editor.destroy()
  },
})
