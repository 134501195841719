import Vuex from 'vuex'
import Vue from 'vue'
// Root state imports
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
// Module imports
import { navbar } from './modules/navbar'
import { alerts } from './modules/alerts'
import { dashboard } from './modules/dashboard'
import { enrollment } from './modules/enrollment'
import { enrollmentDocuments, enrollmentNotes } from '@/store/modules/enrollmentDocuments'
import { billing } from './modules/billing'
import { groupStructure } from '@/store/modules/groupStructure'
import { groups } from './modules/groups'
import { groupProducts } from '@/store/modules/groupProducts'
import { groupContacts, groupVendors } from '@/store/modules/groupContactsVendors'
import { enrollmentBilling } from '@/store/modules/enrollmentBilling'
import { groupDocuments, groupNotes } from '@/store/modules/groupDocuments'
import { enrollmentStatus } from '@/store/modules/enrollmentStatus'
import { enrollmentFringe } from '@/store/modules/enrollmentFringe'
import { enrollmentProducts } from '@/store/modules/enrollmentProducts'
import { groupUserSelection } from '@/store/modules/groupUserSelection'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  getters,
  mutations,
  modules: {
    navbar,
    alerts,
    dashboard,
    enrollment,
    enrollmentDocuments,
    enrollmentNotes,
    billing,
    groupStructure,
    groups,
    groupProducts,
    groupContacts,
    groupVendors,
    groupDocuments,
    groupUserSelection,
    enrollmentBilling,
    groupNotes,
    enrollmentStatus,
    enrollmentFringe,
    enrollmentProducts,
  },
})
