import { BillingStatementDetailsDto } from '@/autogen-interfaces'
import { BillingStatementsPayments } from '@/store/modules/billing/types'
import { formatDate, formatMoney } from '@/helpers'

export default class BillingStatementDetailsToDto {
  public static convert(item: BillingStatementDetailsDto): BillingStatementsPayments {
    return {
      checkNumber: this.nullCheck(item.checkNo),
      deposit: this.nullCheck(item.depositNo),
      depositAmount: item.masCashAmountApplied ? formatMoney(item.masCashAmountApplied) : '$0.00',
      depositDate: item.depositDate ? formatDate(item.depositDate) : '',
      posted: item.postingDate ? formatDate(item.postingDate) : '',
      status: this.nullCheck(item.paymentApplicationStatus),
    }
  }

  private static nullCheck(value: string | null | undefined): string {
    return value || ''
  }
}
