import Vue, { PropType } from 'vue'
import Settings from './Settings/Index.vue'

export interface MenuItem {
  text: string
  path: string
}

export default Vue.extend({
  components: { Settings },
  props: {
    logo: {
      type: String,
      required: true,
    },
    menuItems: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
    settings: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
    notification: {
      type: Boolean,
      required: true,
    },
    shadow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
