import Vue from 'vue'

export default Vue.extend({
  props: {
    viewed: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'mdi-help-circle',
    },
    title: {
      type: String,
      default: 'Title',
    },
    subtitle: {
      type: String,
      default: 'Subtitle',
    },
  },
})
