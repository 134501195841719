import Vue from 'vue'
import Pagination from '@/components/common/ui/Pagination/Index.vue'
import FilterDate from '@/components/common/ui/FilterDate/Index.vue'
import { Note } from '@/store/modules/enrollmentDocuments/types'
import { PageableComponentInterface, RequestFilter } from '@/store/types'
import { AvailableMember } from '@/store/modules/enrollment/types'
import ExpandableTable from '@/components/common/ui/ExpandableTable/Index.vue'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'

export default Vue.extend({
  components: { Pagination, FilterDate, ExpandableTable },
  data: () => ({
    filters: {} as RequestFilter,
    expanded: [],
  }),
  created() {
    this.$store.dispatch('enrollmentNotes/fetchNoteTypes')
  },
  computed: {
    dataTableHeader(): FilterableDataTableHeader[] {
      return [
        {
          text: 'Note ID',
          value: 'noteId',
          width: '12%',
          align: 'start',
          cellClass: 'notesCell',
          filterable: 'text',
        },
        {
          text: 'Date Updated',
          value: 'dateUpdated',
          align: 'center',
          width: '16%',
          cellClass: 'notesCell',
          filterable: 'text',
          inputRegex: '##/##/####',
          placeHolder: 'MM/DD/YYYY',
        },
        {
          text: 'Note Type',
          value: 'noteType',
          align: 'start',
          width: '13%',
          cellClass: 'notesCell',
          filterable: 'dropdown',
          selectOptions: this.$store.state.enrollmentNotes.noteTypes,
        },
        {
          text: 'Note',
          value: 'note',
          align: 'start',
          sortable: false,
          width: '15%',
          filterable: 'text',
        },
        {
          text: 'Phone Number',
          value: 'phoneNumber',
          align: 'center',
          width: '16%',
          cellClass: 'notesCell',
          filterable: 'text',
          inputRegex: '(###) ###-####',
          placeHolder: '(123) 123-12345',
        },
        {
          text: 'TIN',
          value: 'tin',
          align: 'center',
          width: '8%',
          cellClass: 'notesCell',
          filterable: 'text',
        },
        {
          text: 'Resolution Type',
          value: 'resolutionType',
          align: 'center',
          width: '16%',
          cellClass: 'notesCell',
          filterable: 'text',
        },
        {
          text: '',
          value: 'data-table-expand',
          align: 'center',
          width: '4%',
          cellClass: 'text-end notesCell',
        },
      ]
    },
    enrollmentNotes(): Note[] {
      return this.$store.state.enrollmentNotes.notes
    },
    loading(): boolean {
      return this.$store.state.enrollmentNotes.loading
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.enrollmentNotes.totalElements,
        totalPages: this.$store.state.enrollmentNotes.totalPages,
      }
    },
    selectedMember(): number {
      return this.$store.state.enrollment.selectedMember || this.$route.params.memberId
    },
    memberType(): number {
      return (
        this.$store.state.enrollment.availableMembers.find(
          (m: AvailableMember) => m.memberID === this.selectedMember,
        )?.memberTypeID || 40 // on page refresh, fetch employee data, default to 40
      )
    },
  },
  methods: {
    async fetchNotes() {
      await this.$store.dispatch('enrollmentNotes/fetchNotes', {
        memberId: this.selectedMember,
        memberTypeId: this.memberType,
        requestFilter: this.filters,
      })
    },
  },
  watch: {
    selectedMember() {
      this.fetchNotes()
    },
    filters() {
      this.fetchNotes()
    },
  },
})
