import moment from 'moment'

export const formatDate = (dateString: string, customFormat?: string): string => {
  const dateFormat = customFormat ? customFormat : 'MMM DD, YYYY'
  return dateString != null ? moment(dateString).format(dateFormat) : ''
}

export const formatMoney = function (number: number | string): string {
  if (number != null) {
    const amount = typeof number === 'number' ? number : parseFloat(number)
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  }
  return number
}
