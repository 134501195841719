import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { GroupDocuments, GroupNotes } from '@/store/modules/groupDocuments/types'
import {
  groupDocumentsMutation,
  groupNotesMutation,
} from '@/store/modules/groupDocuments/mutations'
import { groupDocumentsActions, groupNotesActions } from '@/store/modules/groupDocuments/actions'
import { groupDocumentState, groupNotesState } from '@/store/modules/groupDocuments/state'

export const groupDocuments: Module<GroupDocuments, RootState> = {
  namespaced: true,
  mutations: groupDocumentsMutation,
  actions: groupDocumentsActions,
  state: groupDocumentState,
}

export const groupNotes: Module<GroupNotes, RootState> = {
  namespaced: true,
  state: groupNotesState,
  mutations: groupNotesMutation,
  actions: groupNotesActions,
}
