var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),(!_vm.loading)?_c('v-data-table',_vm._g(_vm._b({staticClass:"soluta-data-table",attrs:{"items":_vm.items,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"page-count":function($event){_vm.pagination.pageCount = $event}},scopedSlots:_vm._u([_vm._l((_vm.attributeHeaders),function(header){return {key:("header." + (header.value)),fn:function(){return [_vm._v(" "+_vm._s(header.text)+" "),(header.filterable)?_c('v-menu',{key:("filter-" + (header.value)),attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({key:("btn-" + (header.value)),attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-filter-variant ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"width":"277px"}},[_c('v-list',{staticClass:"py-0",attrs:{"color":"#ECEFFF"}},[_c('v-list-item',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-5"},[_vm._v("Filter")]),_c('v-col',{staticClass:"col-6"},[_c('v-btn',{attrs:{"text":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.clearFilter(("" + (header.filterColumn ? header.filterColumn : header.value)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close-circle")]),_vm._v("  Clear Filter ")],1)],1)],1)],1),_c('v-list-item',{on:{"":function($event){}}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-12"},[(header.filterable === 'text')?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(header.inputRegex ? header.inputRegex : ''),expression:"header.inputRegex ? header.inputRegex : ''"}],staticClass:"soluta-text-field data-table-filter",attrs:{"outlined":"","append-icon":"mdi-subdirectory-arrow-right","placeholder":header.placeHolder ? header.placeHolder : ''},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateFilters.apply(null, arguments)}},model:{value:(
                      _vm.filters[("" + (header.filterColumn ? header.filterColumn : header.value))]
                    ),callback:function ($$v) {_vm.$set(_vm.filters, ("" + (header.filterColumn ? header.filterColumn : header.value)), $$v)},expression:"\n                      filters[`${header.filterColumn ? header.filterColumn : header.value}`]\n                    "}}):_c('v-select',{staticClass:"data-table-filter",attrs:{"outlined":"","append-icon":"mdi-subdirectory-arrow-right","items":header.selectOptions,"item-text":'text',"item-value":'value',"placeholder":header.placeHolder ? header.placeHolder : ''},on:{"change":_vm.updateFilters},model:{value:(
                      _vm.filters[("" + (header.filterColumn ? header.filterColumn : header.value))]
                    ),callback:function ($$v) {_vm.$set(_vm.filters, ("" + (header.filterColumn ? header.filterColumn : header.value)), $$v)},expression:"\n                      filters[`${header.filterColumn ? header.filterColumn : header.value}`]\n                    "}})],1)],1)],1)],1)],1)]):_vm._e()]},proxy:true}}),_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"no-data",fn:function(){return [_vm._t("no-data")]},proxy:true}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners)):_vm._e(),(!_vm.loading && _vm.pagination.totalElements >= 10)?_c('pagination',{attrs:{"perPage":_vm.itemsPerPage,"length":_vm.pagination.totalPages,"total-items":_vm.pagination.totalElements ? _vm.pagination.totalElements : 0},on:{"items-per-page":function($event){_vm.itemsPerPage = $event}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }