import { MutationTree } from 'vuex'
import { Enrollment, AvailableMember } from './types'
import { MemberDto, DocumentDataDto } from '@/autogen-interfaces'
import { loadableMutation, paginationMutation } from '@/store/mutations'

export const mutations: MutationTree<Enrollment> = {
  setPerson(state, member: MemberDto) {
    console.log('setPerson', member)
    const groupBenefitGroupId = member?.statusDetails
      ? member.statusDetails[0].groupBenefitGroupId
      : ''
    const active = member?.statusDetails ? !member.statusDetails[0].disabled : false

    state.person = {
      name: `${member.firstName} ${member.lastName}`,
      memberId: member.memberID ? member.memberID.toString() : '0',
      city: member.groupName ? member.groupName : 'No group name',
      groupId: member.groupID ? member.groupID : '0',
      groupPolicyId: groupBenefitGroupId ? groupBenefitGroupId : '0',
      groupType: member.groupTypeName ? member.groupTypeName : 'No group type',
      active: active,
      role: member.relationship ? member.relationship : 'none',
    }
  },
  setItems(state, items: MemberDto[]) {
    state.items = [...items]
  },
  addAvailableMember(state, dependent: MemberDto) {
    state.availableMembers.push({
      ...dependent,
      name: `${dependent.firstName} ${dependent.middleName} ${dependent.lastName}`,
      role: dependent.relationship ? dependent.relationship : 'none',
    })
  },
  setAvailableMembers(state) {
    const members = [] as AvailableMember[]
    members.push({
      ...state.currentMember,
      name: `${state.currentMember.firstName} ${state.currentMember.middleName} ${state.currentMember.lastName}`,
      role: state.currentMember.relationship ? state.currentMember.relationship : 'none',
    })
    state.availableMembers = members
  },
  setSelectedMember(state, memberId: number) {
    state.selectedMember = memberId
  },
  setSelectedMemberFromRole(state, role: string) {
    const member = state.availableMembers.filter((member) => member.relationship === role)
    if (member.length > 0) {
      state.selectedMember = member[0].memberID || 0
    }
  },
  setSelectedDocument(state, document: DocumentDataDto) {
    state.selectedDocument = document
  },
  setCurrentMember(state, member: MemberDto) {
    state.currentMember = member
  },
  ...loadableMutation,
  ...paginationMutation,
}
