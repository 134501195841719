import Vue from 'vue'
import moment from 'moment'
import mixin from '@/mixin'

import { RequestFilter, PageableComponentInterface, GridSort } from '@/store/types'
import { FilterDateModel } from '@/components/common/ui/FilterDate/filterDate'
import { MemberDto } from '@/autogen-interfaces'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'

import FilterField from '@/components/common/ui/FilterField/Index.vue'
import FilterDate from '@/components/common/ui/FilterDate/Index.vue'
import Tag from '@/components/common/ui/Tag/Index.vue'
import TextField from '@/components/common/ui/TextField/Index.vue'
import TagStatus from '@/components/common/ui/TagStatus/Index.vue'
import HeaderSearch from '@/components/common/ui/HeaderSearch/Index.vue'
import DataTable from '@/components/common/ui/DataTable/Index.vue'

export default Vue.extend({
  components: {
    FilterField,
    FilterDate,
    Tag,
    TagStatus,
    TextField,
    HeaderSearch,
    DataTable,
  },
  mixins: [mixin],
  data() {
    return {
      dateRangeFilter: {
        beginDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate(),
      } as FilterDateModel,
      showDetails: false,
      searchBoxText: '',
      firstName: '',
      lastName: '',
      memberId: '',
      dateOfBirth: '',
      showAdvancedSearch: false,
      filters: {
        filter: [],
        page: 1,
        pageSize: 10,
        skip: 0,
        sort: [],
        take: 1,
      } as RequestFilter,
      sortBy: [],
      sortDesc: [false],
      dataTableHeader: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Member Id', value: 'employeeID', sortable: false },
        { text: 'Benefit Group Name', value: 'groupName', sortable: false },
        { text: 'Group Id', value: 'groupId', sortable: true },
        { text: 'Division', value: 'division', sortable: true },
        { text: 'Class', value: 'class', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
      ] as FilterableDataTableHeader[],
    }
  },
  methods: {
    formatDate: function (dateString: string): string {
      return dateString != null ? moment(dateString).format('MMM DD, YYYY') : ''
    },
    setSearch: function (searchBoxText: string) {
      this.searchBoxText = searchBoxText
    },
    executeSearch: function () {
      if (this.showAdvancedSearch) {
        this.$store.dispatch('enrollment/searchMemberAdvanced', {
          name: this.firstName.trim(),
          lastname: this.lastName.trim(),
          dob: this.dateOfBirth.trim(),
          memberId: this.memberId.trim(),
          filters: this.filtersWithSort,
        })
      } else {
        this.$store.dispatch('enrollment/searchMember', {
          searchTerm: this.searchBoxText,
          filter: this.filtersWithSort,
        })
      }
    },
    getMemberDetails(item: MemberDto) {
      this.$router.push({
        name: 'Enrollment - Member Info',
        params: {
          memberId: item.employeeID ? item.employeeID.toString() : 'null',
        },
      })
    },
  },
  computed: {
    enrollmentItems(): MemberDto {
      return this.$store.state.enrollment.items
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.enrollment.totalElements,
        totalPages: this.$store.state.enrollment.totalPages,
      }
    },
    filtersWithSort(): RequestFilter {
      const sorting: GridSort[] = []
      this.sortBy.forEach((sort: string) => {
        sorting.push({
          field: sort,
          dir: this.sortDesc[0] ? 'desc' : 'asc',
        })
      })
      return {
        ...this.filters,
        filter: this.filters.filter ? this.filters.filter : [],
        sort: sorting,
      }
    },
  },

  watch: {
    filtersWithSort() {
      if (this.showAdvancedSearch || this.searchBoxText) {
        this.executeSearch()
      } else {
        this.$store.dispatch('enrollment/searchMember', {
          searchTerm: '',
          filter: this.filtersWithSort,
        })
      }
    },
  },
})
