import Vue, { PropType } from 'vue'

interface ContactCardInterface {
  address: string
  phone1: string
  phone2: string
  email: string
}

export default Vue.extend({
  name: 'ContactCardInfo',
  props: {
    cardInfo: {
      type: Object as PropType<ContactCardInterface>,
      required: true,
    },
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    formatPhoneNumber(phoneNumberString: string): string {
      const cleaned = ('' + phoneNumberString).replace(/\D+/g, '')
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})(\+?\d+)?$/)
      if (match) {
        return `${match[1] ? '+' + match[1] : ''} (${match[2]}) ${match[3]}-${match[4]} ${
          match[5] ? ' xt.' + match[5] : ''
        }`
      }
      console.error('Regex failed for: ' + phoneNumberString)
      return ''
    },
  },
})
