import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { AlertsState } from './types'

export const actions: ActionTree<AlertsState, RootState> = {
  async getAlerts({ commit, state }) {
    console.log('polling alerts...')
    if (state.polling) {
      commit('setNewAlertAvailable', true)
      commit('setAlerts', [
        {
          id: 'articleId',
          type: 'article-alert',
          title: 'Article title',
          subtitle: '',
          message:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temp or incidi dunt ut labore et',
          action: '',
          createdAt: new Date(),
          read: false,
        },
      ])
    }
  },
}
