import Vue from 'vue'

export default Vue.extend({
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  computed: {
    tagClass: function (): string {
      let className = ''
      if (this.error) className = 'tag--error'
      if (this.warning) className = 'tag--warning'
      if (this.success) className = 'tag--success'
      return className
    },
    elipseClass: function (): string {
      let className = ''
      if (this.error) className = 'elipse--error'
      if (this.warning) className = 'elipse--warning'
      if (this.success) className = 'elipse--success'
      return className
    },
  },
})
