import { Api } from '@/api/Api'
import { RequestFilter } from '@/store/types'
import { AppData } from '@/AppData'

class BillingApi extends Api {
  /**
   * Fetch all Billing information
   */
  fetchBillingInformation(filters: RequestFilter) {
    return this.get('Billing/Get_BillingRouteDetails', {
      GroupId: AppData.associations.GroupIds[0],
      ApplicationUser: AppData.associations.UserId,
      GridHelper: filters,
    })
  }

  /**
   * Fetch suspense information
   */
  fetchBillingSuspense(filters: RequestFilter) {
    return this.get('Group/Get_GroupPaymentHistory', {
      GroupId: AppData.associations.GroupIds[0],
      ApplicationUser: AppData.associations.UserId,
      GridHelper: filters,
    })
  }

  /**
   * Fetch billing statement detail
   */
  fetchBillingStatementDetail(invoiceNumber: string, filters: RequestFilter) {
    return this.get('Billing/Get_BillingStatementDetails', {
      InvoiceNumber: invoiceNumber,
      GroupId: AppData.associations.GroupIds[0],
      ApplicationUser: AppData.associations.UserId,
      GridHelper: filters,
    })
  }
}

export default new BillingApi()
