import Vue from 'vue'

export default Vue.extend({
  props: {
    option: {
      type: String,
      default: 'opt1',
    },
  },
})
