import Vue from 'vue'

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    action: {
      type: Boolean,
      default: false,
    },
  },
})
