































































































import Vue from 'vue'

import mixin from '@/mixin'

import Drawer from '@/components/common/ui/Drawer/Index.vue'
import TagStatus from '@/components/common/ui/TagStatus/Index.vue'
import DataTable from '@/components/common/ui/DataTable/Index.vue'

import { DataTableHeader } from 'vuetify'
import { Person } from '@/components/common/ui/HeaderPlain/headerPlain'
import { PageableComponentInterface, RequestFilter } from '@/store/types'

export default Vue.extend({
  name: 'BillingHistoryDetails',
  components: { Drawer, TagStatus, DataTable },
  mixins: [mixin],
  data() {
    return {
      loading: false,
      items: [],
      headers: [
        { text: 'Coverage Begin Date', sortable: false, value: 'coverageBegDate', align: 'left' },
        { text: 'Coverage End Date', sortable: false, value: 'coverageEndDate', align: 'left' },
        { text: 'Bill Type', sortable: false, value: 'billType', align: 'left' },
        {
          text: 'Billing Name',
          sortable: false,
          value: 'billingFeeName',
          align: 'left',
          width: '10%',
        },
        {
          text: 'Carrier Product Name',
          sortable: false,
          value: 'carrierProductName',
          align: 'left',
          width: '10%',
        },
        {
          text: 'Group Product Plan Name',
          sortable: false,
          value: 'groupProductPlanName',
          align: 'left',
          width: '10%',
        },
        { text: 'Coverage', sortable: false, value: 'coverage', align: 'right', width: '8%' },
        {
          text: 'Employer Portion',
          sortable: false,
          value: 'employerPortion',
          align: 'right',
          width: '7%',
        },
        {
          text: 'Employee Portion',
          sortable: false,
          value: 'employeePortion',
          align: 'right',
          width: '7%',
        },
        {
          text: 'Total Amount',
          sortable: false,
          value: 'totalAmount',
          align: 'right',
          width: '7%',
        },
        {
          text: 'Amount Applied',
          sortable: false,
          value: 'cashAmountApplied',
          align: 'right',
          width: '7%',
        },
        { text: 'Paid Up', sortable: false, value: 'paidUp', align: 'center', width: '7%' },
      ] as DataTableHeader[],
      filters: {} as RequestFilter,
    }
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    invoiceId: {
      type: String,
      required: true,
    },
  },
  computed: {
    member(): Person {
      return this.$store.state.enrollment.person
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.enrollmentBilling.invoiceDetails.totalElements,
        totalPages: this.$store.state.enrollmentBilling.invoiceDetails.totalPages,
      }
    },
  },
  methods: {
    fetchDetails() {
      this.$store.dispatch('enrollmentBilling/fetchMemberBillingDetails', {
        memberId: this.$route.params.memberId,
        billId: this.invoiceId,
        filter: this.filters,
      })
    },
  },
  watch: {
    filters() {
      this.fetchDetails()
    },
  },
})
