import { RouteConfig } from 'vue-router'

import Enrollment from '@/views/enrollment/Enrollment.vue'
import EnrollmentDetails from '@/views/enrollment/EnrollmentDetails.vue'
import EnrollmentMembers from '@/views/enrollment/EnrollmentMembers.vue'
import EnrollmentMemberInfo from '@/views/enrollment/member-info/EnrollmentMemberInfo.vue'
import EnrollmentMemberInfoMemberInfo from '@/views/enrollment/member-info/EnrollmentMemberInfoMemberInfo.vue'
import EnrollmentMemberInfoContact from '@/views/enrollment/member-info/EnrollmentMemberInfoContact.vue'
import EnrollmentStatus from '@/views/enrollment/status/EnrollmentStatus.vue'
import EnrollmentStatusCurrentStatus from '@/views/enrollment/status/currentStatus/EnrollmentStatusCurrentStatus.vue'
import EnrollmentStatusStatusHistory from '@/views/enrollment/status/statusHistory/EnrollmentStatusStatusHistory.vue'
import EnrollmentProduct from '@/views/enrollment/product/EnrollmentProduct.vue'
import EnrollmentProductProductElections from '@/views/enrollment/product/EnrollmentProductProductElections.vue'
import EnrollmentProductProductEnrollment from '@/views/enrollment/product/EnrollmentProductProductEnrollment.vue'
import EnrollmentBillingHistory from '@/views/enrollment/billing-history/EnrollmentBillingHistory.vue'
import EnrollmentBillingHistoryBillingHistory from '@/views/enrollment/billing-history/EnrollmentBillingHistoryBillingHistory.vue'
import EnrollmentBillingHistorySuspense from '@/views/enrollment/billing-history/EnrollmentBillingHistorySuspense.vue'
import EnrollmentFringeAllocation from '@/views/enrollment/fringe-allocation/EnrollmentFringeAllocation.vue'
import EnrollmentDocuments from '@/views/enrollment/documents/EnrollmentDocuments.vue'
import EnrollmentDocumentsDocuments from '@/views/enrollment/documents/EnrollmentDocumentsDocuments.vue'
import EnrollmentDocumentsNotes from '@/views/enrollment/documents/EnrollmentDocumentsNotes.vue'

const enrollmentRoutes: Array<RouteConfig> = [
  {
    path: '/enrollment',
    name: 'Enrollment',
    redirect: '/enrollment/members',
    component: Enrollment,
    children: [
      {
        path: '/enrollment/members',
        name: 'Enrollment - Members',
        component: EnrollmentMembers,
      },
      {
        path: '/enrollment/details',
        name: 'Enrollment - Member Details',
        component: EnrollmentDetails,
        children: [
          {
            path: '/enrollment/members/:memberId',
            name: 'Enrollment - Member Info',
            redirect: { name: 'Enrollment - Members - Member Info' },
            component: EnrollmentMemberInfo,
            children: [
              {
                path: '/enrollment/members/:memberId/member-info',
                name: 'Enrollment - Members - Member Info',
                component: EnrollmentMemberInfoMemberInfo,
              },
              {
                path: '/enrollment/members/:memberId/contact',
                name: 'Enrollment - Members - Contact',
                component: EnrollmentMemberInfoContact,
              },
            ],
          },
          {
            path: '/enrollment/status/:memberId',
            name: 'Enrollment - Status',
            redirect: '/enrollment/status/:memberId/current-status',
            component: EnrollmentStatus,
            children: [
              {
                path: '/enrollment/status/:memberId/current-status',
                name: 'Enrollment - Status - Current Status',
                component: EnrollmentStatusCurrentStatus,
              },
              {
                path: '/enrollment/status/:memberId/status-history',
                name: 'Enrollment - Status - Status History',
                component: EnrollmentStatusStatusHistory,
              },
            ],
          },
          {
            path: '/enrollment/product/:memberId',
            name: 'Enrollment - Product',
            redirect: { name: 'Enrollment - Product - Product Elections' },
            component: EnrollmentProduct,
            children: [
              {
                path: '/enrollment/product/:memberId/product-elections',
                name: 'Enrollment - Product - Product Elections',
                component: EnrollmentProductProductElections,
              },
              {
                path: '/enrollment/product/:memberId/product-enrollment',
                name: 'Enrollment - Product - Product Enrollment',
                component: EnrollmentProductProductEnrollment,
              },
            ],
          },
          {
            path: '/enrollment/billing-history/:memberId',
            name: 'Enrollment - Billing History',
            redirect: { name: 'Enrollment - Billing History - Billing History' },
            component: EnrollmentBillingHistory,
            children: [
              {
                path: '/enrollment/billing-history/:memberId/billing-history',
                name: 'Enrollment - Billing History - Billing History',
                component: EnrollmentBillingHistoryBillingHistory,
              },
              {
                path: '/enrollment/billing-history/:memberId/suspense',
                name: 'Enrollment - Billing History - Suspense',
                component: EnrollmentBillingHistorySuspense,
              },
            ],
          },
          {
            path: '/enrollment/fringe-allocation/:memberId',
            name: 'Enrollment - Fringe Allocation',
            component: EnrollmentFringeAllocation,
          },
          {
            path: '/enrollment/documents/:memberId',
            name: 'Enrollment - Documents',
            redirect: { name: 'Enrollment - Documents - Documents' },
            component: EnrollmentDocuments,
            children: [
              {
                path: '/enrollment/documents/:memberId/documents',
                name: 'Enrollment - Documents - Documents',
                component: EnrollmentDocumentsDocuments,
              },
              {
                path: '/enrollment/documents/:memberId/notes',
                name: 'Enrollment - Documents - Notes',
                component: EnrollmentDocumentsNotes,
              },
            ],
          },
        ],
      },
    ],
  },
]

export default enrollmentRoutes
