











































import { GroupAddress } from '@/store/modules/groups/types'
import GroupCard from '@/components/common/ui/GroupCard/GroupCard.vue'

import Vue from 'vue'
export default Vue.extend({
  name: 'GroupAddress',
  components: {
    GroupCard,
  },
  computed: {
    mailingAddress(): GroupAddress {
      return this.$store.state.groups.info.mailingAddress
    },
    physicalAddress(): GroupAddress {
      return this.$store.state.groups.info.physicalAddress
    },
  },
})
