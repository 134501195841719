




































































































import Vue from 'vue'
import VerticalTabs from '@/components/common/ui/VerticalTabs/Index.vue'
import HeaderPlainGroup from '@/components/common/ui/HeaderPlainGroup/Index.vue'
import { AppData } from '@/AppData'

export default Vue.extend({
  components: { VerticalTabs, HeaderPlainGroup },
  computed: {
    selectedUserGroupId: {
      get(): string | null {
        return this.$store.state.selectedUserGroupId
      },
    },
    header() {
      return {
        city: this.$store.state.groups.info.physicalAddress.city,
        groupId: this.$store.state.groups.info.id,
        groupType: this.$store.state.groups.info.type,
        groupPolicyId: this.$store.state.groups.info.policy,
        active: this.$store.state.groups.info.status,
      }
    },
    permissions() {
      return AppData.associations.Permissions.Group
    },
  },
  created: function () {
    // Need to make sure that for all Groups pages a group is selected inside the store
    if (!this.selectedUserGroupId) {
      this.$router.push({ name: 'User Groups' })
    } else {
      this.$store.dispatch('groups/fetchGroupDetails')
    }
  },
})
