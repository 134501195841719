import Vue from 'vue'
import moment from 'moment'
import { Vendor } from '@/store/modules/groupContactsVendors/types'
import { GridFilter, Operators, PageableComponentInterface, RequestFilter } from '@/store/types'
import ExpandableTable from '@/components/common/ui/ExpandableTable/Index.vue'
import FilterSingleDate from '@/components/common/ui/FilterSingleDate/Index.vue'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'

export default Vue.extend({
  components: { ExpandableTable, FilterSingleDate },
  data: () => ({
    filters: {} as RequestFilter,
    dataTableHeader: [
      { text: 'Service Name', value: 'serviceName', sortable: false, width: '15%' },
      {
        text: 'Legal Name',
        value: 'legalName',
        align: 'start',
        sortable: false,
        width: '15%',
      },
      { text: 'Notes', value: 'notes', align: 'start', sortable: false, width: '30%' },
      {
        text: 'Effective Date',
        value: 'effectiveDate',
        align: 'center',
        sortable: false,
        width: '15%',
      },
      { text: 'End Date', value: 'endDate', align: 'center', sortable: false, width: '15%' },
      {
        text: 'Contact',
        value: 'data-table-expand',
        align: 'center',
        sortable: false,
        width: '10%',
        cellClass: 'text-end',
      },
    ] as FilterableDataTableHeader[],
  }),
  methods: {
    fetchVendors() {
      this.$store.dispatch('groupVendors/fetchGroupVendors', this.groupVendorsFilter)
    },
  },
  watch: {
    groupVendorsFilter() {
      this.fetchVendors()
    },
  },
  computed: {
    groupVendors(): Vendor[] {
      return this.$store.state.groupVendors.vendors
    },
    loading(): boolean {
      return this.$store.state.groupVendors.loading
    },
    dateFilter: {
      get(): Date {
        return this.$store.state.groupVendors.dateFilter
      },
      set(dateFilter) {
        this.$store.commit('groupVendors/setDateFilter', dateFilter)
      },
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.groupContacts.totalElements,
        totalPages: this.$store.state.groupContacts.totalPages,
      }
    },
    groupVendorsFilter(): RequestFilter {
      const filter: GridFilter[] = [
        {
          operator: Operators.GREATER_OR_EQUAL_THAN,
          field: 'startDate',
          value: moment(this.dateFilter as Date).format(),
          logic: '',
          filters: [],
        },
      ]
      return { ...this.filters, filter: filter.concat(this.filters.filter) }
    },
  },
})
