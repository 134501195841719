var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"col-12"},[_c('data-table',{staticClass:"mt-5",attrs:{"loading":_vm.$store.state.enrollmentDocuments.loading,"items":_vm.documents,"item-key":"documentId","headers":_vm.dataTableHeader,"pagination":_vm.pagination},on:{"request-filter":function($event){_vm.filters = $event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.documentType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.documentType)+" ")]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.comment)+" ")]}},{key:"item.beginDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.beginDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.endDate))+" ")]}},{key:"item.storageSize",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.storageSize)+" ")]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.downloadDocument(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-tray-arrow-down")])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }