






import Vue from 'vue'
import CardContanctInfo from '@/components/common/ui/CardContactInfo/Index.vue'

export default Vue.extend({
  name: 'EnrollmentMemberInfoContact',
  components: {
    CardContanctInfo,
  },
  computed: {
    cardInfo() {
      return {
        address: `${this.$store.state.enrollment.currentMember.address1}, ${this.$store.state.enrollment.currentMember.address2}, ${this.$store.state.enrollment.currentMember.city}, ${this.$store.state.enrollment.currentMember.stateCode} ${this.$store.state.enrollment.currentMember.zip}, ${this.$store.state.enrollment.currentMember.country}`,
        phone1: this.$store.state.enrollment.currentMember.homePhone,
        phone2: this.$store.state.enrollment.currentMember.cellPhone,
        email: this.$store.state.enrollment.currentMember.email,
      }
    },
  },
})
