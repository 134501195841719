import { MutationTree } from 'vuex'
import { NavbarState } from './types'

export const mutations: MutationTree<NavbarState> = {
  setLogo(state, logo: string): void {
    state.logo = logo
  },
  setBackgroundColor(state, backgroundColor: string): void {
    state.backgroundColor = backgroundColor
  },
  setShadow(state, shadow: boolean): void {
    state.shadow = shadow
  },
}
