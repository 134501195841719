import {
  Groups,
  GroupInfo,
  GroupAddress,
  GroupBillingHistory,
  GroupBillingHistoryItem,
  SelectedGroupBillingHistoryItem,
  GroupBillingSuspense,
} from './types'
import { DocumentDataDto } from '@/autogen-interfaces'

const billingHistory: GroupBillingHistory = {
  totalElements: 10,
  totalPages: 1,
  downloadInvoice: {} as DocumentDataDto,
  items: [] as GroupBillingHistoryItem[],
  dateFilter: new Date(),
  selectedItem: {
    groupBillingHistory: {} as GroupBillingHistoryItem,
    paymentItems: [],
    totalElements: 0,
    totalPages: 0,
  } as SelectedGroupBillingHistoryItem,
}
const paymentHistory: GroupBillingSuspense = {
  items: [],
  totalElements: 0,
  totalPages: 0,
}

export const state: Groups = {
  loading: true,
  info: {
    id: '0',
    name: '',
    status: false,
    policy: '',
    type: '',
    dba: '',
    altGroupId: '',
    masterGrouper: '',
    membershipType: '0',
    effectiveDate: '',
    renewalDate: '',
    terminationDate: '',
    adminDate: '',
    paidThruDate: '',
    taxId: '0',
    sicCode: '0',
    situs: '',
    federalTaxId: '0',
    mailingAddress: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    } as GroupAddress,
    physicalAddress: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    } as GroupAddress,
  } as GroupInfo,
  billingHistory,
  paymentHistory,
}
