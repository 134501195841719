import { MutationTree } from 'vuex'
import { loadableMutation, paginationMutation } from '@/store/mutations'
import {
  Document,
  EnrollmentDocuments,
  EnrollmentNotes,
  Note,
} from '@/store/modules/enrollmentDocuments/types'
import { SelectOption } from '@/components/common/ui/DataTable/dataTable'

export const enrollmentDocumentsMutation: MutationTree<EnrollmentDocuments> = {
  setDocuments(state, documents: Document[]) {
    state.documents = documents
  },
  setDocumentTypes(state, documentTypes: SelectOption[]) {
    state.documentTypes = documentTypes
  },
  ...loadableMutation,
  ...paginationMutation,
}

export const enrollmentNotesMutation: MutationTree<EnrollmentNotes> = {
  setNotes(state, notes: Note[]) {
    state.notes = notes
  },
  ...loadableMutation,
  ...paginationMutation,
  setNoteTypes(state, noteTypes: SelectOption[]) {
    state.noteTypes = noteTypes
  },
}
