import { ActionTree } from 'vuex'
import { PageableComponentInterface, RequestFilter, RootState } from '@/store/types'
import { ApiError } from '@/store/utils/types'
import { GroupContactsDto, GroupVendorsDto } from '@/autogen-interfaces'
import { withAsync } from '@/store/utils/withAsync'
import GroupsApi from '@/api/GroupsApi'
import {
  Contact,
  GroupContacts,
  GroupVendors,
  Vendor,
} from '@/store/modules/groupContactsVendors/types'
import {
  ContactEntityToDto,
  VendorEntityToDto,
} from '@/store/modules/groupContactsVendors/EntityToDto'

export const contactActions: ActionTree<GroupContacts, RootState> = {
  async fetchGroupContacts({ commit, rootState }, filter: RequestFilter) {
    commit('setLoading', true)
    const groupId = rootState.selectedUserGroupId
    const { error, data }: { error: ApiError; data: GroupContactsDto[] } = await withAsync(
      GroupsApi.fetchGroupContacts,
      GroupsApi,
      groupId,
      filter,
    )
    if (error) {
      console.error(error)
    } else {
      const contacts: Contact[] = []
      for (const groupProduct of data) {
        contacts.push(ContactEntityToDto.convert(groupProduct))
      }
      const pagination: PageableComponentInterface = {
        totalElements: contacts.length,
        totalPages: 1,
      }
      commit('setContacts', contacts)
      commit('setPagination', pagination)
    }
    commit('setLoading', false)
  },
}

export const groupVendorsActions: ActionTree<GroupVendors, RootState> = {
  async fetchGroupVendors({ commit, rootState }, requestFilter: RequestFilter) {
    commit('setLoading', true)
    const groupId = rootState.selectedUserGroupId
    const { error, data }: { error: ApiError; data: GroupVendorsDto[] } = await withAsync(
      GroupsApi.fetchGroupVendors,
      GroupsApi,
      groupId,
      requestFilter,
    )
    if (error) {
      console.error(error)
    } else {
      const vendors: Vendor[] = []
      for (const groupVendors of data) {
        vendors.push(VendorEntityToDto.convert(groupVendors))
      }
      const pagination: PageableComponentInterface = {
        totalElements: vendors.length,
        totalPages: 1,
      }
      commit('setVendors', vendors)
      commit('setPagination', pagination)
    }
    commit('setLoading', false)
  },
}
