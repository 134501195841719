import { Api } from '@/api/Api'
import { RequestFilter } from '@/store/types'
import { AppData } from '@/AppData'

class GroupStructureApi extends Api {
  fetchGroupStructure(filters: RequestFilter) {
    return this.get('/Group/Get_GroupStructureDetails', filters)
  }

  fetchGroupDivisions(groupId: number, filters: RequestFilter) {
    return this.get('/Group/Get_GroupDivisions', {
      GroupId: groupId,
      ApplicationUser: AppData.associations.UserId,
      GridHelper: filters,
    })
  }

  fetchGroupClasses(groupId: number, filters: RequestFilter) {
    return this.get('/Group/Get_GroupClasses', {
      GroupId: groupId,
      ApplicationUser: AppData.associations.UserId,
      GridHelper: filters,
    })
  }

  fetchGroupBenefitGroups(groupId: number, filters: RequestFilter) {
    return this.get('/Group/Get_GroupBenefitGroups', {
      GroupId: groupId,
      ApplicationUser: AppData.associations.UserId,
      GridHelper: filters,
    })
  }

  fetchGroupInvoiceSetting(groupId: number) {
    return this.get('/Group/Get_GroupInvoiceSettings', { GroupId: groupId })
  }

  fetchGroupContact(groupId: number) {
    return this.get('/Group/Get_GroupContacts', { GroupId: groupId })
  }
}

export default new GroupStructureApi()
