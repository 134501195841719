import { RootState } from '@/store/types'
import { Module } from 'vuex'
import state from './state'
import mutations from './mutations'
import actions from './actions'
import { GroupStructurePage } from '@/store/modules/groupStructure/types'

export const groupStructure: Module<GroupStructurePage, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}
