import Vue from 'vue'
import SDialog from '@/components/common/ui/SDialog/Index.vue'
import ButtonIcon from '@/components/common/ui/ButtonIcon.vue'
import CardAttachment from '@/components/common/ui/CardAttachment/Index.vue'

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ButtonIcon,
    SDialog,
    CardAttachment,
  },
  data() {
    return {
      addLinkDialog: false,
      uploadedFiles: [] as File[],
      dragover: false,
      addImageDialog: false,
    }
  },
  methods: {
    addFileToArray(file: File): void {
      if (!this.doesFileExist(file)) {
        this.uploadedFiles.push(file)
      }
    },
    onDrop(e: DragEvent): void {
      const transferFiles = e.dataTransfer
      const files = transferFiles !== null ? transferFiles.files : transferFiles
      if (files) {
        for (let i = 0; i < files.length; i++) {
          this.addFileToArray(files[i])
        }
        this.dragover = false
      }
    },
    onFileChange(e: Event) {
      const files = (e.target as HTMLInputElement).files
      if (files !== null && files.length) {
        this.addFileToArray(files[0])
      }
    },
    doesFileExist(draggedFile: File): boolean {
      return (
        this.uploadedFiles.find(
          (currentFile) =>
            currentFile.name === draggedFile.name &&
            currentFile.size === draggedFile.size &&
            currentFile.lastModified === currentFile.lastModified,
        ) !== undefined
      )
    },
    closeAttachFileDialog(): void {
      this.$emit('input', false)
    },
    onUploadClick(): void {
      this.$emit('onUploadClick', this.uploadedFiles)
    },
  },
})
