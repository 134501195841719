import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  methods: {
    formatMoney: function (number: string): string {
      if (number != null) {
        return parseFloat(number).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      }
      return number
    },
    formatDate(date: string, format?: string): string {
      if (!format) {
        format = 'MM/DD/YYYY'
      }
      return moment(date).format(format)
    },
  },
})
