import { ActionContext } from 'vuex'
import { RootState } from '@/store/types'
import { EnrollmentProducts, Actions } from './types'

import EnrollmentApi from '@/api/EnrollmentApi'
import DocumentsApi from '@/api/DocumentsApi'
import { withAsync } from '@/store/utils/withAsync'
import { AppData } from '@/AppData'

import { ApiError, ActionTreeAdaptor } from '@/store/utils/types'
import {
  MemberPlanElectionsInfoDto,
  MemberEnrollmentAndPlanCoverageInfoDto,
  DocumentDto,
  MemberRiderEnrollmentAndCoverageInfoDto,
  MemberEligibilitySpanDto,
  EmployeeBeneficiariesDto,
} from '@/autogen-interfaces'

export const actions: ActionTreeAdaptor<Actions, EnrollmentProducts, RootState> = {
  async getMemberPlanElectionsDetails(
    { commit }: ActionContext<EnrollmentProducts, RootState>,
    { memberId, memberTypeId, asOfDate },
  ) {
    commit('setLoading', true)
    const MemberID = memberId
    const MemberTypeID = memberTypeId
    const AsOfDate = asOfDate ? asOfDate : null
    const { error, data }: { error: ApiError; data: MemberPlanElectionsInfoDto } = await withAsync(
      EnrollmentApi.fetchMemberPlanElectionsDetails,
      EnrollmentApi,
      MemberID,
      MemberTypeID,
      AsOfDate,
    )
    commit('setLoading', false)
    if (error) {
      console.error(error)
    } else {
      commit('setMemberPlanElections', data)
    }
  },

  async getMemberEnrollmentAndPlanCoverageInfo(
    { commit }: ActionContext<EnrollmentProducts, RootState>,
    { memberId, memberTypeId, asOfDate },
  ) {
    commit('setLoading', true)
    const MemberID = memberId
    const MemberTypeID = memberTypeId
    const AsOfDate = asOfDate
    const { error, data }: { error: ApiError; data: MemberEnrollmentAndPlanCoverageInfoDto[] } =
      await withAsync(
        EnrollmentApi.fetchMemberEnrollmentAndPlanCoverageInfo,
        EnrollmentApi,
        MemberID,
        MemberTypeID,
        AsOfDate,
      )
    commit('setLoading', false)
    if (error) {
      console.error(error)
    } else {
      commit('setMemberEnrollmentAndPlanCoverageInfo', data)
    }
  },

  async getMemberProductElection(
    { commit, state }: ActionContext<EnrollmentProducts, RootState>,
    { filter },
  ) {
    commit('setLoading', true)
    const PlanElectionEnrollmentID = state.selectedProductElection.product.planElectionId
    const MemberID = state.selectedProductElection.product.memberID
    const { error, data }: { error: ApiError; data: MemberRiderEnrollmentAndCoverageInfoDto[] } =
      await withAsync(
        EnrollmentApi.fetchMemberProductElection,
        EnrollmentApi,
        MemberID,
        PlanElectionEnrollmentID,
        filter,
      )
    commit('setLoading', false)
    if (error) {
      console.error(error)
    } else {
      commit('setSelectedProductElectionRiderItems', data || [])
      commit('setSelectedProductElectionRiderPagination', {
        totalElements: data.length || 0,
        totalPages: 1,
      })
    }
  },
  async getMemberProductEnrollment(
    { commit, state }: ActionContext<EnrollmentProducts, RootState>,
    { filter },
  ) {
    commit('setLoading', true)
    const MemberID = state.selectedProductElection.product.memberID
    const { error, data }: { error: ApiError; data: MemberRiderEnrollmentAndCoverageInfoDto[] } =
      await withAsync(EnrollmentApi.fetchMemberProductEnrollment, EnrollmentApi, MemberID, filter)
    commit('setLoading', false)
    if (error) {
      console.error(error)
    } else {
      commit('setSelectedProductEnrollmentRiderItems', data)
      commit('setSelectedProductEnrollmentRiderPagination', {
        totalElements: data.length,
        totalPages: 1,
      })
    }
  },
  async getMemberEligibilitySpans(
    { commit, state }: ActionContext<EnrollmentProducts, RootState>,
    { filter },
  ) {
    commit('setLoading', true)
    const MemberId = state.selectedProductElection.product.memberID
    const planElectionEnrollmentID = state.selectedProductElection.product.planElectionId
    const { error, data }: { error: ApiError; data: MemberEligibilitySpanDto[] } = await withAsync(
      EnrollmentApi.fetchMemberEligibilitySpans,
      EnrollmentApi,
      MemberId,
      planElectionEnrollmentID,
      filter,
    )
    commit('setLoading', false)
    if (error) {
      console.error(error)
    } else {
      commit('setProductElectionEligibilitySpan', data)
      commit('setProductElectionEligibilitySpanPagination', {
        totalElements: data.length,
        totalPages: 1,
      })
    }
  },

  async getMemberBeneficiaries(
    { commit }: ActionContext<EnrollmentProducts, RootState>,
    { memberId, parameterName, filter },
  ) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: EmployeeBeneficiariesDto[] } = await withAsync(
      EnrollmentApi.fetchEmployeeBeneficiaries,
      EnrollmentApi,
      memberId,
      filter,
    )
    if (error) {
      console.error(error)
    } else {
      commit('setLoading', false)
      if (parameterName === 'PlanElectionID') {
        commit('setProductElectionBeneficiaries', data || [])
        commit('setProductElectionBeneficiariesPagination', {
          totalElements: data.length,
          totalPages: 1,
        })
      } else if (parameterName === 'PlanEnrollmentID') {
        commit('setProductEnrollmentBeneficiaries', data || [])
        commit('setProductEnrollmentBeneficiariesPagination', {
          totalElements: data.length || 0,
          totalPages: 1,
        })
      }
    }
  },

  async getDocuments(
    { commit, state }: ActionContext<EnrollmentProducts, RootState>,
    { parameterId, parameterName, filter },
  ) {
    commit('setLoading', true)
    interface Payload {
      MemberID: number
      MemberTypeID: number
      GroupID: number
      PlanElectionID?: number
      PlanEnrollmentID?: number
    }
    const payload = {
      MemberID: state.selectedProductElection.product.memberID,
      MemberTypeID: state.selectedProductElection.product.memberTypeID,
      GroupID: AppData.associations.GroupIds[0],
    } as Payload
    if (parameterName === 'PlanElectionID') {
      payload.PlanElectionID = parameterId
    } else {
      payload.PlanEnrollmentID = parameterId
    }
    const { error, data }: { error: ApiError; data: DocumentDto[] } = await withAsync(
      DocumentsApi.fetchDocuments,
      DocumentsApi,
      payload,
      filter,
    )
    if (error) {
      console.error(error)
    } else {
      commit('setLoading', false)
      if (parameterName === 'PlanElectionID') {
        commit('setProductElectionDocuments', data)
        commit('setProductElectionDocumentsPagination', {
          totalElements: data.length,
          totalPages: 1,
        })
      } else if (parameterName === 'PlanEnrollmentID') {
        commit('setProductEnrollmentDocuments', data)
        commit('setProductEnrollmentDocumentsPagination', {
          totalElements: data.length,
          totalPages: 1,
        })
      }
    }
  },
}
