import Vue from 'vue'
import HeaderBasic from '@/components/common/ui/HeaderBasic/Index.vue'
import { VTabs } from 'vuetify/lib/components'
import mixin from '@/mixin'

export default Vue.extend({
  components: { HeaderBasic, VTabs },
  mixins: [mixin],
  computed: {
    grandTotal(): string {
      return this.$store.state.billing.billingStatements[0]?.grandTotal
    },
  },
})
