import Vue from 'vue'
import { Chart, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import definedColors from './Assets/chartColors.js'
import chartOptions from './Assets/options.js'

export default Vue.extend({
  name: 'BarChartCensus',
  props: {
    colorSetup: {
      type: String,
      default: 'opt1',
    },
    labels: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    currentColors() {
      if (definedColors[this.colorSetup] === undefined) {
        return definedColors['opt1']
      }
      return definedColors[this.colorSetup]
    },
    checkedLabels() {
      // If chart has less than 3 values we need to add null values to align left the graph
      // https://github.com/chartjs/Chart.js/issues/7974#issuecomment-720082641
      let labels = this.labels
      if (labels.length < 3) {
        for (let i = labels.length; i < 3; i++) {
          labels.push(null)
        }
      }
      return labels
    },
  },
  mounted() {
    let barCharCensus = this.$refs.barCharCensus
    Chart.register(...registerables)
    Chart.register(ChartDataLabels)
    new Chart(barCharCensus, {
      type: 'bar',
      data: {
        labels: this.checkedLabels,
        datasets: [
          {
            barThickness: 50,
            data: this.data,
            backgroundColor: this.currentColors.backgroundColor,
            borderColor: this.currentColors.borderColor,
            borderSkipped: false,
            borderWidth: this.currentColors.borderWidth,
            percentageTextColor: this.currentColors.percentageTextColor,
          },
        ],
      },
      options: {
        ...chartOptions,
        plugins: {
          tooltip: {
            callbacks: {
              label: function () {
                return ''
              },
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            font: {
              size: 16,
              family: "'Avenir Heavy', serif",
            },
            padding: 0,
            labels: {
              qty: {
                align: 'end',
                anchor: 'end',
                color: 'black',
              },
              percentage: {
                align: 'top',
                anchor: 'start',
                color: function (currentValue) {
                  let percentageTextColor = currentValue.dataset.percentageTextColor
                  return percentageTextColor !== null ? percentageTextColor : 'black'
                },
                formatter(value, currentValue) {
                  let sum = 0
                  let dataArr = currentValue.chart.data.datasets[0].data
                  dataArr.map((data) => {
                    sum += data
                  })
                  return ((value * 100) / sum).toFixed(0) + '%'
                },
              },
            },
          },
        },
      },
    })
  },
})
