import { MutationTree } from 'vuex'
import { Dashboard } from './types'

export const mutations: MutationTree<Dashboard> = {
  setCurrentBalance(state, currentBalance: number) {
    state.currentBalance = currentBalance
  },
  setLastAmountPaid(state, lastAmountPaid: number) {
    state.lastAmountPaid = lastAmountPaid
  },
  setRemaining(state, remaining: number) {
    state.remaining = remaining
  },
  setPaymentStatus(state, paymentStatus: 'pending' | 'past due' | 'paid') {
    state.paymentStatus = paymentStatus
  },
  setACHStatus(state, ACHStatus: 'Unapproved' | 'Approved' | 'Completed') {
    state.ACHStatus = ACHStatus
  },
  setShowBanner(state, showBanner: boolean) {
    state.showBanner = showBanner
  },
  setBannerText(state, bannerText: string) {
    state.bannerText = bannerText
  },
  setDashboard(state, dashboard: Dashboard) {
    state.currentBalance = dashboard.currentBalance
    state.lastAmountPaid = dashboard.lastAmountPaid
    state.remaining = dashboard.remaining
    state.paymentStatus = dashboard.paymentStatus
    state.ACHStatus = dashboard.ACHStatus
    state.paymentStatusTooltip = dashboard.paymentStatusTooltip
    state.achTooltip = dashboard.achTooltip
    state.showBanner = dashboard.showBanner
    state.bannerText = dashboard.bannerText
  },
}
