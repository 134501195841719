import Vue from 'vue'
import mixin from '@/mixin'
import { GroupBillingHistoryDto } from '@/autogen-interfaces'
import DataTable from '@/components/common/ui/DataTable/Index.vue'
import { PageableComponentInterface, RequestFilter } from '@/store/types'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'

export default Vue.extend({
  mixins: [mixin],
  components: { DataTable },
  data: () => ({
    filters: {} as RequestFilter,
    headers: [
      { text: 'Deposit Category', sortable: false, value: 'depositCategory' },
      { text: 'Deposit Date', sortable: false, value: 'depositDate' },
      { text: 'Deposit Desc', sortable: false, value: 'depositDesc' },
      { text: 'Invoice ID', sortable: false, value: 'invoiceNumber' },
      { text: 'Employee Name', sortable: false, value: 'employeeName' },
      { text: 'Coverage Begin Date', sortable: false, value: 'coverageBeginDate' },
      { text: 'Check Number', sortable: false, value: 'checkNo' },
      { text: 'Bank Code', sortable: false, value: 'bankCode' },
      { text: 'Amount Posted', sortable: false, value: 'amountPosted' },
      { text: 'Notes', sortable: false, value: 'notes' },
    ] as FilterableDataTableHeader[],
  }),
  methods: {
    fetchBillingSuspense() {
      this.$store.dispatch('billing/getBillingSuspense', this.filters)
    },
  },
  computed: {
    billingStatements(): GroupBillingHistoryDto[] {
      return this.$store.state.billing.billingSuspense.items
    },
    loading(): boolean {
      return this.$store.state.billing.loading
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.billing.billingSuspense.totalElements,
        totalPages: this.$store.state.billing.billingSuspense.totalPages,
      }
    },
  },
  watch: {
    async filters() {
      await this.fetchBillingSuspense()
    },
  },
})
