export const getUniqueItems = (array: any[], uniqueProperty: string): any => {
  const result: any[] = []
  // The map will help us keep a record of the objects
  const map = new Map()
  array.forEach((item) => {
    if (!map.has(item[uniqueProperty])) {
      map.set(item[uniqueProperty], true)
      // save unique object
      result.push(item)
    }
  })
  return result
}
