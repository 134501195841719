import Vue from 'vue'
import CardEnrollmentAdvanced from '@/components/common/ui/CardEnrollmentAdvanced/Index.vue'
import { AvailableMember } from '@/store/modules/enrollment/types'

export default Vue.extend({
  name: 'EnrollmentStatusCurrentStatus',
  components: { CardEnrollmentAdvanced },
  async created() {
    await this.$store.dispatch('enrollmentStatus/fetchMemberStatus', {
      memberId: this.selectedMember,
      memberType: this.memberType,
      requestFilter: null,
    })
  },
  computed: {
    selectedMember(): number {
      return this.$store.state.enrollment.selectedMember || this.$route.params.memberId
    },
    currentStatus(): number {
      return {
        ...this.$store.state.enrollmentStatus.currentStatus,
        role:
          this.$store.state.enrollment.availableMembers.find(
            (m: AvailableMember) => m.memberID === this.selectedMember,
          )?.role || '',
      }
    },
    memberType(): number {
      return (
        this.$store.state.enrollment.availableMembers.find(
          (m: AvailableMember) => m.memberID === this.selectedMember,
        )?.memberTypeID || 40 // on page refresh, fetch employee data, default to 40
      )
    },
  },
  watch: {
    selectedMember() {
      this.$store.dispatch('enrollmentStatus/fetchMemberStatus', {
        memberId: this.selectedMember,
        memberType: this.memberType,
        requestFilter: null,
      })
    },
  },
})
