import Vue from 'vue'

import Drawer from '@/components/common/ui/Drawer/Index.vue'
import TagStatus from '@/components/common/ui/TagStatus/Index.vue'
import ProductRider from '@/views/groups/products/productDetail/productRider/ProductRider.vue'
import PlanProductDetail from '@/views/groups/products/productDetail/planProductDetail/PlanProductDetail.vue'
import ProductDocuments from './productDocuments/ProductDocuments.vue'

import { PlanOptionDetails } from '@/store/modules/groupProducts/types'
import { DataTableHeader } from 'vuetify'
import { RequestFilter, PageableComponentInterface } from '@/store/types'
import { DocumentDto } from '@/autogen-interfaces'
import { base64ToBlob } from '@/helpers'

export default Vue.extend({
  components: { Drawer, TagStatus, ProductRider, PlanProductDetail, ProductDocuments },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  updated() {
    this.tabModel = 'tabDocuments'
  },
  data: () => ({
    tabModel: 'tabDocuments',
    documentHeaders: [
      { text: 'Name', sortable: false, width: '25%', value: 'documentName' },
      { text: 'Type', sortable: false, width: '10%', value: 'documentType' },
      { text: 'Comment', sortable: false, width: '25%', value: 'comment' },
      { text: 'Begin Date', sortable: false, width: '10%', value: 'beginDate' },
      { text: 'End Date', sortable: false, width: '10%', value: 'endDate' },
      { text: 'Inserted', sortable: false, width: '10%', value: 'insertYear' },
      { text: 'Download', sortable: false, width: '10%', value: 'download', align: 'center' },
    ] as DataTableHeader[],
    documentsFilter: {
      page: 1,
      pageSize: 10,
      skip: 0,
      take: 10,
      sort: [],
      filter: [],
    } as RequestFilter,
  }),
  computed: {
    selectedProduct(): PlanOptionDetails {
      return this.$store.state.groupProducts.selectedPlan.plan
    },
    documentsPagination(): PageableComponentInterface {
      return {
        totalElements:
          this.$store.state.groupProducts.selectedPlan.planDocumentsTable.totalElements,
        totalPages: this.$store.state.groupProducts.selectedPlan.planDocumentsTable.totalPages,
      }
    },
  },
  methods: {
    async downloadDocument(doc: DocumentDto) {
      await this.$store.dispatch('enrollment/getDocumentData', { documentId: doc.documentID })
      if (this.$store.state.enrollment.selectedDocument) {
        base64ToBlob(
          this.$store.state.enrollment.selectedDocument.documentData,
          this.$store.state.enrollment.selectedDocument.mimeType,
          doc.documentName || 'document',
          this.$store.state.enrollment.selectedDocument.fileExtension,
        )
      }
    },
  },
  watch: {
    documentsFilter() {
      this.$store.dispatch('groupProducts/fetchPlanDocuments', {
        gppoid: this.selectedProduct.groupProductPlanOptionId,
        groupId: this.$store.state.selectedUserGroupId,
        filter: this.documentsFilter,
      })
    },
  },
})
