import { EnrollmentProducts, SelectedProductElection, SelectedProductEnrollment } from './types'

const selectedProductElection: SelectedProductElection = {
  product: {},
  productRiderTable: {
    totalElements: 10,
    totalPages: 1,
    items: [],
  },
  productEligibilitySpansTable: {
    totalElements: 10,
    totalPages: 1,
    items: [],
  },
  productDocumentsTable: {
    totalElements: 10,
    totalPages: 1,
    items: [],
  },
  productBeneficiariesTable: {
    totalElements: 10,
    totalPages: 1,
    items: [],
  },
}

const selectedProductEnrollment: SelectedProductEnrollment = {
  product: {},
  productRiderTable: {
    totalElements: 10,
    totalPages: 1,
    items: [],
  },
  productDocumentsTable: {
    totalElements: 10,
    totalPages: 1,
    items: [],
  },
  productBeneficiariesTable: {
    totalElements: 10,
    totalPages: 1,
    items: [],
  },
}

export const state: EnrollmentProducts = {
  loading: false,
  memberPlanElections: [],
  memberEnrollmentAndPlanCoverageInfo: [],
  dateFilter: null,
  selectedProductEnrollment: selectedProductEnrollment,
  selectedProductElection: selectedProductElection,
  showProductElectionDetails: false,
  showProductEnrollmentDetails: false,
}
