import { PageableComponentInterface, RootState } from '@/store/types'
import {
  Document,
  Note,
  EnrollmentDocuments,
  EnrollmentNotes,
  DocumentsActions,
  NotesActions,
} from '@/store/modules/enrollmentDocuments/types'
import { ApiError, ActionTreeAdaptor } from '@/store/utils/types'
import { DocumentTypeDto, MemberDocumentsDto, MemberNotesDto } from '@/autogen-interfaces'
import {
  DocumentEntityToDto,
  NoteEntityToDto,
} from '@/store/modules/groupDocuments/DocumentEntityToDto'
import { SelectOption } from '@/components/common/ui/DataTable/dataTable'

import { withAsync } from '@/store/utils/withAsync'
import DocumentsApi from '@/api/DocumentsApi'
import NotesApi from '@/api/NotesApi'

export const enrollmentDocumentsActions: ActionTreeAdaptor<
  DocumentsActions,
  EnrollmentDocuments,
  RootState
> = {
  async fetchDocuments({ commit }, { memberId, memberTypeId, requestFilter }) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: MemberDocumentsDto[] } = await withAsync(
      DocumentsApi.fetchMemberDocuments,
      DocumentsApi,
      memberId,
      memberTypeId,
      requestFilter,
    )
    if (error) {
      console.error(error)
    } else {
      const documents: Document[] = []
      for (const groupDocument of data) {
        documents.push(DocumentEntityToDto.convert(groupDocument))
      }
      commit('setDocuments', documents)
      const pagination: PageableComponentInterface = {
        totalElements: documents.length,
        totalPages: 1,
      }
      commit('setPagination', pagination)
    }
    commit('setLoading', false)
  },

  async fetchDocumentTypes({ commit }) {
    const { error, data }: { error: ApiError; data: DocumentTypeDto[] } = await withAsync(
      DocumentsApi.fetchDocumentTypes,
      DocumentsApi,
    )
    if (error) {
      console.error(error)
    } else {
      const convertedTypes: SelectOption[] = data.map((type) => ({
        text: type.documentTypeName ? type.documentTypeName : 'None',
        value: type.documentTypeID ? type.documentTypeID : '-1',
      }))
      commit('setDocumentTypes', convertedTypes)
    }
  },
}

export const enrollmentNotesActions: ActionTreeAdaptor<NotesActions, EnrollmentNotes, RootState> = {
  async fetchNotes({ commit }, { memberId, memberTypeId, requestFilter }) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: MemberNotesDto[] } = await withAsync(
      NotesApi.fetchMemberNoteDetails,
      NotesApi,
      memberId,
      memberTypeId,
      requestFilter,
    )
    if (error) {
      console.error(error)
    } else {
      const notes: Note[] = []
      for (const groupNotes of data) {
        notes.push(NoteEntityToDto.convert(groupNotes))
      }
      commit('setNotes', notes)
      const pagination: PageableComponentInterface = {
        totalElements: notes.length,
        totalPages: 1,
      }
      commit('setPagination', pagination)
    }
    commit('setLoading', false)
  },

  async fetchNoteTypes({ commit }) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: DocumentTypeDto[] } = await withAsync(
      NotesApi.fetchNoteTypes,
      NotesApi,
    )
    if (error) {
      console.error(error)
    } else {
      const convertedTypes: SelectOption[] = data.map((type) => ({
        text: type.documentTypeName ? type.documentTypeName : 'None',
        value: type.documentTypeID ? type.documentTypeID : '-1',
      }))
      commit('setNoteTypes', convertedTypes)
    }
    commit('setLoading', false)
  },
}
