import { EnrollmentDocuments, EnrollmentNotes } from '@/store/modules/enrollmentDocuments/types'

export const enrollmentDocumentsState: EnrollmentDocuments = {
  loading: false,
  totalPages: 0,
  totalElements: 0,
  documents: [],
  documentTypes: [],
}

export const enrollmentNotesState: EnrollmentNotes = {
  totalElements: 10,
  totalPages: 1,
  notes: [],
  loading: false,
  noteTypes: [],
}
