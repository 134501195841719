import Vue, { PropType } from 'vue'
import TagStatus from '@/components/common/ui/TagStatus/Index.vue'
import CompanyCardInterface from '@/components/common/interface/companyCardInterface'

export default Vue.extend({
  components: { TagStatus },
  props: {
    info: {
      type: Object as PropType<CompanyCardInterface>,
      required: true,
    },
  },
})
