import moment from 'moment'
import { GroupContacts, GroupVendors } from '@/store/modules/groupContactsVendors/types'

export const groupContactsState: GroupContacts = {
  contacts: [],
  loading: false,
  totalElements: 10,
  totalPages: 1,
}

export const groupVendorsState: GroupVendors = {
  dateFilter: moment().toDate(),
  vendors: [],
  loading: false,
  totalElements: 10,
  totalPages: 1,
}
