import { MutationTree } from 'vuex'
import {
  LoadableComponent,
  PageableComponent,
  PageableComponentInterface,
  RootState,
} from './types'

export const mutations: MutationTree<RootState> = {
  setSelectedUserGroupId(state, selectedUserGroupId: string) {
    state.selectedUserGroupId = selectedUserGroupId
  },
  setTheme(state, theme: string) {
    state.theme = theme
  },
  setObserver(state, observer: IntersectionObserver | null) {
    state.observer = observer
  },
}

export const pageableMutation: MutationTree<PageableComponent> = {
  setPage(state, page: number) {
    state.page = page
  },
  setPageCount(state, pageCount: number) {
    state.pageCount = pageCount
  },
  setItemsPerPage(state, itemsPerPage: number) {
    state.itemsPerPage = itemsPerPage
  },
}

export const loadableMutation: MutationTree<LoadableComponent> = {
  setLoading(state, loading: boolean) {
    state.loading = loading
  },
}

export const paginationMutation: MutationTree<PageableComponentInterface> = {
  setPagination(state, pagination: PageableComponentInterface) {
    state.totalElements = pagination.totalElements
    state.totalPages = pagination.totalPages
  },
}
