import { Api } from '@/api/Api'
import { RequestFilter } from '@/store/types'
import { AppData } from '@/AppData'
import { AdvanceSearch } from '@/store/modules/groupUserSelection/type'

class GroupsApi extends Api {
  /**
   * Fetch details of the selected group
   * @returns {Promise} axios promise
   */
  fetchGroupDetails(groupId: number, DocumentTypeID: number[], MimeTypeID: number[]) {
    return this.get(`/Group/Get_GroupRouteDetails`, {
      GroupId: groupId,
      DocumentTypeID,
      MimeTypeID,
    })
  }

  /**
   * fetch group billing history
   * @param {string} groupId // not implemented yet
   * @param requestFilter
   * @returns {Promise} axios promise
   *
   */
  fetchGroupBillingHistory(groupId: number, requestFilter: RequestFilter) {
    return this.get(`/Group/Get_GroupBillingHistory`, {
      GroupId: groupId,
      ApplicationUser: AppData.associations.UserId,
      GridHelper: requestFilter,
    })
  }

  /**
   * fetch group billing history
   * @param {string} groupId // not implemented yet
   * @param invoiceNumber
   * @param requestFilter
   * @returns {Promise} axios promise
   *
   */
  fetchGroupPaymentHistory(groupId: number, invoiceNumber: string, requestFilter: RequestFilter) {
    return this.get(`/Group/Get_GroupPaymentHistory`, {
      InvoiceNumber: invoiceNumber,
      GroupId: groupId,
      ApplicationUser: AppData.associations.UserId,
      GridHelper: requestFilter,
    })
  }

  /**
   * fetch group products
   *
   * @returns {Promise} axios promise
   * @param groupId
   * @param requestFilter
   */
  fetchGroupProducts(groupId: number, requestFilter: RequestFilter) {
    return this.get('Group/Get_GroupProducts', {
      GroupId: groupId,
      ApplicationUser: AppData.associations.UserId,
      GridHelper: requestFilter,
    })
  }

  /**
   * fetch group products
   *
   * @returns {Promise} axios promise
   * @param groupId
   * @param requestFilter
   */
  fetchGroupContacts(groupId: number, requestFilter: RequestFilter) {
    return this.get('Group/Get_GroupContacts', {
      GroupId: groupId,
      ApplicationUser: AppData.associations.UserId,
      GridHelper: requestFilter,
    })
  }

  /**
   * fetch group products
   *
   * @returns {Promise} axios promise
   *
   * @param groupId
   * @param requestFilter
   */
  fetchGroupVendors(groupId: number, requestFilter: RequestFilter) {
    return this.get('Group/Get_GroupVendors', {
      GroupId: groupId,
      ApplicationUser: AppData.associations.UserId,
      GridHelper: requestFilter,
    })
  }

  /**
   * fetch group detail
   *
   * @returns {Promise} axios promise
   *
   * @param groupIds
   * @param inputSearch
   * @param requestFilter
   */
  fetchGroupDetail(
    groupIds: Array<number>,
    inputSearch: string | null,
    requestFilter: RequestFilter,
  ) {
    return this.get('Group/Get_Groups', {
      GroupIDs: groupIds,
      ApplicationUser: AppData.associations.UserId,
      GridHelper: requestFilter,
      Input: inputSearch,
    })
  }

  /**
   * fetch advance search
   *
   * @returns {Promise} axios promise
   *
   * @param groupIds
   * @param advanceSearch
   * @param requestFilter
   */
  fetchGroupDetailAdvanced(
    groupIds: Array<number>,
    advanceSearch: AdvanceSearch,
    requestFilter: RequestFilter,
  ) {
    return this.get('Group/Get_GroupsAdvanced', {
      GroupIDs: groupIds,
      ApplicationUser: AppData.associations.UserId,
      GridHelper: requestFilter,
      AlternateGroupNumber: advanceSearch.altGroupNumber,
      GroupName: advanceSearch.groupName,
      GroupId: advanceSearch.groupId,
      CarrierGroupNumber: advanceSearch.groupNumber,
    })
  }

  /**
   * Fetch group plan options
   *
   * @returns {Promise} axios promise
   *
   * @param {number} GroupProductId
   * @param {number} planId
   * @param {RequestFilter} filter
   *
   */
  fetchGroupPlanOptions(GroupProductId: number) {
    return this.get('Group/Get_GroupPlanOptions', {
      GroupProductId,
      ApplicationUser: AppData.associations.UserId,
      // GridHelper: ,
    })
  }
}

const groupsApi = new GroupsApi()

export default groupsApi
