import Vue from 'vue'
import HeaderNav from '@/components/common/ui/HeaderNav/Index.vue'
import Alert from '@/components/common/ui/Alert/Index.vue'

import { mapState } from 'vuex'

export default Vue.extend({
  name: 'App',
  components: {
    HeaderNav,
    Alert,
  },
  computed: {
    ...mapState(['observer']),
    ...mapState('navbar', ['logo', 'menuItems', 'settings', 'shadow']),
    ...mapState('alerts', ['polling', 'newAlertAvailable', 'firstTimeMessage']),
    firstTimeAlert(): boolean {
      return this.newAlertAvailable && !this.firstTimeMessage.read
    },
  },
  methods: {
    dismissAlert: function (): void {
      this.$store.commit('alerts/setNewAlertAvailable', false)
      this.$store.commit('alerts/setFirstTimeMessageRead')
    },
    startAlertsPolling(): void {
      const interval = setInterval(() => {
        // query the server for new alerts every 5 minutes
        this.$store.dispatch('alerts/getAlerts')
      }, 300000)
      this.$store.commit('alerts/setPollingAlerts', interval)
    },
    setIntersectionObserver(): void {
      const options = {
        rootMargin: '0px 0px 0px 0px',
        threshold: 1,
      }
      const target = document.querySelector('#navbar') as HTMLElement
      this.$store.commit(
        'setObserver',
        new IntersectionObserver(this.intersectionCallback, options),
      )
      this.observer.observe(target)
    },
    intersectionCallback(entries: Array<IntersectionObserverEntry>): void {
      entries.forEach((entry: IntersectionObserverEntry) => {
        this.$store.commit('navbar/setShadow', !entry.isIntersecting)
      })
    },
  },
  created() {
    this.$root.$on('notificationClick', this.dismissAlert)
    this.startAlertsPolling()
  },
  mounted() {
    this.setIntersectionObserver()
  },
  beforeDestroy() {
    //clean up
    clearInterval(this.polling)
    this.observer.disconnect()
    this.$store.commit('setObserver', null, { root: true })
  },
})
