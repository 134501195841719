












import Vue from 'vue'
export default Vue.extend({
  name: 'EnrollmentBillingHistory',
  created() {
    if (this.$store.state.enrollment.currentMember) {
      // default to employee, no menu selection on this page, but only if this is not page refresh
      this.$store.commit('enrollment/setPerson', this.$store.state.enrollment.currentMember)
      this.$store.commit(
        'enrollment/setSelectedMember',
        this.$store.state.enrollment.currentMember.memberID,
      )
    }
  },
})
