import Vue from 'vue'
import SelectField from '@/components/common/ui/SelectField/Index.vue'

export default Vue.extend({
  components: { SelectField },
  props: {
    value: {
      type: String,
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },
})
