import {
  GroupContactsDto,
  GroupInvoiceSettingsDto,
  GroupDivisionDto,
  GroupClassDto,
  GroupBenefitGroupDto,
} from '@/autogen-interfaces'
import { LoadableComponent, PageableComponentInterface } from '@/store/types'

export interface GroupStructure extends LoadableComponent, PageableComponentInterface {
  groupId: number
  dateFilter: Date
}

export interface GroupStructureDivision extends GroupStructure, PageableComponentInterface {
  invoiceSettings: GroupInvoiceSettingsDto[]
  groupContacts: GroupContactsDto[]
  groupDivisions: GroupDivisionDto[]
}

export interface GroupStructureClasses extends GroupStructure {
  groupClasses: GroupClassDto[]
}

export interface GroupStructureBenefits extends GroupStructure {
  groupBenefitGroups: GroupBenefitGroupDto[]
}

export interface GroupStructurePage {
  divisions: GroupStructureDivision
  classes: GroupStructureClasses
  benefitGroups: GroupStructureBenefits
}

export enum GroupStructureTabs {
  DIVISIONS = 'divisions',
  CLASSES = 'classes',
  BENEFITS_GROUP = 'benefitGroups',
}
