import Vue, { PropType } from 'vue'

interface MenuItem {
  text: string
  path: string
}

export default Vue.extend({
  props: {
    settings: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
    notification: {
      type: Boolean,
      required: true,
    },
  },
})
