var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('data-table',{attrs:{"loading":_vm.loading,"headers":_vm.dataTableHeader,"items":_vm.items,"pagination":_vm.pagination},on:{"request-filter":function($event){_vm.filters = $event}},scopedSlots:_vm._u([{key:"item.riderNameAndDescription",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0 col-12 body-md-heavy"},[_vm._v(_vm._s(item.riderName))]),_c('v-col',{staticClass:"ma-0 pa-0 col-12 body-md"},[_vm._v(_vm._s(item.riderDescription))])],1)]}},{key:"item.coverage",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"ma-0 pa-0 pl-3"},[_c('v-col',{staticClass:"ma-0 pa-0 col-12 body-md-heavy"},[_vm._v(_vm._s(item.coverage))]),_c('v-col',{staticClass:"ma-0 pa-0 col-12 body-md"},[_vm._v("Tier "+_vm._s(item.coverageTier))])],1)]}},{key:"item.effectiveDate",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"ma-0 pa-0 body-md"},[_vm._v(" "+_vm._s(item.effectiveDate)+" ")])]}},{key:"item.rates",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"ma-0 pa-0 body-md"},[_c('v-col',{staticClass:"ma-0 pa-0 col-6"},[_vm._v("Employee")]),_c('v-col',{staticClass:"ma-0 pa-0 col-6"},[_vm._v(_vm._s(item.employeeRate))]),_c('v-col',{staticClass:"ma-0 pa-0 col-6"},[_vm._v("Employer")]),_c('v-col',{staticClass:"ma-0 pa-0 col-6"},[_vm._v(_vm._s(item.employerRate))]),_c('v-col',{staticClass:"ma-0 pa-0 col-6 body-md-heavy"},[_vm._v("Total Rate")]),_c('v-col',{staticClass:"ma-0 pa-0 col-6 body-md-heavy"},[_vm._v(_vm._s(item.totalRate))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }