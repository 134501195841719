import { NavbarState } from './types'
import { AppData } from '@/AppData'

export const state: NavbarState = {
  shadow: true,
  logo: AppData.whitelabel.insightDemo
    ? require('@/assets/logo-boon-orange.png')
    : require('@/assets/logo-boon-blue.png'),
  backgroundColor: '#ffff',
  menuItems: [
    {
      text: 'Dashboard',
      path: '/',
    },
    {
      text: 'Billing',
      path: '/billing',
    },
    // {
    //   text: 'Reporting',
    //   path: 'reporting',
    // },
    {
      text: 'Enrollment',
      path: '/enrollment',
    },
    {
      text: 'Groups',
      path: '/user-groups',
    },
    // Out of this sprint
    // {
    //   text: 'Support',
    //   path: '#support',
    // },
  ],
  settings: [
    {
      text: 'Profile',
      path: 'profile',
    },
    {
      text: 'Get help',
      path: 'get-help',
    },
    {
      text: 'Logout',
      path: 'logout',
    },
  ],
}
