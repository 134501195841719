import Vue from 'vue'
import mixin from '@/mixin'
import moment from 'moment'
import FilterSingleDate from '@/components/common/ui/FilterSingleDate/Index.vue'
import { GroupDivisionDto } from '@/autogen-interfaces'
import { GroupStructureTabs } from '@/store/modules/groupStructure/types'
import { GridFilter, Operators, PageableComponentInterface, RequestFilter } from '@/store/types'
import DataTable from '@/components/common/ui/DataTable/Index.vue'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'

export default Vue.extend({
  components: { FilterSingleDate, DataTable },
  mixins: [mixin],
  data: () => ({
    filters: {} as RequestFilter,
    dataTableHeader: [
      { text: 'Class Name', value: 'className', sortable: false },
      { text: 'Class Code', value: 'classCode', align: 'center', sortable: false },
      { text: 'Class ID', value: 'groupClassId', align: 'center', sortable: false },
      { text: 'Effective Date', value: 'classEffDate', align: 'center', sortable: false },
      { text: 'Termination Date', value: 'classEndDate', align: 'center', sortable: false },
    ] as FilterableDataTableHeader[],
  }),
  methods: {
    fetchGroupClasses(): void {
      this.$store.dispatch('groupStructure/getGroupClassesInfo', this.groupClassesFilter)
    },
  },
  computed: {
    groupClasses(): GroupDivisionDto[] {
      return this.$store.state.groupStructure.classes.groupClasses
    },
    loading(): boolean {
      return this.$store.state.groupStructure.classes.loading
    },
    dateFilter: {
      get(): Date {
        return this.$store.state.groupStructure.classes.dateFilter
      },
      set(dateFilter) {
        this.$store.commit('groupStructure/setDateFilter', {
          dateFilter,
          tab: GroupStructureTabs.CLASSES,
        })
      },
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.groupStructure.classes.totalElements,
        totalPages: this.$store.state.groupStructure.classes.totalPages,
      }
    },
    groupClassesFilter(): RequestFilter {
      const filter: GridFilter[] = [
        {
          operator: Operators.GREATER_OR_EQUAL_THAN,
          field: 'classEffDate',
          value: moment(this.dateFilter as Date).format(),
          logic: '',
          filters: [],
        },
      ]
      return { ...this.filters, filter: filter.concat(this.filters.filter) }
    },
  },
  watch: {
    groupClassesFilter() {
      this.fetchGroupClasses()
    },
  },
})
