import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Dashboard from '@/views/dashboard/Dashboard.vue'
import Reporting from '@/views/reporting/Reporting.vue'
import Groups from '@/views/groups/Groups.vue'
import enrollmentRoutes from './enrollment'
import styleguideRoutes from './styleguide'
import groupsRoutes from './group'
import billingRoutes from '@/router/billing'
import GroupSelection from '@/views/groups/GroupSelection.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  ...billingRoutes,
  {
    path: '/reporting',
    name: 'Reporting',
    component: Reporting,
  },
  ...enrollmentRoutes,
  {
    path: '/group-info',
    name: 'Group Info',
    component: Groups,
  },
  {
    path: '/user-groups',
    name: 'User Groups',
    component: GroupSelection,
  },
  ...styleguideRoutes,
  ...groupsRoutes,
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router
