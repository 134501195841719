import Vue from 'vue'
import moment from 'moment'
import HeaderDashboard from '@/components/common/ui/HeaderDashboard/Index.vue'
import BannerBill from '@/components/common/ui/BannerBill/Index.vue'
import SolutaLegend from '@/components/common/ui/SolutaLegend/Index.vue'
import FilterDate from '@/components/common/ui/FilterDate/Index.vue'
import { FilterDateModel } from '@/components/common/ui/FilterDate/filterDate'

export default Vue.extend({
  name: 'Dashboard',
  components: {
    HeaderDashboard,
    BannerBill,
    SolutaLegend,
    FilterDate,
  },
  data() {
    return {
      dateRangeFilter: {
        beginDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate(),
      } as FilterDateModel,
      eligibility: [
        {
          title: 'Employee Only',
          option: 'opt1',
        },
        {
          title: 'Employee + Spouse',
          option: 'opt2',
        },
        {
          title: 'Employee + Family',
          option: 'opt3',
        },
      ],
    }
  },
  computed: {
    headerDashboard() {
      return {
        currentBalance: this.$store.state.dashboard.currentBalance,
        lastAmountPaid: this.$store.state.dashboard.lastAmountPaid,
        remaining: this.$store.state.dashboard.remaining,
        paymentStatus: this.$store.state.dashboard.paymentStatus,
        ach: this.$store.state.dashboard.ACHStatus,
      }
    },
  },
  methods: {
    viewBill() {
      this.$store.commit('dashboard/setShowBanner', false)
      this.$router.push({ name: 'Billing' })
    },
  },
})
