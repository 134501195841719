import Vue from 'vue'
import ButtonIcon from '@/components/common/ui/ButtonIcon.vue'

export default Vue.extend({
  components: { ButtonIcon },
  methods: {
    click: function (): void {
      console.log('')
    },
  },
})
