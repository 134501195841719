import Vue from 'vue'
import HeaderNav from '@/components/common/ui/HeaderNav/Index.vue'
import HeaderDashboard from '@/components/common/ui/HeaderDashboard/Index.vue'
import Tag from '@/components/common/ui/Tag/Index.vue'
import HeaderBasic from '@/components/common/ui/HeaderBasic/Index.vue'
import HeaderSearch from '@/components/common/ui/HeaderSearch/Index.vue'
import HeaderPlain from '@/components/common/ui/HeaderPlain/Index.vue'
import HeaderPlainGroup from '@/components/common/ui/HeaderPlainGroup/Index.vue'
import TagStatus from '@/components/common/ui/TagStatus/Index.vue'
import Avatar from '@/components/common/ui/Avatar/Index.vue'
import SolutaLegend from '@/components/common/ui/SolutaLegend/Index.vue'
import EmployeeCard from '@/components/common/ui/EmployeeCard/Index.vue'
import CompanyCard from '@/components/common/ui/CompanyCard/Index.vue'
import Pagination from '@/components/common/ui/Pagination/Index.vue'
import BannerBill from '@/components/common/ui/BannerBill/Index.vue'
import Alert from '@/components/common/ui/Alert/Index.vue'
import TextField from '@/components/common/ui/TextField/Index.vue'
import SelectField from '@/components/common/ui/SelectField/Index.vue'
import FilterField from '@/components/common/ui/FilterField/Index.vue'
import FilterDate from '@/components/common/ui/FilterDate/Index.vue'
import SearchBar from '@/components/common/ui/SearchBar/Index.vue'

export default Vue.extend({
  components: {
    HeaderNav,
    HeaderDashboard,
    Tag,
    HeaderBasic,
    HeaderSearch,
    HeaderPlain,
    TagStatus,
    HeaderPlainGroup,
    Avatar,
    SolutaLegend,
    EmployeeCard,
    CompanyCard,
    Pagination,
    BannerBill,
    Alert,
    TextField,
    SelectField,
    FilterField,
    FilterDate,
    SearchBar,
  },
  data() {
    return {
      individualFilter: 'Active',
      date: '',
      filterValue: 'All',
      show: false,
      menuItems: [
        {
          text: 'Billing',
          path: '#billing',
        },
        {
          text: 'Reporting',
          path: '#reporting',
        },
        {
          text: 'Enrollment',
          path: '#enrollment',
        },
        {
          text: 'Groups',
          path: '#groups',
        },
        {
          text: 'Support',
          path: '#support',
        },
      ],
      settings: [
        {
          text: 'Profile',
          path: '#profile',
        },
        {
          text: 'Get help',
          path: '#get-help',
        },
        {
          text: 'Logout',
          path: '#logout',
        },
      ],
      person: {
        name: 'Rebecca Montgomery Jorgenson, PhD',
        memberId: '549812345',
        city: 'City of Urbana',
        groupId: '84598400000',
        groupPolicyId: '56489123789',
        groupType: 'TPA',
        active: true,
        role: 'employee',
      },
      header: {
        city: 'City of Urbana',
        groupId: '84598400000',
        groupPolicyId: '56489123789',
        groupType: 'TPA',
        active: true,
      },
      employee: {
        name: 'Rebecca Montgomery Jorgenson PhD',
        active: true,
        position: 'employee',
      },
      headerInfo: {
        title: 'Signature HSA GAP',
        subtitle:
          'HSA High Gap Deductible $1400 Individual/$2800 Family Inpatient Max Benefit $4000',
        active: true,
        effective: 'Jan 1, 2021',
        paid: 'Jul 1, 2021',
      },
      headersLarge: [
        'Coverage Begin Date',
        'Coverage End Date',
        'Bill Type',
        'Billing Name',
        'Carrier Product Name',
        'Group Product Plan Name',
        'Coverage',
        'Employer Portion',
        'Employee Portion',
        'Total Amount',
        'Amount Applied',
        'Paid Up',
      ],
      headers: ['Group Desc', 'Invoice #', 'Bill Date', 'Status', 'Billing Total', 'Total Due'],
      headersSmall: [
        'Rider Name and Description',
        'Coverage',
        'Date Effective',
        'Rates',
        'Download',
      ],
      searchBarItems: [
        'Rebecca Montgomery Jorgenson PHD',
        'Arlene McCoy',
        'Darlene Robertson',
        'Guy Hawkins',
        'Theresa Webb',
        'Jane Cooper',
        'Annette Black',
        'Albert Flores',
      ],
      vModelTest: null,
    }
  },
})
