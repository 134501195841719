import Vue from 'vue'
import mixin from '@/mixin'
import moment from 'moment'
import Tag from '@/components/common/ui/Tag/Index.vue'
import FilterSingleDate from '@/components/common/ui/FilterSingleDate/Index.vue'
import BillingHistoryDetails from './details/BillingHistoryDetails.vue'
import DataTable from '@/components/common/ui/DataTable/Index.vue'
import { GroupBillingHistoryDto } from '@/autogen-interfaces'
import { RequestFilter, Operators, PageableComponentInterface, GridFilter } from '@/store/types'
import { base64ToBlob } from '@/helpers'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'

export default Vue.extend({
  components: { DataTable, FilterSingleDate, Tag, BillingHistoryDetails },
  data: () => ({
    filters: {} as RequestFilter,
    dataTableHeader: [
      { text: 'Invoice Desc', value: 'invoiceDesc', sortable: false },
      { text: 'Invoice #', value: 'invoiceIdent', align: 'start', sortable: false },
      { text: 'Bill Date', value: 'billDate', align: 'start', sortable: false },
      { text: 'Status', value: 'billStatus', align: 'start', sortable: false },
      { text: 'Billing Total', value: 'billingRecordTotal', align: 'end', sortable: false },
      { text: 'Total Due', value: 'netDue', align: 'end', sortable: false },
      { text: 'Download', value: 'download', align: 'center', sortable: false },
      { text: '', value: 'expand', align: 'start', sortable: false },
    ] as FilterableDataTableHeader[],
    showDetails: false,
  }),
  mixins: [mixin],
  methods: {
    setSelectedGroupBillingHistoryDto(item: GroupBillingHistoryDto): void {
      this.$store.commit('groups/setSelectedBillingHistory', item)
      this.showDetails = true
    },
    async downloadInvoice(invoiceNumber: string) {
      await this.$store.dispatch('groups/fetchInvoiceData', invoiceNumber)
      if (
        this.$store.state.groups.billingHistory.downloadInvoice &&
        this.$store.state.groups.billingHistory.downloadInvoice.documentData
      ) {
        const document = this.$store.state.groups.billingHistory.downloadInvoice
        base64ToBlob(
          document.documentData,
          document.mimeType,
          document.documentName || 'document',
          document.fileExtension,
        )
      }
    },
    fetchGroupBillingHistoryDto() {
      this.$store.dispatch('groups/fetchGroupBillingHistory', this.groupBillingHistoryFilter)
    },
  },
  computed: {
    items(): GroupBillingHistoryDto[] {
      return this.$store.state.groups.billingHistory.items
    },
    loading(): boolean {
      return this.$store.state.groups.loading
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.groups.billingHistory.totalElements,
        totalPages: this.$store.state.groups.billingHistory.totalPages,
      }
    },
    dateFilter: {
      get(): Date {
        return this.$store.state.groups.billingHistory.dateFilter
      },
      set(dateFilter) {
        this.$store.commit('groups/setBillingHistoryDateFilter', dateFilter)
      },
    },
    groupBillingHistoryFilter(): RequestFilter {
      const gridFilters: GridFilter[] = [
        {
          operator: Operators.GREATER_OR_EQUAL_THAN,
          field: 'billDate',
          value: moment(this.dateFilter as Date).format(),
          logic: '',
          filters: [],
        },
      ]
      return { ...this.filters, filter: gridFilters.concat(this.filters.filter) }
    },
  },
  watch: {
    async groupBillingHistoryFilter() {
      this.fetchGroupBillingHistoryDto()
    },
  },
})
