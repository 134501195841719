import Vue from 'vue'
import mixin from '@/mixin'
import moment from 'moment'
import { Group } from '@/store/modules/billing/types'
import Tag from '@/components/common/ui/Tag/Index.vue'
import BillingDetails from './details/BillingDetailsDrawer.vue'
import DataTable from '@/components/common/ui/DataTable/Index.vue'
import FilterDate from '@/components/common/ui/FilterDate/Index.vue'
import { GridFilter, Operators, PageableComponentInterface, RequestFilter } from '@/store/types'
import FilterField from '@/components/common/ui/FilterField/Index.vue'
import { FilterDateModel } from '@/components/common/ui/FilterDate/filterDate'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'
import { BillingStatementGridDto, BillingDivisionsDto } from '@/autogen-interfaces'

export default Vue.extend({
  components: { FilterField, FilterDate, BillingDetails, Tag, DataTable },
  mixins: [mixin],
  data: () => ({
    dateRangeFilter: {
      beginDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate(),
    } as FilterDateModel,
    dataTableFilters: {} as RequestFilter,
    selectedGroup: undefined as Group | undefined,
    selectedBillingDivision: undefined as BillingDivisionsDto | undefined,
    selectedState: '',
    openDrawer: false,
    headers: [
      { text: 'Bill Division', value: 'arDivisionNo' },
      { text: 'Group', value: 'groupId' },
      { text: 'Invoice #', value: 'invoiceNumber' },
      { text: 'Date Published', value: 'dateRecordAdded' },
      { text: 'Bill Period', value: 'depositDate' },
      { text: 'Charged', value: 'masCashAmountApplied' },
      { text: 'Status', value: 'paymentApplicationStatus' },
      { text: '', value: 'options' },
    ] as FilterableDataTableHeader[],
  }),
  methods: {
    formatDate: function (dateString: string): string {
      return dateString != null ? moment(dateString).format('MMM DD, YYYY') : ''
    },
    async fetchBillingStatements() {
      await this.$store.dispatch('billing/getBillingStatements', this.filters)
      this.selectedBillingDivision = this.selectedBillingDivision
        ? this.selectedBillingDivision
        : this.billingDivision[0]
      this.selectedGroup = this.selectedGroup ? this.selectedGroup : this.billingGroups[0]
    },
    showDetails(item: BillingStatementGridDto) {
      this.$store.commit('billing/setSelectedBillingStatement', item)
      this.openDrawer = true
    },
  },
  watch: {
    async filters() {
      await this.fetchBillingStatements()
    },
  },
  computed: {
    billingStatements(): BillingStatementGridDto[] {
      return this.$store.state.billing.billingStatements.items
    },
    billingGroups(): Group[] {
      return this.$store.state.billing.billingGroups
    },
    billingDivision(): BillingDivisionsDto[] {
      return this.$store.state.billing.billingDivisions
    },
    loading(): boolean {
      return this.$store.state.billing.loading
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.billing.billingStatements.totalElements,
        totalPages: this.$store.state.billing.billingStatements.totalPages,
      }
    },
    filters: function (): RequestFilter {
      const filters: Array<GridFilter> = []
      if (this.selectedGroup?.groupInvoiceSettingID !== -1)
        filters.push({
          operator: Operators.EQUAL,
          field: 'groupId',
          value: this.selectedGroup?.groupInvoiceSettingID
            ? this.selectedGroup.groupInvoiceSettingID.toString()
            : '0',
          logic: '',
          filters: [],
        })

      if (this.selectedBillingDivision?.groupInvoiceSettingID !== -1)
        filters.push({
          operator: Operators.EQUAL,
          field: 'billBatchId',
          value: this.selectedBillingDivision?.groupInvoiceSettingID
            ? this.selectedBillingDivision.groupInvoiceSettingID.toString()
            : '',
          logic: '',
          filters: [],
        })

      filters.push({
        operator: Operators.EQUAL,
        field: 'billStatus',
        value: this.selectedState,
        logic: '',
        filters: [],
      })

      const serverFormatDate = 'MM/DD/YYYY'
      filters.push({
        operator: Operators.GREATER_OR_EQUAL_THAN,
        field: 'beginDate',
        value: moment(this.dateRangeFilter.beginDate).format(serverFormatDate),
        logic: '',
        filters: [],
      })

      filters.push({
        operator: Operators.LOWER_OR_EQUAL_THAN,
        field: 'endDate',
        value: moment(this.dateRangeFilter.endDate).format(serverFormatDate),
        logic: '',
        filters: [],
      })
      return { ...this.dataTableFilters, filter: filters.concat(this.dataTableFilters.filter) }
    },
  },
})
