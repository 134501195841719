import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=2e2553a5&scoped=true&"
import script from "./notification.ts?vue&type=script&lang=ts&"
export * from "./notification.ts?vue&type=script&lang=ts&"
import style0 from "./notification.scss?vue&type=style&index=0&id=2e2553a5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e2553a5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VIcon,VRow})
