import Vue from 'vue'
import mixin from '@/mixin'
import moment from 'moment'
import { RequestFilter, Operators, PageableComponentInterface, GridFilter } from '@/store/types'
import Pagination from '@/components/common/ui/Pagination/Index.vue'
import ExpandableTable from '@/components/common/ui/ExpandableTable/Index.vue'
import { GroupStructureTabs } from '@/store/modules/groupStructure/types'
import FilterSingleDate from '@/components/common/ui/FilterSingleDate/Index.vue'
import { GroupContactsDto, GroupDivisionDto, GroupInvoiceSettingsDto } from '@/autogen-interfaces'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'

export default Vue.extend({
  components: { Pagination, FilterSingleDate, ExpandableTable },
  mixins: [mixin],
  data: () => ({
    filters: {} as RequestFilter,
    dataTableHeader: [
      { text: 'Division Name', value: 'divisionName', sortable: false },
      { text: 'Invoice Setting', value: 'invoiceSetting', align: 'center', sortable: false },
      { text: 'Invoice ID', value: 'invoiceId', align: 'center', sortable: false },
      { text: 'Location Code', value: 'locationCode', align: 'center', sortable: false },
      { text: 'Delivery Method', value: 'string', sortable: false },
      { text: 'Billing Mode', value: 'payCycle', align: 'center', sortable: false },
      { text: 'Effective Date', value: 'divisionEffDate', align: 'center', sortable: false },
      { text: 'Termination Date', value: 'divisionEndDate', align: 'center', sortable: false },
      { text: '', value: 'data-table-expand', sortable: false },
    ] as FilterableDataTableHeader[],
  }),
  computed: {
    groupDivisions(): GroupDivisionDto[] {
      return this.$store.state.groupStructure.divisions.groupDivisions
    },
    loading(): boolean {
      return this.$store.state.groupStructure.divisions.loading
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.groupStructure.divisions.totalElements,
        totalPages: this.$store.state.groupStructure.divisions.totalPages,
      }
    },
    dateFilter: {
      get(): Date {
        return this.$store.state.groupStructure.divisions.dateFilter
      },
      set(dateFilter) {
        this.$store.commit('groupStructure/setDateFilter', {
          dateFilter,
          tab: GroupStructureTabs.DIVISIONS,
        })
      },
    },
    groupDivisionFilter(): RequestFilter {
      const filter: GridFilter[] = [
        {
          operator: Operators.GREATER_OR_EQUAL_THAN,
          field: 'divisionEffDate',
          value: moment(this.dateFilter as Date).format(),
          logic: '',
          filters: [],
        },
      ]
      return { ...this.filters, filter: filter.concat(this.filters.filter) }
    },
  },
  watch: {
    groupDivisionFilter() {
      this.fetchGroupDivision()
    },
  },
  methods: {
    fetchGroupDivision(): void {
      this.$store.dispatch('groupStructure/getGroupDivisionInfo', this.groupDivisionFilter)
    },
    getInvoiceSetting(groupInvoiceSettingID: number): GroupInvoiceSettingsDto {
      const invoiceSetting = this.$store.state.groupStructure.divisions.invoiceSettings.find(
        (item: GroupInvoiceSettingsDto) => item.groupInvoiceSettingID === groupInvoiceSettingID,
      )
      return invoiceSetting !== undefined ? invoiceSetting : {}
    },
    getGroupContact(groupId: number): GroupContactsDto {
      const groupContact = this.$store.state.groupStructure.divisions.groupContacts.find(
        (item: GroupContactsDto) => item.groupId === groupId,
      )
      return groupContact !== undefined ? groupContact : {}
    },
  },
})
