import Vue from 'vue'
import Tag from '@/components/common/ui/Tag/Index.vue'

export default Vue.extend({
  props: {
    currentBalance: {
      type: Number,
      default: 0,
    },
    lastAmountPaid: {
      type: Number,
      default: 0,
    },
    remaining: {
      type: Number,
      default: 0,
    },
    paymentStatus: {
      type: String,
      default: 'pending',
    },
    ach: {
      type: String,
      default: 'Unapproved',
    },
    paymentStatusTooltip: {
      type: String,
      default: '',
    },
    achTooltip: {
      type: String,
      default: '',
    },
  },
  components: {
    Tag,
  },
  methods: {
    formatMoney: function (number: number): string {
      return number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },
  },
  data: function () {
    return {
      columnClass:
        'col-xs-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 mr-2 mx-md-2 mr-sm-0 my-md-4 my-sm-3 body-md-heavy',
    }
  },
  computed: {
    achSuccess() {
      return this.ach === 'Completed'
    },
    achWarning() {
      return this.ach === 'Approved'
    },
    achError() {
      return this.ach === 'Unapproved'
    },
    paymentSuccess() {
      return this.paymentStatus === 'paid'
    },
    paymentWarning() {
      return this.paymentStatus === 'pending'
    },
    paymentError() {
      return this.paymentStatus === 'past due'
    },
  },
})
