import Vue from 'vue'
import TextField from '@/components/common/ui/TextField/Index.vue'

export default Vue.extend({
  props: {
    value: {
      type: String,
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },
  components: { TextField },
})
