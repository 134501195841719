import { GroupDocumentsDto, GroupNotesDto } from '@/autogen-interfaces'
import { Document, Note } from '@/store/modules/groupDocuments/types'
import { formatDate } from '@/helpers'

export class DocumentEntityToDto {
  public static convert(groupDocumentsDto: GroupDocumentsDto): Document {
    return {
      documentId: groupDocumentsDto.documentId ? groupDocumentsDto.documentId : -1,
      comment: this.nullCheck(groupDocumentsDto.comment),
      dateAdded: groupDocumentsDto.insertDate
        ? formatDate(groupDocumentsDto.insertDate, 'MM/DD/YYYY')
        : '',
      downloadUrl: '',
      name: this.nullCheck(groupDocumentsDto.name),
      size: groupDocumentsDto.storageSize ? groupDocumentsDto.storageSize + ' KB' : -1 + ' KB',
      type: this.nullCheck(groupDocumentsDto.documentType),
    }
  }

  private static nullCheck(value: string | null | undefined): string {
    return value || ''
  }
}

export class NoteEntityToDto {
  public static convert(groupNotesDto: GroupNotesDto): Note {
    return {
      entity: this.nullCheck(groupNotesDto.entities),
      updatedBy: this.nullCheck(groupNotesDto.lastUpdatedBy),
      dateUpdated: groupNotesDto.lastUpdated
        ? formatDate(groupNotesDto.lastUpdated, 'MM/DD/YYYY')
        : '',
      note: this.nullCheck(groupNotesDto.noteText),
      noteId: groupNotesDto.notesGlobalNoteIDInt ? `${groupNotesDto.notesGlobalNoteIDInt}` : '0',
      noteType: this.nullCheck(groupNotesDto.noteType),
      phoneNumber: this.nullCheck(groupNotesDto.phoneNumber),
      resolutionType: this.nullCheck(groupNotesDto.resolutionType),
      tin: this.nullCheck(groupNotesDto.tin),
    }
  }

  private static nullCheck(value: string | null | undefined): string {
    return value || ''
  }
}
