import Vue from 'vue'
import TextField from '@/components/common/ui/TextField/Index.vue'
import moment from 'moment'

const inputDateFormat = 'MM/DD/YYYY'

export default Vue.extend({
  props: {
    label: {
      type: String,
      default: 'period',
    },
    value: Date,
    nullable: {
      type: Boolean,
      default: false,
    },
  },
  components: { TextField },
  data: () => ({
    menu: false,
    inputDate: '',
    selectValue: '',
  }),
  created() {
    if ((this.value === null || this.value === undefined) && !this.nullable) {
      this.$emit('input', moment().toDate())
    }
    this.$nextTick(() => {
      this.setInputValues()
      this.setSelectValue()
    })
  },
  methods: {
    setInputValues: function (): void {
      this.inputDate = this.value
        ? this.dateToString(new Date(this.value), inputDateFormat)
        : this.value
    },
    setSelectValue: function (): void {
      const selectDateFormat = 'MMM Do YYYY'
      this.selectValue = this.value
        ? this.dateToString(new Date(this.value), selectDateFormat)
        : 'All records'
      this.closeMenuIfOpen()
    },
    setVModelValue: function (): void {
      this.$emit('input', this.inputDate ? moment(this.inputDate, inputDateFormat).toDate() : null)
    },
    setValues: function (): void {
      this.setVModelValue()
      this.$nextTick(() => {
        this.setInputValues()
        this.setSelectValue()
      })
    },
    dateToString: function (date: Date, format: string): string {
      return moment(date).format(format)
    },
    closeMenuIfOpen: function (): void {
      this.menu = this.menu ? !this.menu : this.menu
    },
  },
})
