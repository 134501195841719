import { ActionTree } from 'vuex'
import { PageableComponentInterface, RequestFilter, RootState } from '@/store/types'
import GroupStructureApi from '@/api/GroupStructureApi'
import { ApiError } from '@/store/utils/types'
import { withAsync } from '@/store/utils/withAsync'
import {
  GroupBenefitGroupDto,
  GroupClassDto,
  GroupContactsDto,
  GroupDivisionDto,
  GroupInvoiceSettingsDto,
} from '@/autogen-interfaces'
import { GroupStructurePage, GroupStructureTabs } from '@/store/modules/groupStructure/types'

export const actions: ActionTree<GroupStructurePage, RootState> = {
  async getGroupClassesInfo({ commit, rootState }, filters: RequestFilter) {
    commit('setLoading', { loading: true, tab: GroupStructureTabs.CLASSES })
    const { error, data }: { error: ApiError; data: GroupClassDto[] } = await withAsync(
      GroupStructureApi.fetchGroupClasses,
      GroupStructureApi,
      rootState.selectedUserGroupId,
      filters,
    )
    if (error) {
      console.error(error)
    } else {
      const pagination: PageableComponentInterface = {
        totalElements: data.length,
        totalPages: 1,
      }
      commit('setGroupClasses', data)
      commit('setPagination', { pagination, tab: GroupStructureTabs.CLASSES })
    }
    commit('setLoading', { loading: false, tab: GroupStructureTabs.CLASSES })
  },
  async getGroupDivisionInfo({ commit, state, rootState }, filters: RequestFilter) {
    commit('setLoading', { loading: true, tab: GroupStructureTabs.DIVISIONS })
    const GroupId = rootState.selectedUserGroupId
    const { error, data }: { error: ApiError; data: GroupDivisionDto[] } = await withAsync(
      GroupStructureApi.fetchGroupDivisions,
      GroupStructureApi,
      GroupId,
      filters,
    )
    if (error) {
      console.error(error)
    } else {
      const pagination: PageableComponentInterface = {
        totalElements: data.length,
        totalPages: 1,
      }
      commit('setGroupDivisions', data)
      commit('setPagination', { pagination, tab: GroupStructureTabs.DIVISIONS })
    }
    const groupDivisions = state.divisions.groupDivisions
    if (groupDivisions) {
      for (const groupDivision of groupDivisions) {
        // adding invoiceSettings
        const invoiceSetting = state.divisions.invoiceSettings?.find((invoiceSetting) => {
          return invoiceSetting.groupInvoiceSettingID === groupDivision.groupInvoiceSettingId
        })
        if (!invoiceSetting) {
          await this.dispatch('groupStructure/getGroupInvoiceSetting', groupDivision.groupId)
        }
        // adding group contact
        const groupContactInfo = state.divisions.groupContacts?.find((contactInfo) => {
          return contactInfo.groupId === groupDivision.groupId
        })
        if (!groupContactInfo) {
          await this.dispatch('groupStructure/getGroupContactInfo', groupDivision.groupId)
        }
      }
    }
    commit('setLoading', { loading: false, tab: GroupStructureTabs.DIVISIONS })
  },
  async getGroupInvoiceSetting({ commit }, groupId: number) {
    const { error, data }: { error: ApiError; data: GroupInvoiceSettingsDto[] } = await withAsync(
      GroupStructureApi.fetchGroupInvoiceSetting,
      GroupStructureApi,
      groupId,
    )
    if (error) {
      console.error(error)
    } else {
      for (const invoiceSetting of data) {
        commit('addGroupInvoiceSetting', invoiceSetting)
      }
    }
  },
  async getGroupContactInfo({ commit }, groupId: number) {
    const { error, data }: { error: ApiError; data: GroupContactsDto[] } = await withAsync(
      GroupStructureApi.fetchGroupContact,
      GroupStructureApi,
      groupId,
    )
    if (error) {
      console.error(error)
    } else {
      for (const contact of data) {
        contact.groupId = groupId // TODO check this relation
        commit('addGroupContact', contact)
      }
    }
  },
  async getGroupBenefitsInfo({ commit, rootState }, filters: RequestFilter) {
    commit('setLoading', { loading: true, tab: GroupStructureTabs.BENEFITS_GROUP })
    const { error, data }: { error: ApiError; data: GroupBenefitGroupDto[] } = await withAsync(
      GroupStructureApi.fetchGroupBenefitGroups,
      GroupStructureApi,
      rootState.selectedUserGroupId,
      filters,
    )
    if (error) {
      console.error(error)
    } else {
      const pagination: PageableComponentInterface = {
        totalElements: data.length,
        totalPages: 1,
      }
      commit('setGroupBenefitGroups', data)
      commit('setPagination', { pagination, tab: GroupStructureTabs.BENEFITS_GROUP })
    }
    commit('setLoading', { loading: false, tab: GroupStructureTabs.BENEFITS_GROUP })
  },
}

export default actions
