import Vue, { PropType } from 'vue'
import CompanyCard from '@/components/common/ui/CompanyCard/Index.vue'
import CompanyCardInterface from '@/components/common/interface/companyCardInterface'

interface ProductCardInterface {
  headerInfo: CompanyCardInterface
  carrier: string
  coverage: {
    name: string
    value: string
  }
  gppoId: string
  extPlanCode: string
}

export default Vue.extend({
  components: { CompanyCard },
  props: {
    cardInfo: {
      type: Object as PropType<ProductCardInterface>,
      required: true,
    },
  },
})
