import Vue from 'vue'

interface VSelectField extends HTMLFormElement {
  computedId: string
}

export default Vue.extend({
  name: 'SelectField',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    individualFilter: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    vSelectFieldId: '',
  }),
  mounted() {
    this.vSelectFieldId = this.vSelectField.computedId
  },
  computed: {
    vSelectField: function (): VSelectField {
      return this.$refs.vSelectField as VSelectField
    },
  },
})
