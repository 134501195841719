import Vue, { PropType } from 'vue'
import TagStatus from '@/components/common/ui/TagStatus/Index.vue'

interface CardEnrollmentInterface {
  isActive: boolean
  enrollmentMethod: string
  enrollmentStatus: string
  billConfiguration: string
  initialEnrollmentDate: string
  annualEnrollmentDate: string
  initEnrollmentDate: string
}

export default Vue.extend({
  props: {
    cardInfo: {
      type: Object as PropType<CardEnrollmentInterface>,
      required: true,
    },
  },
  components: { TagStatus },
})
