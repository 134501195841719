import Vue from 'vue'

export default Vue.extend({
  name: 'CardAttachment',
  props: {
    vMenu: {
      type: Boolean,
      default: true,
    },
  },
})
