import moment from 'moment'
import { GroupDocuments, GroupNotes } from '@/store/modules/groupDocuments/types'

export const groupDocumentState: GroupDocuments = {
  documents: [],
  filterDates: {
    beginDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(),
  },
  totalElements: 10,
  totalPages: 1,
  loading: false,
}

export const groupNotesState: GroupNotes = {
  notes: [],
  filterDates: {
    beginDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(),
  },
  totalElements: 10,
  totalPages: 1,
  loading: false,
}
