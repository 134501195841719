import { Api } from '@/api/Api'

class UserApi extends Api {
  auth(username: string, password: string) {
    return this.post('Users/Auth', null, undefined, {
      username,
      password,
      rememberMe: false,
    })
  }

  updatePassword(username: string, oldpassword: string, newpassword: string) {
    return this.post('Users/Update_Password', null, undefined, {
      username,
      oldpassword,
      newpassword,
    })
  }

  userAssociations(userId: string) {
    return this.get('Users/Get_UserAssociations', {
      RequestingUserId: userId, // Impersonation user. Out of scope.
      ApplicationUserId: userId,
    })
  }
}

export default new UserApi()
