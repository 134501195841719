import Vue from 'vue'
import moment from 'moment'
import mixin from '@/mixin'
import FilterSingleDate from '@/components/common/ui/FilterSingleDate/Index.vue'
import { GroupBenefitGroupDto } from '@/autogen-interfaces'
import { GroupStructureTabs } from '@/store/modules/groupStructure/types'
import { GridFilter, Operators, PageableComponentInterface, RequestFilter } from '@/store/types'
import DataTable from '@/components/common/ui/DataTable/Index.vue'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'

export default Vue.extend({
  components: { FilterSingleDate, DataTable },
  mixins: [mixin],
  data: () => ({
    filters: {} as RequestFilter,
    dataTableHeader: [
      { text: 'Benefit Group Name', value: 'groupBenefitGroupName', sortable: false },
      {
        text: 'Benefit Group Description',
        value: 'groupBenefitGroupDescription',
        align: 'center',
        sortable: false,
      },
      { text: 'External ID', value: 'externalIdentifier', align: 'center', sortable: false },
      { text: 'Effective Date', value: 'beginDate', align: 'center', sortable: false },
      { text: 'Termination Date', value: 'endDate', align: 'center', sortable: false },
    ] as FilterableDataTableHeader[],
  }),
  methods: {
    fetchGroupBenefitGroup(): void {
      this.$store.dispatch('groupStructure/getGroupBenefitsInfo', this.groupBenefitsFilter)
    },
  },
  computed: {
    items(): GroupBenefitGroupDto[] {
      return this.$store.state.groupStructure.benefitGroups.groupBenefitGroups
    },
    loading(): boolean {
      return this.$store.state.groupStructure.benefitGroups.loading
    },
    dateFilter: {
      get(): Date {
        return this.$store.state.groupStructure.benefitGroups.dateFilter
      },
      set(dateFilter) {
        this.$store.commit('groupStructure/setDateFilter', {
          dateFilter,
          tab: GroupStructureTabs.BENEFITS_GROUP,
        })
      },
    },
    groupBenefitsFilter(): RequestFilter {
      const filter: GridFilter[] = [
        {
          operator: Operators.GREATER_OR_EQUAL_THAN,
          field: 'endDate',
          value: moment(this.dateFilter as Date).format(),
          logic: '',
          filters: [],
        },
      ]
      return { ...this.filters, filter: filter.concat(this.filters.filter) }
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.groupStructure.benefitGroups.totalElements,
        totalPages: this.$store.state.groupStructure.benefitGroups.totalPages,
      }
    },
  },
  watch: {
    groupBenefitsFilter() {
      this.fetchGroupBenefitGroup()
    },
  },
})
