import Vue from 'vue'
import Pagination from '@/components/common/ui/Pagination/Index.vue'

export default Vue.extend({
  components: { Pagination },
  data: function () {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        {
          text: 'Coverage Begin Date',
          align: 'start',
          sortable: false,
          value: 'coverage_begin_date',
        },
        { text: 'Coverage End Date', value: 'coverage_end_date' },
        { text: 'Bill Type', value: 'bill_type' },
        { text: 'Billing Name', value: 'billing_name' },
        { text: 'Carrier Product Name', value: 'carrier_product_name' },
        { text: 'Group Product Plan Name', value: 'group_product_plan_name' },
        { text: 'Coverage', value: 'coverage' },
        { text: 'Employer Portion', value: 'employer_portion' },
        { text: 'Employee Portion', value: 'employee_portion' },
        { text: 'Total Amount', value: 'total_amount' },
        { text: 'Amount Applied', value: 'amount_applied' },
        { text: 'Paid Up', value: 'paid_up' },
      ],
      coverage: [
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Premium',
          billing_name: 'Kemper Care Fee',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$38.58',
          total_amount: '$38.58',
          amount_applied: '$38.58',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Free',
          billing_name: 'Kemper Care Fee',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '$50,000',
          employer_portion: '$0.00',
          employee_portion: '$3.00',
          total_amount: '$3.00',
          amount_applied: '$3.00',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Free',
          billing_name: 'Kemper Care Fee',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$1.00',
          total_amount: '$1.00',
          amount_applied: '$1.00',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Free',
          billing_name: 'Network Fee',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$1.00',
          total_amount: '$1.00',
          amount_applied: '$1.00',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Free',
          billing_name: 'Kemper Care Fee',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$3.00',
          total_amount: '$3.00',
          amount_applied: '$3.00',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Premium',
          billing_name: 'PPO Fee',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$0.00',
          total_amount: '$0.00',
          amount_applied: '$0.00',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Premium',
          billing_name: 'RX Rider - RX Generic $1000',
          carrier_product_name: 'Minimal Essential Coverage',
          group_product_plan_name: 'MEC-1',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$56.81',
          total_amount: '$56.81',
          amount_applied: '$56.81',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Fee',
          billing_name: 'Administration',
          carrier_product_name: 'Minimal Essential Coverage',
          group_product_plan_name: 'MEC-1',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$8.00',
          total_amount: '$8.00',
          amount_applied: '$8.00',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Fee',
          billing_name: 'Administration - Bank Acct Admin',
          carrier_product_name: 'Minimal Essential Coverage',
          group_product_plan_name: 'MEC-1',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$0.25',
          total_amount: '$0.25',
          amount_applied: '$0.25',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Fee',
          billing_name: 'COBRA Admin Fee',
          carrier_product_name: 'Minimal Essential Coverage',
          group_product_plan_name: 'MEC-1',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$1.00',
          total_amount: '$1.00',
          amount_applied: '$1.00',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Premium',
          billing_name: 'Kemper Care Fee 1',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$38.58',
          total_amount: '$38.58',
          amount_applied: '$38.58',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Premium',
          billing_name: 'Kemper Care Fee 2',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$38.58',
          total_amount: '$38.58',
          amount_applied: '$38.58',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Premium',
          billing_name: 'Kemper Care Fee 3',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$38.58',
          total_amount: '$38.58',
          amount_applied: '$38.58',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Premium',
          billing_name: 'Kemper Care Fee 4',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$38.58',
          total_amount: '$38.58',
          amount_applied: '$38.58',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Premium',
          billing_name: 'Kemper Care Fee 5',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$38.58',
          total_amount: '$38.58',
          amount_applied: '$38.58',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Premium',
          billing_name: 'Kemper Care Fee 6',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$38.58',
          total_amount: '$38.58',
          amount_applied: '$38.58',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Premium',
          billing_name: 'Kemper Care Fee 7',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$38.58',
          total_amount: '$38.58',
          amount_applied: '$38.58',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Premium',
          billing_name: 'Kemper Care Fee 8',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$38.58',
          total_amount: '$38.58',
          amount_applied: '$38.58',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Premium',
          billing_name: 'Kemper Care Fee 9',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$38.58',
          total_amount: '$38.58',
          amount_applied: '$38.58',
          paid_up: 'Yes',
        },
        {
          coverage_begin_date: '01/20/21',
          coverage_end_date: '01/20/21',
          bill_type: 'Premium',
          billing_name: 'Kemper Care Fee 10',
          carrier_product_name: 'Limited Medical Benefit Plan',
          group_product_plan_name: 'Limited Medical Benefit Plan Custom Build 9',
          coverage: '0',
          employer_portion: '$0.00',
          employee_portion: '$38.58',
          total_amount: '$38.58',
          amount_applied: '$38.58',
          paid_up: 'Yes',
        },
      ],
    }
  },
})
