























































































































































import TagStatus from '@/components/common/ui/TagStatus/Index.vue'
import GroupCard from '@/components/common/ui/GroupCard/GroupCard.vue'

import Vue from 'vue'
export default Vue.extend({
  name: 'GroupInfo',
  components: {
    TagStatus,
    GroupCard,
  },
  computed: {
    info() {
      return this.$store.state.groups.info
    },
    loading() {
      return this.$store.state.groups.loading
    },
  },
})
