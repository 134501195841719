import Vue, { PropType } from 'vue'
import TagStatus from '@/components/common/ui/TagStatus/Index.vue'

interface Employee {
  name: string
  active: boolean
  position: string
}

export default Vue.extend({
  components: { TagStatus },
  props: {
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },
  },
  computed: {
    changeFont: function (): boolean {
      return this.$vuetify.breakpoint.width < 1150
    },
  },
})
