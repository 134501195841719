import { Api } from '@/api/Api'
import { RequestFilter } from '@/store/types'
import { AppData } from '@/AppData'

class NotesApi extends Api {
  fetchGroupNoteDetails(groupId: number, requestFilter: RequestFilter) {
    return this.get('Notes/Get_GroupNoteDetails', {
      GroupId: groupId,
      ApplicationUser: AppData.associations.UserId,
      GridHelper: requestFilter,
    })
  }

  fetchMemberNoteDetails(memberId: number, memberTypeId: number, requestFilter: RequestFilter) {
    return this.get('Notes/Get_MemberNoteDetails', {
      MemberID: memberId,
      MemberTypeID: memberTypeId ? memberTypeId : 40,
      Expired: true,
      ApplicationUser: AppData.associations.UserId,
      GridHelper: requestFilter,
    })
  }

  /**
   * Fetch note types
   *
   * @returns {AxiosPromise<DocumentTypeDto[]>}
   *
   */
  fetchNoteTypes() {
    return this.get('Documents/Get_DocumentTypes', { ApplicationUser: AppData.associations.UserId })
  }
}

export default new NotesApi()
