import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=aa775b64&scoped=true&"
import script from "./headerDashboard.ts?vue&type=script&lang=ts&"
export * from "./headerDashboard.ts?vue&type=script&lang=ts&"
import style0 from "./headerDashboard.scss?vue&type=style&index=0&id=aa775b64&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa775b64",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
