import Vue from 'vue'
import DataTable from '@/components/common/ui/DataTable/Index.vue'
import { PageableComponentInterface, RequestFilter } from '@/store/types'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'
import HeaderSearch from '@/components/common/ui/HeaderSearch/Index.vue'
import TextField from '@/components/common/ui/TextField/Index.vue'
import { AdvanceSearch } from '@/store/modules/groupUserSelection/type'

export default Vue.extend({
  components: { DataTable, HeaderSearch, TextField },
  data: () => ({
    filters: {} as RequestFilter,
    showAdvancedSearch: false,
    advanceSearch: {
      groupId: '',
      groupName: '',
      groupNumber: '',
      altGroupNumber: '',
    } as AdvanceSearch,
    searchBoxText: '',
    dataTableHeader: [
      { text: 'Group ID', value: 'groupId', width: '10%', align: 'center' },
      { text: 'Group Name', value: 'groupName', width: '25%', align: 'start' },
      { text: 'Group Description', value: 'groupDescription', width: '25%', align: 'start' },
      { text: 'External ID', value: 'externalId', width: '10%', align: 'center' },
      { text: 'Effective Date', value: 'effectiveDate', width: '15%', align: 'center' },
      { text: 'Termination Date', value: 'terminationDate', width: '15%', align: 'center' },
    ] as FilterableDataTableHeader[],
  }),
  beforeMount() {
    this.selectedUserGroupId = null
  },
  methods: {
    async fetchUserGroupData(input?: string) {
      await this.$store.dispatch('groupUserSelection/fetchOrSelectUserGroups', {
        requestFilters: this.filters,
        input,
      })
    },
    async executeAdvancedSearch() {
      await this.$store.dispatch('groupUserSelection/fetchUserGroupsAdvance', {
        requestFilters: this.filters,
        advanceSearch: {
          ...this.advanceSearch,
          groupId: this.advanceSearch.groupId === '' ? -1 : this.advanceSearch.groupId, // If empty value is sent, BE will give 500 error
        },
      })
    },
    async executeGlobalSearch() {
      await this.$store.dispatch('groupUserSelection/fetchUserGroups', {
        groupIds: this.$store.state.groupUserSelection.userGroupIds,
        requestFilters: this.filters,
        input: this.searchBoxText,
      })
    },
    checkRedirection() {
      if (this.selectedUserGroupId) {
        this.$router.push({ name: 'Group' })
      }
    },
  },
  computed: {
    selectedUserGroupId: {
      get(): string | null {
        return this.$store.state.selectedUserGroupId
      },
      set(selectedUserGroupId: string) {
        this.$store.commit('setSelectedUserGroupId', selectedUserGroupId)
      },
    },
    benefitsGroups() {
      return this.$store.state.groupUserSelection.items
    },
    loading(): boolean {
      return this.$store.state.groupUserSelection.loading
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.groupUserSelection.totalElements,
        totalPages: this.$store.state.groupUserSelection.totalPages,
      }
    },
  },
  watch: {
    selectedUserGroupId() {
      this.checkRedirection()
    },
    filters() {
      this.fetchUserGroupData()
    },
  },
})
