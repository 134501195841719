var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 mt-8",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"col-lg-4 col-md-12"},[_c('v-btn-toggle',{attrs:{"mandatory":""}},[_c('v-btn',{on:{"click":function($event){_vm.selectedState = 'all'}}},[_vm._v("All")]),_c('v-btn',{on:{"click":function($event){_vm.selectedState = 'unpaid'}}},[_vm._v("Unpaid")]),_c('v-btn',{on:{"click":function($event){_vm.selectedState = 'pending'}}},[_vm._v("Pending")]),_c('v-btn',{on:{"click":function($event){_vm.selectedState = 'paid'}}},[_vm._v("Paid")])],1)],1),_c('v-col',{staticClass:"col-lg-8 col-md-12"},[_c('v-row',[_c('v-col',{staticClass:"col-4"},[_c('filter-field',{attrs:{"items":_vm.billingGroups,"item-text":"groupName","return-object":"","no-data-text":"No groups found","label":"groups"},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}})],1),_c('v-col',{staticClass:"col-4"},[_c('filter-field',{attrs:{"items":_vm.billingDivision,"item-text":"invoiceDesc","return-object":"","no-data-text":"No divisions found","label":"bill divisions"},model:{value:(_vm.selectedBillingDivision),callback:function ($$v) {_vm.selectedBillingDivision=$$v},expression:"selectedBillingDivision"}})],1),_c('v-col',{staticClass:"col-4"},[_c('filter-date',{attrs:{"label":"date range"},model:{value:(_vm.dateRangeFilter),callback:function ($$v) {_vm.dateRangeFilter=$$v},expression:"dateRangeFilter"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-12"},[_c('data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.billingStatements,"pagination":_vm.pagination},on:{"request-filter":function($event){_vm.dataTableFilters = $event}},scopedSlots:_vm._u([{key:"item.arDivisionNo",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){_vm.showDetails = true}}},[_vm._v(_vm._s(item.billBatchID))])]}},{key:"item.groupId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.groupName)+" ")]}},{key:"item.dateRecordAdded",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.billRunDateTime))+" ")]}},{key:"item.depositDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"body-md-heavy"},[_vm._v(_vm._s(_vm.formatDate(item.billDate))+" - "),_c('br'),_vm._v(" "+_vm._s(_vm.formatDate(item.billDate))+" ")])]}},{key:"item.masCashAmountApplied",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"body-md-heavy"},[_vm._v(_vm._s(_vm.formatMoney(item.cashAmountApplied)))])]}},{key:"item.paymentApplicationStatus",fn:function(ref){
var item = ref.item;
return [_c('tag',{attrs:{"success":item.billStatus === 'Completed',"error":item.billStatus === 'Unpaid',"warning":item.billStatus === 'Pending',"tooltip":'message'}},[_c('span',{staticClass:"text-capitalize pl-1 pr-2"},[_vm._v(" "+_vm._s(item.billStatus)+" ")])])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v("View Details")])]}}],null,true)})],1)],1),_c('billing-details',{attrs:{"show":_vm.openDrawer},on:{"closeDrawer":function($event){_vm.openDrawer = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }