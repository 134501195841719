import { MutationTree } from 'vuex'
import { EnrollmentStatus, EnrollmentStatusPage } from '@/store/modules/enrollmentStatus/types'
import { loadableMutation } from '@/store/mutations'

export const mutations: MutationTree<EnrollmentStatusPage> = {
  setDateFilter(state, dateFilter: Date | null) {
    state.dateFilter = dateFilter
  },
  setCurrentStatus(state, currentStatus: EnrollmentStatus) {
    state.currentStatus = currentStatus
  },
  setStatusHistory(state, statusHistory: EnrollmentStatus[]) {
    state.statusHistory = statusHistory
  },
  ...loadableMutation,
}
