import { GroupUserSelection } from '@/store/modules/groupUserSelection/type'

const state: GroupUserSelection = {
  userGroupIds: [],
  items: [],
  loading: false,
  totalElements: 0,
  totalPages: 0,
}

export default state
