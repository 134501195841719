import { MutationTree } from 'vuex'
import { GroupStructurePage, GroupStructureTabs } from '@/store/modules/groupStructure/types'
import {
  GroupBenefitGroupDto,
  GroupClassDto,
  GroupContactsDto,
  GroupDivisionDto,
  GroupInvoiceSettingsDto,
} from '@/autogen-interfaces'
import { PageableComponentInterface } from '@/store/types'

const mutations: MutationTree<GroupStructurePage> = {
  addGroupContact(state, groupContactsDto: GroupContactsDto) {
    const found = state.divisions.groupContacts?.find((item: GroupContactsDto) => {
      return item.groupId === groupContactsDto.groupId
    })
    if (!found) {
      state.divisions.groupContacts.push(groupContactsDto)
    }
  },
  addGroupInvoiceSetting(state, groupInvoiceSettingsDto: GroupInvoiceSettingsDto) {
    const found = state.divisions.invoiceSettings?.find((item: GroupInvoiceSettingsDto) => {
      return item.groupInvoiceSettingID === groupInvoiceSettingsDto.groupInvoiceSettingID
    })
    if (!found) {
      state.divisions.invoiceSettings.push(groupInvoiceSettingsDto)
    }
  },
  setGroupDivisions(state, groupDivisions: GroupDivisionDto[]) {
    state.divisions.groupDivisions = groupDivisions
  },
  setGroupClasses(state, groupClasses: GroupClassDto[]) {
    state.classes.groupClasses = groupClasses
  },
  setGroupBenefitGroups(state, groupBenefitGroups: GroupBenefitGroupDto[]) {
    state.benefitGroups.groupBenefitGroups = groupBenefitGroups
  },
  setDateFilter(state, { dateFilter, tab }: { dateFilter: Date; tab: GroupStructureTabs }) {
    state[tab].dateFilter = dateFilter
  },
  setLoading(state, { loading, tab }: { loading: boolean; tab: GroupStructureTabs }) {
    state[tab].loading = loading
  },
  setPagination(
    state,
    { pagination, tab }: { pagination: PageableComponentInterface; tab: GroupStructureTabs },
  ) {
    state[tab].totalElements = pagination.totalElements
    state[tab].totalPages = pagination.totalPages
  },
}

export default mutations
