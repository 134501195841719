import { MutationTree } from 'vuex'
import {
  EnrollmentProducts,
  ProductElectionRiderItem,
  ProductEnrollmentRiderItem,
  ProductEligibilitySpanItem,
} from './types'
import {
  MemberPlanElectionsInfoDto,
  MemberEnrollmentAndPlanCoverageInfoDto,
  DocumentDto,
  MemberRiderEnrollmentAndCoverageInfoDto,
  EmployeeBeneficiaryDetailDto,
  MemberEligibilitySpanDto,
} from '@/autogen-interfaces'
import { PageableComponentInterface } from '@/store/types'
import { formatDate, formatMoney } from '@/helpers'
import { PageableComponent } from '@/store/types'
import { loadableMutation } from '@/store/mutations'

export const mutations: MutationTree<EnrollmentProducts> = {
  setMemberPlanElections(state, MemberPlanElections: MemberPlanElectionsInfoDto[]) {
    state.memberPlanElections = [...MemberPlanElections]
  },
  setMemberEnrollmentAndPlanCoverageInfo(
    state,
    MemberEnrollmentAndPlanCoverageInfo: MemberEnrollmentAndPlanCoverageInfoDto[],
  ) {
    state.memberEnrollmentAndPlanCoverageInfo = [...MemberEnrollmentAndPlanCoverageInfo]
  },
  setDateFilter(state, dateFilter: Date | null) {
    state.dateFilter = dateFilter
  },
  setSelectedProductEnrollment(state, product: MemberEnrollmentAndPlanCoverageInfoDto) {
    state.selectedProductEnrollment.product = product
  },
  setSelectedProductElection(state, product: MemberPlanElectionsInfoDto) {
    state.selectedProductElection.product = product
  },
  setProductElectionDocuments(state, documents: DocumentDto[]) {
    state.selectedProductElection.productDocumentsTable.items = documents
  },
  setProductElectionDocumentsPagination(state, pagination: PageableComponentInterface) {
    state.selectedProductElection.productDocumentsTable.totalElements = pagination.totalElements
    state.selectedProductElection.productDocumentsTable.totalPages = pagination.totalPages
  },
  setProductElectionBeneficiaries(state, beneficiaries: EmployeeBeneficiaryDetailDto[]) {
    state.selectedProductElection.productBeneficiariesTable.items = beneficiaries
  },
  setProductElectionBeneficiariesPagination(state, pagination: PageableComponentInterface) {
    state.selectedProductElection.productBeneficiariesTable.totalElements = pagination.totalElements
    state.selectedProductElection.productBeneficiariesTable.totalPages = pagination.totalPages
  },
  setShowProductElectionDetails(state, value: boolean) {
    state.showProductElectionDetails = value
  },
  setProductEnrollmentDocuments(state, documents: DocumentDto[]) {
    state.selectedProductEnrollment.productDocumentsTable.items = documents
  },
  setProductEnrollmentDocumentsPagination(state, pagination: PageableComponentInterface) {
    state.selectedProductEnrollment.productDocumentsTable.totalElements = pagination.totalElements
    state.selectedProductEnrollment.productDocumentsTable.totalPages = pagination.totalPages
  },
  setProductEnrollmentBeneficiaries(state, beneficiaries: EmployeeBeneficiaryDetailDto[]) {
    state.selectedProductEnrollment.productBeneficiariesTable.items = beneficiaries
  },
  setProductEnrollmentBeneficiariesPagination(state, pagination: PageableComponentInterface) {
    state.selectedProductEnrollment.productBeneficiariesTable.totalElements =
      pagination.totalElements
    state.selectedProductEnrollment.productBeneficiariesTable.totalPages = pagination.totalPages
  },
  setShowProductEnrollmentDetails(state, value: boolean) {
    state.showProductEnrollmentDetails = value
  },
  setSelectedProductElectionRiderItems(state, dtoItems: MemberRiderEnrollmentAndCoverageInfoDto[]) {
    const enrollmentProductElectionRider: ProductElectionRiderItem[] = []
    dtoItems.forEach((dto) => {
      enrollmentProductElectionRider.push({
        coverage: dto.coverageVolume ? formatMoney(dto.coverageVolume) : 'N/A',
        coverageTier: dto.coverageTier ? formatMoney(dto.coverageTier) : 'N/A',
        effectiveDate: dto.effectiveDate ? formatDate(dto.effectiveDate, 'MM/DD/YYYY') : 'N/A',
        employeeRate: dto.employeePortion ? formatMoney(dto.employeePortion) : 'N/A',
        employerRate: dto.employerPortion ? formatMoney(dto.employerPortion) : 'N/A',
        riderDescription: dto.riderPlanDesc ? dto.riderPlanDesc : 'N/A',
        riderName: dto.riderPlanName ? dto.riderPlanName : 'N/A',
        totalRate: dto.totalRate ? formatMoney(dto.totalRate) : 'N/A',
      })
    })
    state.selectedProductElection.productRiderTable.items = enrollmentProductElectionRider
  },
  setSelectedProductElectionRiderPagination(state, pagination: PageableComponentInterface) {
    state.selectedProductElection.productRiderTable.totalElements = pagination.totalElements
    state.selectedProductElection.productRiderTable.totalPages = pagination.totalPages
  },
  setSelectedProductEnrollmentRiderItems(
    state,
    dtoItems: MemberRiderEnrollmentAndCoverageInfoDto[],
  ) {
    const enrollmentProductRiders: ProductEnrollmentRiderItem[] = []
    dtoItems.forEach((dto) => {
      enrollmentProductRiders.push({
        coverage: dto.coverageVolume ? formatMoney(dto.coverageVolume) : 'N/A',
        coverageTier: dto.coverageTier ? formatMoney(dto.coverageTier) : 'N/A',
        dateElected: dto.dateEnrolled ? formatDate(dto.dateEnrolled, 'MM/DD/YYYY') : 'N/A',
        dateTerminated: dto.dateTerminated ? formatDate(dto.dateTerminated, 'MM/DD/YYYY') : 'N/A',
        employeeRate: dto.employeePortion ? formatMoney(dto.employeePortion) : 'N/A',
        employerRate: dto.employerPortion ? formatMoney(dto.employerPortion) : 'N/A',
        riderDescription: dto.riderPlanDesc ? dto.riderPlanDesc : 'N/A',
        riderName: dto.riderPlanName ? dto.riderPlanName : 'N/A',
        totalRate: dto.totalRate ? formatMoney(dto.totalRate) : 'N/A',
      })
    })
    state.selectedProductEnrollment.productRiderTable.items = enrollmentProductRiders
  },
  setSelectedProductEnrollmentRiderPagination(state, pagination: PageableComponentInterface) {
    state.selectedProductEnrollment.productRiderTable.totalElements = pagination.totalElements
    state.selectedProductEnrollment.productRiderTable.totalPages = pagination.totalPages
  },
  setProductRiderEnrollmentTabPagination(state, pagination: PageableComponent) {
    Object.assign(state.selectedProductEnrollment.productRiderTable, pagination)
  },
  setProductElectionEligibilitySpan(state, dtoItems: MemberEligibilitySpanDto[]) {
    const productElectionEligibilitySpanItems: ProductEligibilitySpanItem[] = []
    dtoItems.forEach((dto) => {
      productElectionEligibilitySpanItems.push({
        coverageBeginDate: dto.beginDate ? formatDate(dto.beginDate, 'MM/DD/YYYY') : 'N/A',
        coverageEndDate: dto.endDate ? formatDate(dto.endDate, 'MM/DD/YYYY') : 'N/A',
        eligibilityStatus: dto.eligibilityStatusDescription
          ? dto.eligibilityStatusDescription
          : 'N/A',
        updatedDate: dto.updatedDate ? formatDate(dto.updatedDate, 'MM/DD/YYYY') : 'N/A',
      })
    })
    state.selectedProductElection.productEligibilitySpansTable.items =
      productElectionEligibilitySpanItems
  },
  setProductElectionEligibilitySpanPagination(state, pagination: PageableComponentInterface) {
    state.selectedProductElection.productEligibilitySpansTable.totalElements =
      pagination.totalElements
    state.selectedProductElection.productEligibilitySpansTable.totalPages = pagination.totalPages
  },
  ...loadableMutation,
}
