import { formatDate, formatMoney } from '@/helpers'
import {
  GroupPageInfoDto,
  GroupDto,
  GroupBillingHistoryDto,
  GroupPaymentHistoryDto,
} from '@/autogen-interfaces'
import {
  GroupInfo,
  GroupAddress,
  GroupBillingHistoryItem,
  PaymentHistoryTableItem,
} from '@/store/modules/groups/types'

export class GroupInfoEntityToDto {
  public static convert(groupPageInfoDto: GroupPageInfoDto): GroupInfo {
    return {
      id: this.nullCheck(groupPageInfoDto.group?.groupId),
      name: this.nullCheck(groupPageInfoDto.group?.groupName),
      status: this.nullCheck(groupPageInfoDto.group?.groupStatus) === 'active',
      policy: this.nullCheck(groupPageInfoDto.group?.externalGroupNumber),
      type: this.nullCheck(groupPageInfoDto.group?.groupTypeName),
      dba: this.nullCheck(groupPageInfoDto.group?.dba),
      altGroupId: this.nullCheck(groupPageInfoDto.group?.alternateGroupNumber),
      masterGrouper: this.nullCheck(groupPageInfoDto.group?.masterGrouperName),
      membershipType: this.nullCheck(groupPageInfoDto.group?.groupMembershipType),
      effectiveDate: groupPageInfoDto.group?.groupEffectiveDate
        ? formatDate(groupPageInfoDto.group?.groupEffectiveDate)
        : '',
      renewalDate: groupPageInfoDto.group?.groupRenewalDate
        ? formatDate(groupPageInfoDto.group?.groupRenewalDate)
        : '',
      terminationDate: groupPageInfoDto.group?.groupTermDate
        ? formatDate(groupPageInfoDto.group?.groupTermDate)
        : '',
      adminDate: groupPageInfoDto.group?.adminDate
        ? formatDate(groupPageInfoDto.group?.adminDate)
        : '',
      paidThruDate: groupPageInfoDto.group?.paidThruDate
        ? formatDate(groupPageInfoDto.group?.paidThruDate)
        : '',
      taxId: this.nullCheck(groupPageInfoDto.group?.groupTaxId),
      sicCode: this.nullCheck(groupPageInfoDto.group?.sicCode),
      situs: this.nullCheck(groupPageInfoDto.group?.domiciledStateName),
      federalTaxId: this.nullCheck(groupPageInfoDto.group?.federalTaxClassificationId),
      mailingAddress: this.getMailingAddress(groupPageInfoDto.group ? groupPageInfoDto.group : {}),
      physicalAddress: this.getPhysicalAddress(
        groupPageInfoDto.group ? groupPageInfoDto.group : {},
      ),
    }
  }

  public static convertBillingHistory(
    billingHistory: GroupBillingHistoryDto,
  ): GroupBillingHistoryItem {
    return {
      billDate: formatDate(this.nullCheck(billingHistory.billDate), 'MM/DD/YYYY'),
      billStatus: this.nullCheck(billingHistory.billStatus),
      billingRecordTotal: billingHistory.billingRecordTotal
        ? formatMoney(billingHistory.billingRecordTotal)
        : '',
      download: '',
      invoiceDesc: this.nullCheck(billingHistory.invoiceDesc),
      invoiceIdent: this.nullCheck(billingHistory.invoiceIdent),
      invoiceNumber: this.nullCheck(billingHistory.invoiceNumber),
      netDue: billingHistory.netDue ? formatMoney(billingHistory.netDue) : '',
      billRunDateTime: formatDate(this.nullCheck(billingHistory.billRunDateTime), 'MM/DD/YYYY'),
      billingRecordMiscTotal: billingHistory.billingRecordMiscTotal
        ? formatMoney(billingHistory.billingRecordMiscTotal)
        : '',
      grandTotal: billingHistory.grandTotal ? formatMoney(billingHistory.grandTotal) : '',
      cashAmountApplied: billingHistory.cashAmountApplied
        ? formatMoney(billingHistory.cashAmountApplied)
        : '',
      billAdjustments: billingHistory.billAdjustments
        ? formatMoney(billingHistory.billAdjustments)
        : '',
    }
  }

  public static convertPaymentHistory(
    paymentHistoryDto: GroupPaymentHistoryDto,
  ): PaymentHistoryTableItem {
    return {
      checkNumber: this.nullCheck(paymentHistoryDto.checkNo),
      deposit: this.nullCheck(paymentHistoryDto.depositNo),
      depositAmount: paymentHistoryDto.masInvoiceBalance
        ? formatMoney(paymentHistoryDto.masInvoiceBalance)
        : '',
      depositDate: formatDate(this.nullCheck(paymentHistoryDto.depositDate), 'MM/DD/YYYY'),
      posted: formatDate(this.nullCheck(paymentHistoryDto.billDate), 'MM/DD/YYYY'),
      status: this.nullCheck(paymentHistoryDto.paymentApplicationStatus),
    }
  }

  private static getPhysicalAddress({
    groupPhysicalAddr1 = '',
    groupPhysicalAddr2 = '',
    groupPhysicalAddrCity = '',
    groupPhysicalAddrState = '',
    groupPhysicalAddrZip = '-1',
  }: GroupDto): GroupAddress {
    return {
      address1: this.nullCheck(groupPhysicalAddr1),
      address2: this.nullCheck(groupPhysicalAddr2),
      city: this.nullCheck(groupPhysicalAddrCity),
      state: this.nullCheck(groupPhysicalAddrState),
      zip: this.nullCheck(groupPhysicalAddrZip),
      country: 'USA',
    }
  }

  private static getMailingAddress({
    groupMailingAddr1 = '',
    groupMailingAddr2 = '',
    groupMailingAddrCity = '',
    groupMailingAddrState = '',
    groupMailingAddrZip = '-1',
  }: GroupDto): GroupAddress {
    return {
      address1: this.nullCheck(groupMailingAddr1),
      address2: this.nullCheck(groupMailingAddr2),
      city: this.nullCheck(groupMailingAddrCity),
      state: this.nullCheck(groupMailingAddrState),
      zip: this.nullCheck(groupMailingAddrZip),
      country: 'USA',
    }
  }

  private static nullCheck(value: string | number | null | undefined): string {
    if (typeof value === 'number') {
      return value.toString()
    }
    return value || ''
  }
}
