import { ApiError } from './types'

// TODO DELETE ME after API integration
// export const withAsync = async (fn: any, context: any, ...args: any[]): Promise<any> => {
//   try {
//     const { data }: { data: any } = await fn.bind(context, ...args)()
//     return {
//       data: {
//         error: data.error,
//         codigo: data.codigo,
//         data: data.data, // workaround for mock server, remove when local env is ready, replace with data
//       },
//       error: null,
//     }
//   } catch (err: any) {
//     const error: ApiError = {
//       status: null,
//       message: null,
//     }
//     if (err?.response) {
//       error.status = err.response.status
//       error.message = err.response.data.message
//     } else if (err?.request) {
//       error.status = null
//       error.message = err.toJSON().message
//     } else {
//       error.status = null
//       error.message = err.message || 'Something went wrong'
//     }
//     return {
//       data: null,
//       error,
//     }
//   }
// }

export const withAsync = async (fn: any, context: any, ...args: any[]): Promise<any> => {
  try {
    const { data }: { data: any } = await fn.bind(context, ...args)()
    console.log(fn)
    console.log(context)
    console.log(args)
    console.log(data)
    return { data }
  } catch (err: any) {
    const error: ApiError = {
      status: null,
      message: null,
    }
    if (err?.response) {
      error.status = err.response.status
      error.message = err.response.data.message
    } else if (err?.request) {
      error.status = null
      error.message = err.toJSON().message
    } else {
      error.status = null
      error.message = err.message || 'Something went wrong'
    }
    return {
      data: null,
      error,
    }
  }
}
