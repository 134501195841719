import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { EnrollmentDocuments, EnrollmentNotes } from '@/store/modules/enrollmentDocuments/types'
import {
  enrollmentDocumentsMutation,
  enrollmentNotesMutation,
} from '@/store/modules/enrollmentDocuments/mutations'
import {
  enrollmentDocumentsActions,
  enrollmentNotesActions,
} from '@/store/modules/enrollmentDocuments/actions'
import {
  enrollmentDocumentsState,
  enrollmentNotesState,
} from '@/store/modules/enrollmentDocuments/state'

export const enrollmentDocuments: Module<EnrollmentDocuments, RootState> = {
  namespaced: true,
  mutations: enrollmentDocumentsMutation,
  actions: enrollmentDocumentsActions,
  state: enrollmentDocumentsState,
}

export const enrollmentNotes: Module<EnrollmentNotes, RootState> = {
  namespaced: true,
  state: enrollmentNotesState,
  mutations: enrollmentNotesMutation,
  actions: enrollmentNotesActions,
}
