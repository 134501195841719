import Vue, { PropType } from 'vue'
import TagStatus from '@/components/common/ui/TagStatus/Index.vue'

interface CardACHCreditCard {
  creditCard: string
  isActive: boolean
  addedBy: string
  date: string
}

export default Vue.extend({
  props: {
    cardInfo: {
      type: Object as PropType<CardACHCreditCard>,
      required: true,
    },
  },
  components: { TagStatus },
})
