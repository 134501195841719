import Vue, { PropType } from 'vue'
import TagStatus from '@/components/common/ui/TagStatus/Index.vue'
import AuditInterface from '@/components/common/interface/auditInterface'
import EditableTextField from '@/components/common/ui/EditableTextField/Index.vue'
import EditableSelectField from '@/components/common/ui/EditableSelectField/Index.vue'

export interface CardEnrollmentAdvancedInterface {
  isActive: boolean
  title: string
  fromDate: string
  toDate: string | null
  division: string
  employeeClass: string
  benefitGroup: string
  salary: string | null
  audit: AuditInterface
  statusNote: string
  role: string
}

export default Vue.extend({
  components: { TagStatus, EditableTextField, EditableSelectField },
  props: {
    cardInfo: {
      type: Object as PropType<CardEnrollmentAdvancedInterface>,
      required: true,
    },
  },
  data: () => ({
    edit: false,
  }),
  computed: {
    isEmployee(): boolean {
      return this.cardInfo.role === 'Employee'
    },
  },
})
