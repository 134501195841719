var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.coverage,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.bill_type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"body-md-heavy"},[_vm._v(_vm._s(item.bill_type))])]}},{key:"item.billing_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"body-md-heavy"},[_vm._v(_vm._s(item.billing_name))])]}},{key:"item.total_amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"body-md-heavy"},[_vm._v(_vm._s(item.total_amount))])]}},{key:"item.paid_up",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"body-md-heavy"},[_vm._v(_vm._s(item.paid_up))])]}}])}),_c('pagination',{attrs:{"length":_vm.pageCount,"total-items":_vm.coverage.length},on:{"items-per-page":function($event){_vm.itemsPerPage = $event}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }