import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { NavbarState } from './types'
import { state } from './state'
import { mutations } from './mutations'
import { actions } from './actions'

export const navbar: Module<NavbarState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}
