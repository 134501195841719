import Vue, { PropType } from 'vue'
import TextField from '@/components/common/ui/TextField/Index.vue'
import moment from 'moment'

const inputDateFormat = 'MM/DD/YYYY'
export interface FilterDateModel {
  beginDate: Date
  endDate: Date
}
export default Vue.extend({
  props: {
    label: {
      type: String,
      default: 'period',
    },
    value: {
      type: Object as PropType<FilterDateModel>,
      required: true,
    },
  },
  components: { TextField },
  data: () => ({
    menu: false,
    inputBeginDate: '',
    inputEndDate: '',
    selectValue: '',
  }),
  created() {
    if (this.value !== null) {
      if (this.value.beginDate === undefined || this.value.endDate === undefined) {
        const defaultValues = {
          beginDate: moment().startOf('month').toDate(),
          endDate: moment().endOf('month').toDate(),
        }
        this.$emit('input', defaultValues)
      }
    }
    this.$nextTick(() => {
      this.setInputValues()
      this.setSelectValue()
    })
  },
  methods: {
    setInputValues: function (): void {
      this.inputBeginDate = this.dateToString(this.value.beginDate, inputDateFormat)
      this.inputEndDate = this.dateToString(this.value.endDate, inputDateFormat)
    },
    setSelectValue: function (): void {
      const selectDateFormat = 'MMM YYYY'
      this.selectValue =
        this.dateToString(this.value.beginDate, selectDateFormat) +
        ' - ' +
        this.dateToString(this.value.endDate, selectDateFormat)
      this.closeMenuIfOpen()
    },
    setVModelValue: function (): void {
      const vModelValues = {
        beginDate: moment(this.inputBeginDate, inputDateFormat).toDate(),
        endDate: moment(this.inputEndDate, inputDateFormat).toDate(),
      }
      this.$emit('input', vModelValues)
    },
    setValues: function (): void {
      this.setVModelValue()
      this.$nextTick(() => {
        this.setInputValues()
        this.setSelectValue()
      })
    },
    dateToString: function (date: Date, format: string): string {
      return moment(date).format(format)
    },
    closeMenuIfOpen: function (): void {
      this.menu = this.menu ? !this.menu : this.menu
    },
  },
})
