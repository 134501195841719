// TODO replace with autogen-interfaces.ts or swagger-latest.json
export enum Role {
  Administrator = 'Administrator',
  Unknown = 'Unknown',
}

export interface UserAuth {
  id: string
  username: string
  token: string
  userRoles: Array<Role>
}

export interface UserAssociations {
  UserId: string
  GroupIds: Array<number>
  CompanyId: number
  Permissions: {
    Group: {
      groupInfo: boolean
      groupStructure: boolean
    }
    Enrollment: {
      enrollmentInfo: boolean
    }
  }
}

/*
 * DO NOT TREAT THIS AS THE VUEX STORE!!!
 *
 * This class is intended to be used to store data that gets loaded
 * before Vue is even mounted.
 *
 * For permissions, this is more flexible, from my experience, than
 * putting permissions on the store because occasionally I've found
 * non-UI things that need permissions, but can't reach the store)
 */
class AppDataClass {
  auth: UserAuth = {
    id: '',
    username: '',
    token: '',
    userRoles: [Role.Unknown],
  }

  associations: UserAssociations = {
    UserId: '',
    GroupIds: [],
    CompanyId: 0,
    Permissions: {
      Group: {
        groupInfo: false,
        groupStructure: false,
      },
      Enrollment: {
        enrollmentInfo: false,
      },
    },
  }

  whitelabel = {
    insightTest: window.location.host.split('.')[0] === 'insighttest',
    insightDemo: window.location.host.split('.')[0] === 'insightdemo',
  }

  getAuth() {
    return this.auth
  }

  setAuth(auth: UserAuth) {
    this.auth = auth
  }

  getAssociations() {
    return this.associations
  }

  setAssociations(associations: any) {
    const userAssociations = {
      UserId: associations.userId || this.auth.id,
      GroupIds: associations.groupIds || [],
      CompanyId: associations.companyId || 1000,
      Permissions: {
        ...associations.permissions,
        Group: {
          groupInfo: associations.permissions.Group?.groupInfo || true,
          groupStructure: associations.permissions.Group?.groupStructure || true,
        },
        Enrollment: {
          enrollmentInfo: associations.permissions.Enrollment?.enrollmentInfo || true,
        },
      },
    }
    this.associations = userAssociations
  }
}

export const AppData = new AppDataClass()
