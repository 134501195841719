import Vue, { PropType } from 'vue'
import TagStatus from '@/components/common/ui/TagStatus/Index.vue'

interface Header {
  city: string
  groupId: string
  groupPolicyId: string
  groupType: string
  active: boolean
}

export default Vue.extend({
  props: {
    header: {
      type: Object as PropType<Header>,
    },
  },
  components: {
    TagStatus,
  },
})
