import {
  BillingDivisionPage,
  BillingPage,
  BillingStatementsPaymentsPage,
  BillingSuspensePage,
  Group,
} from './types'
import { BillingDivisionsDto, BillingStatementGridDto } from '@/autogen-interfaces'

const billingStatements: BillingDivisionPage = {
  selectedItem: {} as BillingStatementGridDto,
  selectedItemDetail: {} as BillingStatementsPaymentsPage,
  items: [],
  totalElements: 0,
  totalPages: 0,
}
const billingSuspense: BillingSuspensePage = { items: [], totalElements: 0, totalPages: 0 }
export const state: BillingPage = {
  loading: false,
  billingGroups: [] as Group[],
  billingDivisions: [] as BillingDivisionsDto[],
  billingStatements,
  billingSuspense,
}
