import { ActionContext } from 'vuex'
import { RootState } from '@/store/types'
import { Enrollment, Actions } from './types'

import EnrollmentApi from '@/api/EnrollmentApi'
import DocumentsApi from '@/api/DocumentsApi'
import { withAsync } from '@/store/utils/withAsync'
import { getUniqueItems } from '@/helpers'

import { ApiError, ActionTreeAdaptor } from '@/store/utils/types'
import {
  MemberDto,
  DocumentDto,
  EnrollmentPageInfoDto,
  DependentEnrollmentInfoDto,
} from '@/autogen-interfaces'

export const actions: ActionTreeAdaptor<Actions, Enrollment, RootState> = {
  async getMember({ commit, state }: ActionContext<Enrollment, RootState>, { memberId }) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: MemberDto[] } = await withAsync(
      EnrollmentApi.fetchMember,
      EnrollmentApi,
      memberId,
    )
    if (error) {
      console.error(error)
    } else {
      commit('setCurrentMember', data)
      commit('setAvailableMembers')
      // Check if member has dependents
      if (state.currentMember.dependentEnrollmentDetails) {
        // Get unique members
        const uniqueDependents = getUniqueItems(
          state.currentMember.dependentEnrollmentDetails,
          'memberId',
        )

        uniqueDependents
          .filter(
            (dependent: DependentEnrollmentInfoDto) =>
              dependent.memberId !== state.currentMember.memberID,
          )
          .forEach(async (member: DependentEnrollmentInfoDto) => {
            const { error, data }: { error: ApiError; data: MemberDto } = await withAsync(
              EnrollmentApi.fetchMember,
              EnrollmentApi,
              member.memberId,
            )
            if (error) {
              console.error(error)
            } else {
              // this way, dependents will be rendered as their information is fetched
              commit('addAvailableMember', data)
            }
          })
      }
    }
    commit('setLoading', false)
  },

  async searchMember({ commit }: ActionContext<Enrollment, RootState>, { searchTerm, filter }) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: MemberDto[] } = await withAsync(
      EnrollmentApi.searchMember,
      EnrollmentApi,
      searchTerm,
      filter,
    )
    if (error) {
      console.error(error)
    } else {
      commit('setItems', data)
      commit('setPagination', {
        totalElements: data.length,
        totalPages: 1,
      })
    }
    commit('setLoading', false)
  },

  async searchMemberAdvanced(
    { commit }: ActionContext<Enrollment, RootState>,
    { name, lastname, dob, memberId, filters },
  ) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: MemberDto[] } = await withAsync(
      EnrollmentApi.searchMemberAdvanced,
      EnrollmentApi,
      name,
      lastname,
      dob,
      memberId,
      filters,
    )
    if (error) {
      console.error(error)
    } else {
      commit('setItems', data)
      commit('setPagination', {
        totalElements: data.length,
        totalPages: 1,
      })
    }
    commit('setLoading', false)
  },

  async getEmployeeDataById({ commit }: ActionContext<Enrollment, RootState>, { memberId }) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: EnrollmentPageInfoDto } = await withAsync(
      EnrollmentApi.fetchEmployeeDataById,
      EnrollmentApi,
      memberId,
    )
    if (error) {
      console.error(error)
    } else {
      commit('setCurrentMember', data)
    }
    commit('setLoading', false)
  },

  async getDocumentData({ commit }: ActionContext<Enrollment, RootState>, { documentId }) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: DocumentDto[] } = await withAsync(
      DocumentsApi.fetchDocumentData,
      DocumentsApi,
      documentId,
    )
    commit('setLoading', false)
    if (error) {
      console.error(error)
    } else {
      commit('setSelectedDocument', data)
    }
  },
}
