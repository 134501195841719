import Vue from 'vue'

export default Vue.extend({
  props: {
    active: {
      type: Boolean,
      default: true,
    },
  },
})
