import { MemberStatusDto } from '@/autogen-interfaces'
import { EnrollmentStatus } from '@/store/modules/enrollmentStatus/types'
import { formatDate, formatMoney } from '@/helpers'

export class MemberStatusToDto {
  public static convert(memberStatusToDto: MemberStatusDto): EnrollmentStatus {
    return {
      employeeStatusId: memberStatusToDto.employeeStatusId
        ? memberStatusToDto.employeeStatusId
        : -1,
      isActive: false,
      title: this.nullCheck(memberStatusToDto.workStatus),
      fromDate: memberStatusToDto.beginDate
        ? formatDate(memberStatusToDto.beginDate, 'MM/DD/YYYY')
        : '',
      toDate: memberStatusToDto.endDate
        ? formatDate(memberStatusToDto.endDate, 'MM/DD/YYYY')
        : null,
      division: this.nullCheck(memberStatusToDto.divisionName),
      employeeClass: this.nullCheck(memberStatusToDto.className),
      benefitGroup: this.nullCheck(memberStatusToDto.benefitGroupName),
      salary: memberStatusToDto.salary ? formatMoney(memberStatusToDto.salary) : null,
      audit: {
        addedBy: this.nullCheck(memberStatusToDto.addedBy),
        updatedBy: this.nullCheck(memberStatusToDto.lastUpdatedBy),
        addedDate: memberStatusToDto.dateRecordAdded
          ? formatDate(memberStatusToDto.dateRecordAdded)
          : 'MM/DD/YYYY',
        updatedDate: memberStatusToDto.lastUpdateDate
          ? formatDate(memberStatusToDto.lastUpdateDate)
          : 'MM/DD/YYYY',
      },
      statusNote: this.nullCheck(memberStatusToDto.statusNotes),
      role: '',
    }
  }

  private static nullCheck(value: string | null | undefined): string {
    return value || ''
  }
}
