import Vue from 'vue'
import { DataTableHeader } from 'vuetify'
import { PageableComponentInterface, RequestFilter } from '@/store/types'
import DataTable from '@/components/common/ui/DataTable/Index.vue'
import { ProductEligibilitySpanItem } from '@/store/modules/enrollmentProducts/types'
import Tag from '@/components/common/ui/Tag/Index.vue'

export default Vue.extend({
  components: { DataTable, Tag },
  async created() {
    await this.$store.dispatch('enrollmentProducts/getMemberEligibilitySpans', {
      filter: this.filters,
    })
  },
  data: () => ({
    dataTableHeader: [
      { text: 'Coverage Begin Date', value: 'coverageBeginDate', sortable: false, width: '25%' },
      { text: 'Coverage End Date', value: 'coverageEndDate', sortable: false, width: '25%' },
      { text: 'Eligibility Status', value: 'eligibilityStatus', sortable: false, width: '25%' },
      { text: 'Updated Date', value: 'updatedDate', sortable: false, width: '25%' },
    ] as DataTableHeader[],
    filters: {
      page: 1,
      pageSize: 10,
      skip: 0,
      take: 1,
      sort: [],
      filter: [],
    } as RequestFilter,
  }),
  computed: {
    items(): ProductEligibilitySpanItem[] {
      return this.$store.state.enrollmentProducts.selectedProductElection
        .productEligibilitySpansTable.items
    },
    loading(): boolean {
      return this.$store.state.enrollmentProducts.loading
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements:
          this.$store.state.enrollmentProducts.selectedProductElection.productEligibilitySpansTable
            .totalElements,
        totalPages:
          this.$store.state.enrollmentProducts.selectedProductElection.productEligibilitySpansTable
            .totalPages,
      }
    },
  },
  watch: {
    filters() {
      this.$store.dispatch('enrollmentProducts/getMemberEligibilitySpans', { filter: this.filters })
    },
  },
})
