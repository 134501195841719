import { RouteConfig } from 'vue-router'

import Billing from '@/views/billing/Billing.vue'
import BillingStatement from '@/views/billing/statement/BillingStatement.vue'
import BillingSuspense from '@/views/billing/suspense/BillingSuspense.vue'

const billingRoutes: Array<RouteConfig> = [
  {
    path: '/billing',
    name: 'Billing',
    component: Billing,
    redirect: '/billing/statement',
    children: [
      {
        path: '/billing/statement',
        name: 'Billing statement',
        component: BillingStatement,
      },
      {
        path: '/billing/suspense',
        name: 'Billing suspense',
        component: BillingSuspense,
      },
    ],
  },
]

export default billingRoutes
