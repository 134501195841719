import Vue from 'vue'
import mixin from '@/mixin'
import DataTable from '@/components/common/ui/DataTable/Index.vue'
import { base64ToBlob } from '@/helpers'
import { RequestFilter, PageableComponentInterface } from '@/store/types'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'
import { Document } from '@/store/modules/enrollmentDocuments/types'
import { AvailableMember } from '@/store/modules/enrollment/types'

export default Vue.extend({
  components: { DataTable },
  mixins: [mixin],
  data: () => ({
    filters: {
      filter: [],
      page: 1,
      pageSize: 10,
      skip: 0,
      sort: [],
      take: 1,
    } as RequestFilter,
  }),
  created() {
    this.$store.dispatch('enrollmentDocuments/fetchDocumentTypes')
  },
  computed: {
    dataTableHeader(): FilterableDataTableHeader[] {
      return [
        { text: 'Name', width: '15%', value: 'name', filterable: 'text' },
        {
          text: 'Type',
          width: '10%',
          value: 'type',
          filterable: 'dropdown',
          selectOptions: this.$store.state.enrollmentDocuments.documentTypes,
        },
        { text: 'Comment', width: '25%', value: 'comment', filterable: 'text' },
        {
          text: 'Effective Date',
          width: '20%',
          value: 'beginDate',
          filterable: 'text',
          inputRegex: '##/##/####',
          placeHolder: 'MM/DD/YYYY',
        },
        {
          text: 'End Date',
          width: '15%',
          value: 'endDate',
          filterable: 'text',
          inputRegex: '##/##/####',
          placeHolder: 'MM/DD/YYYY',
        },
        { text: 'Size', width: '10%', value: 'size' },
        { text: 'Download', width: '5%', value: 'download', align: 'center' },
      ]
    },
    documents(): Document[] {
      return this.$store.state.enrollmentDocuments.documents
    },
    loading(): boolean {
      return this.$store.state.enrollmentDocuments.loading
    },
    selectedMember(): number {
      return this.$store.state.enrollment.selectedMember || this.$route.params.memberId
    },
    memberType(): number {
      return (
        this.$store.state.enrollment.availableMembers.find(
          (m: AvailableMember) => m.memberID === this.selectedMember,
        )?.memberTypeID || 40 // on page refresh, fetch employee data, default to 40
      )
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.enrollmentDocuments.totalElements,
        totalPages: this.$store.state.enrollmentDocuments.totalPages,
      }
    },
  },
  methods: {
    async fetchDocuments() {
      await this.$store.dispatch('enrollmentDocuments/fetchDocuments', {
        memberId: this.selectedMember,
        memberTypeId: this.memberType,
        requestFilter: this.filters,
      })
    },
    async downloadDocument(doc: Document) {
      await this.$store.dispatch('enrollment/getDocumentData', { documentId: doc.documentId })
      if (this.$store.state.enrollment.selectedDocument) {
        base64ToBlob(
          this.$store.state.enrollment.selectedDocument.documentData,
          this.$store.state.enrollment.selectedDocument.mimeType,
          this.$store.state.enrollment.selectedDocument.documentName,
          this.$store.state.enrollment.selectedDocument.fileExtension,
        )
      }
    },
  },
  watch: {
    filters() {
      this.fetchDocuments()
    },
    selectedMember() {
      this.fetchDocuments()
    },
  },
})
