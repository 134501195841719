import Vue from 'vue'
import moment from 'moment'
import DataTable from '@/components/common/ui/DataTable/Index.vue'
import FilterDate from '@/components/common/ui/FilterDate/Index.vue'
import { GridFilter, Operators, PageableComponentInterface, RequestFilter } from '@/store/types'
import { Document } from '@/store/modules/groupDocuments/types'
import { FilterDateModel } from '@/components/common/ui/FilterDate/filterDate'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'
import { base64ToBlob } from '@/helpers'

export default Vue.extend({
  components: { DataTable, FilterDate },
  data: () => ({
    filters: {} as RequestFilter,
  }),
  created() {
    if (!this.$store.state.enrollmentDocuments.documentTypes.length) {
      this.$store.dispatch('enrollmentDocuments/fetchDocumentTypes')
    }
  },
  methods: {
    fetchGroupDocuments() {
      this.$store.dispatch('groupDocuments/fetchGroupContacts', this.groupDocumentsFilter)
    },
    async downloadDocument(doc: Document) {
      await this.$store.dispatch('enrollment/getDocumentData', { documentId: doc.documentId })
      if (this.$store.state.enrollment.selectedDocument) {
        base64ToBlob(
          this.$store.state.enrollment.selectedDocument.documentData,
          this.$store.state.enrollment.selectedDocument.mimeType,
          doc.name || 'document',
          this.$store.state.enrollment.selectedDocument.fileExtension,
        )
      }
    },
  },
  computed: {
    dataTableHeader(): FilterableDataTableHeader[] {
      return [
        { text: 'Name', value: 'name', filterable: 'text', width: '20%', align: 'start' },
        {
          text: 'Type',
          value: 'type',
          align: 'start',
          filterable: 'dropdown',
          selectOptions: this.$store.state.enrollmentDocuments.documentTypes,
          width: '15%',
        },
        { text: 'Comment', value: 'comment', align: 'start', filterable: 'text', width: '30%' },
        {
          text: 'Date Added',
          value: 'dateAdded',
          align: 'start',
          filterable: 'text',
          width: '15%',
          inputRegex: '##/##/####',
          placeHolder: 'MM/DD/YYYY',
        },
        { text: 'Size', value: 'size', align: 'center', filterable: 'text', width: '10%' },
        {
          text: 'Download',
          value: 'downloadUrl',
          align: 'center',
          filterable: 'text',
          width: '10%',
        },
      ]
    },
    filterDates: {
      get(): FilterDateModel {
        return this.$store.state.groupDocuments.filterDates
      },
      set(filterDates: FilterDateModel): void {
        this.$store.commit('groupDocuments/setFilterDates', filterDates)
      },
    },
    groupDocuments(): Document[] {
      return this.$store.state.groupDocuments.documents
    },
    loading(): boolean {
      return this.$store.state.groupDocuments.loading
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.groupContacts.totalElements,
        totalPages: this.$store.state.groupContacts.totalPages,
      }
    },
    groupDocumentsFilter(): RequestFilter {
      const gridFilters: GridFilter[] = [
        {
          operator: Operators.GREATER_OR_EQUAL_THAN,
          field: 'insertDate',
          value: moment(this.filterDates.beginDate as Date).format(),
          logic: 'AND',
          filters: [],
        },
        {
          operator: Operators.LOWER_OR_EQUAL_THAN,
          field: 'insertDate',
          value: moment(this.filterDates.endDate as Date).format(),
          logic: 'AND',
          filters: [],
        },
      ]
      return { ...this.filters, filter: gridFilters.concat(this.filters.filter) }
    },
  },
  watch: {
    groupDocumentsFilter() {
      this.fetchGroupDocuments()
    },
  },
})
