import Vue from 'vue'

export default Vue.extend({
  props: {
    fieldsName: {
      type: Array,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showMenu: true,
    items: [
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me 2' },
    ],
  }),
})
