import Vue, { PropType } from 'vue'
import BarChartCensus from '@/components/common/ui/BarChartCensus/Index.vue'

export default Vue.extend({
  name: 'Census',
  props: {
    colorSetup: {
      type: String,
      default: 'opt1',
    },
    labels: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    data: {
      type: Array as PropType<Array<number>>,
      default: () => [],
    },
  },
  components: { BarChartCensus },
  computed: {
    totalData(): number {
      return this.data.reduce((a, b) => a + b, 0)
    },
  },
})
