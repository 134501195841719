import { AlertsState } from './types'

export const state: AlertsState = {
  polling: null,
  newAlertAvailable: true,
  firstTimeMessage: {
    id: 'firstTimeAlertId',
    type: 'new-user-alert',
    title: '',
    subtitle: '',
    message: 'You have a new alert!',
    action: 'view',
    createdAt: new Date(),
    read: false,
  },
  alerts: [
    {
      id: 'articleId',
      type: 'article-alert',
      title: 'Article title',
      subtitle: '',
      message:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temp or incidi dunt ut labore et',
      action: '',
      createdAt: new Date(),
      read: false,
    },
    {
      id: 'articleId',
      type: 'announcement-alert',
      title: 'Announcement title',
      subtitle: '',
      message:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temp or incidi dunt ut labore et',
      action: '',
      createdAt: new Date(),
      read: true,
    },
    {
      id: 'supportId',
      type: 'support-alert',
      title: 'Support response',
      subtitle: 'Ticket #99920: Issue with 1/1 Billing Statement ',
      message:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temp or incidi dunt ut labore et',
      action: 'needs response',
      createdAt: new Date(),
      read: false,
    },
  ],
}
