import { MutationTree } from 'vuex'
import { Groups, GroupInfo, GroupBillingHistoryItem, PaymentHistoryTableItem } from './types'
import { DocumentDataDto, GroupPaymentHistoryDto } from '@/autogen-interfaces'
import { PageableComponentInterface } from '@/store/types'

export const mutations: MutationTree<Groups> = {
  setLoading(state, loading: boolean) {
    state.loading = loading
  },
  setInfo(state, info: GroupInfo) {
    state.info = { ...info }
  },
  setBillingHistory(state, billingHistory: GroupBillingHistoryItem[]) {
    state.billingHistory.items = [...billingHistory]
  },
  setBillingHistoryPagination(state, pagination: PageableComponentInterface) {
    state.billingHistory.totalElements = pagination.totalElements
    state.billingHistory.totalPages = pagination.totalPages
  },
  setBillingHistoryDateFilter(state, dateFilter: Date) {
    state.billingHistory.dateFilter = dateFilter
  },
  setPaymentHistory(state, paymentHistory: GroupPaymentHistoryDto[]) {
    state.paymentHistory.items = [...paymentHistory]
  },
  setPaymentHistoryPagination(state, pagination: PageableComponentInterface) {
    state.paymentHistory.totalElements = pagination.totalElements
    state.paymentHistory.totalPages = pagination.totalPages
  },
  setSelectedBillingHistory(state, selectedItem: GroupBillingHistoryItem) {
    state.billingHistory.selectedItem.groupBillingHistory = { ...selectedItem }
  },
  setDownloadInvoiceDate(state, documentData: DocumentDataDto) {
    state.billingHistory.downloadInvoice = { ...documentData }
  },
  setSelectedBillingHistoryPaymentHistory(
    state,
    {
      paymentsHistory,
      pageableComponent,
    }: {
      paymentsHistory: PaymentHistoryTableItem[]
      pageableComponent: PageableComponentInterface
    },
  ) {
    state.billingHistory.selectedItem.paymentItems = [...paymentsHistory]
    state.billingHistory.selectedItem.totalPages = pageableComponent.totalPages
    state.billingHistory.selectedItem.totalElements = pageableComponent.totalElements
  },
}
