import { EnrollmentStatusPage } from '@/store/modules/enrollmentStatus/types'

export const state: EnrollmentStatusPage = {
  dateFilter: null,
  loading: false,
  currentStatus: {
    employeeStatusId: -1,
    isActive: true,
    title: '',
    fromDate: '',
    toDate: '',
    division: '',
    employeeClass: '',
    benefitGroup: '',
    salary: '',
    audit: {
      addedBy: '',
      updatedBy: '',
      addedDate: '',
      updatedDate: '',
    },
    statusNote: '',
    role: '',
  },
  statusHistory: [],
}
