import {
  GroupPlanOptionsDto,
  GroupProductsDto,
  GroupProductRiderPlanOptionsDto,
} from '@/autogen-interfaces'
import {
  GroupProductDetail,
  GroupProductRider,
  Product,
  PlanOptionDetails,
} from '@/store/modules/groupProducts/types'
import { formatDate, formatMoney } from '@/helpers'

export default class ProductEntityToDto {
  public convert(groupProductsDto: GroupProductsDto): Product {
    return {
      beginDate: groupProductsDto.gpBeginDate
        ? formatDate(groupProductsDto.gpBeginDate, 'MM/DD/YYYY')
        : '',
      carrier: ProductEntityToDto.nullCheck(groupProductsDto.carrier),
      coverageTier: ProductEntityToDto.nullCheck(groupProductsDto.coverageTier),
      description: ProductEntityToDto.nullCheck(groupProductsDto.carrierProductDescription),
      endDate: groupProductsDto.gpEndDate
        ? formatDate(groupProductsDto.gpEndDate, 'MM/DD/YYYY')
        : '',
      product: ProductEntityToDto.nullCheck(groupProductsDto.carrierProductName),
      productId: groupProductsDto.groupProductId ? groupProductsDto.groupProductId : 0,
      billAdminDate: groupProductsDto.billAdminDate
        ? formatDate(groupProductsDto.billAdminDate, 'MM/DD/YYYY')
        : '',
      eligAdmin: groupProductsDto.eligAdmin ? groupProductsDto.eligAdmin : false,
      eligFeed: groupProductsDto.eligFeed ? groupProductsDto.eligFeed : false,
      billingAdmin: groupProductsDto.billingAdmin ? groupProductsDto.billingAdmin : false,
      addedBy: ProductEntityToDto.nullCheck(null),
      code: ProductEntityToDto.nullCheck(groupProductsDto.carrierProductCode),
      planDescription: ProductEntityToDto.nullCheck(groupProductsDto.groupProductDescription),
      planOptions: ProductEntityToDto.convertGroupPlanOptions(groupProductsDto),
    }
  }

  private static convertGroupProductRiders(
    groupProductRidersDto: GroupProductRiderPlanOptionsDto[],
  ): GroupProductRider[] {
    const groupProductRider: GroupProductRider[] = []
    for (const itemDto of groupProductRidersDto) {
      groupProductRider.push({
        groupProductRiderId: itemDto.groupProductRiderId ? itemDto.groupProductRiderId : -1,
        rider: ProductEntityToDto.nullCheck(itemDto.riderName),
        rateTable: ProductEntityToDto.nullCheck(itemDto.rateTable),
        planName: ProductEntityToDto.nullCheck(itemDto.riderPlanName),
        planOptionsBegin: itemDto.begDate ? formatDate(itemDto.begDate, 'MM/DD/YYYY') : '',
        planOptionsTermination: itemDto.endDate ? formatDate(itemDto.endDate, 'MM/DD/YYYY') : '',
        childCoverage: itemDto.childCoverage ? 'Yes' : 'No',
        childPercentage: itemDto.childPercent ? `${itemDto.childPercent}%` : '0%',
        description2: ProductEntityToDto.nullCheck(itemDto.riderPlanDesc),
        spouseCoverage: itemDto.spouseCoverage ? 'Yes' : 'No',
        spousePercentage: itemDto.spousePercent ? `${itemDto.spousePercent}%` : '0%',
        optional: itemDto.optional ? 'Yes' : 'No',
        description: ProductEntityToDto.nullCheck(itemDto.riderDescription),
        riderCode: ProductEntityToDto.nullCheck(itemDto.riderCode),
        riderBeg: itemDto.riderPlanOptionBegDate
          ? formatDate(itemDto.riderPlanOptionBegDate, 'MM/DD/YYYY')
          : '',
        riderTerm: itemDto.riderPlanOptionEndDate
          ? formatDate(itemDto.riderPlanOptionEndDate, 'MM/DD/YYYY')
          : '',
      })
    }
    return groupProductRider
  }

  private static convertGroupPlanOptions(groupProductsDto: GroupProductsDto): PlanOptionDetails[] {
    const groupPlanOptions: GroupPlanOptionsDto[] = groupProductsDto.planOptions
      ? groupProductsDto.planOptions
      : []
    const planOptionDetails = [] as PlanOptionDetails[]
    for (const itemDto of groupPlanOptions) {
      planOptionDetails.push({
        ...itemDto,
        begDate: itemDto.begDate ? formatDate(itemDto.begDate, 'MM/DD/YYYY') : '',
        endDate: itemDto.endDate ? formatDate(itemDto.endDate, 'MM/DD/YYYY') : '',
        billAdminDate: groupProductsDto.billAdminDate
          ? formatDate(groupProductsDto.billAdminDate, 'MM/DD/YYYY')
          : '',
        eligAdmin: groupProductsDto.eligAdmin ? groupProductsDto.eligAdmin : false,
        eligFeed: groupProductsDto.eligFeed ? groupProductsDto.eligFeed : false,
        billingAdmin: groupProductsDto.billingAdmin ? groupProductsDto.billingAdmin : false,
        addedBy: ProductEntityToDto.nullCheck(null),
      })
    }
    return planOptionDetails
  }

  public convertGroupProductDetail(
    groupPlanOptionsDto: GroupPlanOptionsDto[],
  ): GroupProductDetail[] {
    const groupPlanOtions: GroupProductDetail[] = []
    for (const itemDto of groupPlanOptionsDto) {
      groupPlanOtions.push({
        groupProductPlanOptionId: itemDto.groupProductPlanOptionId
          ? itemDto.groupProductPlanOptionId
          : -1,
        childCoverage: itemDto.childCoverage ? 'Yes' : 'No',
        childPercentage: itemDto.childPercent ? `${itemDto.childPercent}%` : '0%',
        defaultIsSelected: itemDto.defaultSelect ? 'Yes' : 'No',
        depToAge: itemDto.dependentsToAge ? itemDto.dependentsToAge : '0',
        domesticPartner: itemDto.domesticPartnerCoverage ? 'Yes' : 'No',
        erContribution: itemDto.employerContribution ? 'Yes' : 'No',
        isVolume: itemDto.isVolume ? 'Yes' : 'No',
        maxVolume: itemDto.maxVolume ? formatMoney(itemDto.maxVolume) : formatMoney(0),
        minVolume: itemDto.minVolume ? formatMoney(itemDto.minVolume) : formatMoney(0),
        spouseCoverage: itemDto.spouseCoverage ? 'Yes' : 'No',
        spousePercentage: itemDto.spousePercent ? `${itemDto.spousePercent}%` : '0%',
        volumeBasis: itemDto.volumeBasis ? `${itemDto.volumeBasis}` : 'N/A',
        volumeBasisPercentage: itemDto.volumeBasisPercent
          ? `${itemDto.volumeBasisPercent}%`
          : '0.00%',
        volumeInc: itemDto.volumeIncrement ? formatMoney(itemDto.volumeIncrement) : formatMoney(0),
        riders: ProductEntityToDto.convertGroupProductRiders(
          itemDto.groupProductRiderPlanOptions || [],
        ),
      })
    }
    return groupPlanOtions
  }

  private static nullCheck(value: string | null | undefined): string {
    return value || ''
  }
}
