import Vue from 'vue'
import Drawer from '@/components/common/ui/Drawer/Index.vue'
import DataTable from '@/components/common/ui/DataTable/Index.vue'
import { Contact } from '@/store/modules/groupContactsVendors/types'
import { PageableComponentInterface, RequestFilter } from '@/store/types'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'

export default Vue.extend({
  components: { DataTable, Drawer },
  data: () => ({
    showDescription: false,
    filters: {} as RequestFilter,
    dataTableHeader: [
      { text: 'Name', value: 'name', sortable: false, align: 'start' },
      { text: 'Contact Type', value: 'contactType', align: 'start', sortable: false },
      { text: 'Address', value: 'address', align: 'start', sortable: false },
      { text: 'Email', value: 'email', align: 'start', sortable: false },
      { text: 'Phone', value: 'phone', align: 'start', sortable: false },
      { text: 'Fax', value: 'fax', align: 'start', sortable: false },
      { text: 'Expired Date', value: 'expiredDate', align: 'start', sortable: false },
    ] as FilterableDataTableHeader[],
  }),
  methods: {
    fetchGroupContacts() {
      this.$store.dispatch('groupContacts/fetchGroupContacts', this.filters)
    },
  },
  computed: {
    groupContacts(): Contact[] {
      return this.$store.state.groupContacts.contacts
    },
    loading(): boolean {
      return this.$store.state.groupContacts.loading
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.groupContacts.totalElements,
        totalPages: this.$store.state.groupContacts.totalPages,
      }
    },
  },
  watch: {
    filters() {
      this.fetchGroupContacts()
    },
  },
})
