import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    noColumns: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: true,
    fullscreenCss: 'fullscreen',
  }),
})
