import Vue from 'vue'

export default Vue.extend({
  name: 'CardReport',
  props: {
    pdf: {
      type: Boolean,
      default: false,
    },
    excel: {
      type: Boolean,
      default: false,
    },
    reportName: {
      type: String,
      default: 'Report Name',
    },
    reportDate: {
      type: String,
      default: 'JUN 27, 2021 4:34PM',
    },
  },
})
