import colors from '@/plugins/defaultTheme'

const first = colors.primary
const second = colors['lighten-1']
const third = colors['lighten-3']

export default {
  opt1: {
    backgroundColor: [`${first}`, `${second}`, `${third}`],
    borderColor: [`${first}`, `${second}`, `${third}`],
    percentageTextColor: ['white', 'white', 'black'],
    borderWidth: {
      top: 2,
      bottom: 2,
      right: 2,
      left: 2,
    },
  },
  opt2: {
    backgroundColor: ['rgba(71, 85, 105, 1)', 'rgba(148, 163, 184, 1)'],
    borderColor: ['rgba(71, 85, 105, 1)', 'rgba(148, 163, 184, 1)'],
    percentageTextColor: ['white', 'white'],
    borderWidth: 0,
  },
}
