import { Module } from 'vuex'
import { RootState } from '@/store/types'
import state from './state'
import mutations from './mutations'
import actions from './actions'
import { GroupUserSelection } from '@/store/modules/groupUserSelection/type'

export const groupUserSelection: Module<GroupUserSelection, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}
