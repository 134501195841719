var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"col-12"},[_c('data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"pagination":_vm.pagination},on:{"request-filter":function($event){_vm.filters = $event}},scopedSlots:_vm._u([{key:"item.documentName",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.documentName))])]}},{key:"item.beginDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.beginDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.endDate))+" ")]}},{key:"item.insertYear",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.insertYear))+" ")]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('download-document', item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-tray-arrow-down")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }