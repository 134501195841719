export interface RootState {
  theme: string
  observer: IntersectionObserver | null
  selectedUserGroupId: string | null
}

// Value	     || SQL Equivalent
// eq         ||    	=
// neq        ||    	<>
// gte        ||    	>=
// gt         ||    	>
// lte        ||    	<=
// lt         ||    	<
// contains	 ||    	LIKE %[value]%

export enum Operators {
  EQUAL = 'eq',
  NOT_EQUAL = 'neq',
  GREATER_THAN = 'gt',
  GREATER_OR_EQUAL_THAN = 'gte',
  LOWER_OR_EQUAL_THAN = 'lte',
  LOWER_THAN = 'lt',
  CONTAINS = 'contains',
}

export enum Dir {
  ASC = 'asc',
  DESC = 'desc',
}

export interface PaginationFilter {
  page: number
  pageSize: number
  skip: 0
  take: number
}

// a.k.a. GridHelperQuery
export interface RequestFilter extends PaginationFilter {
  sort: GridSort[]
  filter: GridFilter[]
}

export interface GridFilter {
  operator: Operators
  field: string
  value: string
  logic: string
  filters: GridFilter[]
}

export interface GridSort {
  field: string
  dir: string
}

export interface TableHeaderItem {
  text: string
  align?: string
  sortable?: boolean
  value: string
  class?: string
}

export interface PageableComponent {
  page: number
  pageCount: number
  itemsPerPage: number
}

export interface PageableComponentInterface {
  totalElements: number
  totalPages: number
}

export interface LoadableComponent {
  loading: boolean
}

export interface GridFilterQuery {
  Operator: Operators
  Field: string
  Value: string
  Logic: string
  Filters: GridFilterQuery[]
}
export interface GridHelperQuery {
  Page: number
  PageSize: number
  Skip: 0
  Take: number
  Sort: GridSort[]
  Filter: GridFilterQuery | null
}
