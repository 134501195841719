import { Api } from '@/api/Api'
import { RequestFilter } from '@/store/types'
import { AppData } from '@/AppData'

class EnrollmentApi extends Api {
  /**
   * Fetch a list of enrolled members
   *
   * @returns {Promise} axios promise
   * @param {RequestFilter} filters
   */
  fetchMember(memberId: number) {
    const ApplicationUser = AppData.associations.UserId
    return this.get(`/Enrollment/Get_AllMembersDetail`, { ApplicationUser, MemberID: memberId })
  }

  fetchEmployeeDataById(memberId: number) {
    const ApplicationUser = AppData.associations.UserId
    return this.get('/Enrollment/Get_EnrollmentRouteDetails', {
      EmployeeID: memberId,
      ApplicationUser,
    })
  }

  fetchMemberById(memberId: number) {
    const ApplicationUser = AppData.associations.UserId
    const MemberTypeID = -1
    return this.get('/Enrollment/Get_MemberDetail', {
      MemberID: memberId,
      ApplicationUser,
      MemberTypeID,
    })
  }

  searchMember(searchTerm: string, filters: RequestFilter) {
    const GroupID = AppData.associations.GroupIds[0]
    const ApplicationUser = AppData.associations.UserId
    return this.get('/Enrollment/Get_EmployeeSearch', {
      Input: searchTerm,
      ApplicationUser,
      GridHelper: filters,
      GroupID,
      GroupIDs: AppData.associations.GroupIds,
    })
  }

  searchMemberAdvanced(
    name: string | null,
    lastname: string | null,
    dob: Date | null,
    memberId: string | null,
    filters: RequestFilter,
  ) {
    const GroupID = AppData.associations.GroupIds[0]
    const ApplicationUser = AppData.associations.UserId
    return this.get('/Enrollment/Get_EmployeeAdvancedSearch', {
      ApplicationUser,
      GroupID,
      FirstName: name,
      LastName: lastname,
      DateOfBirth: dob ? dob.toLocaleDateString() : '',
      MemberID: memberId,
      GridHelper: filters,
      GroupName: '',
      MiddleName: '',
      DependentID: '',
      ExternalgroupNumber: '',
      AlternateGroupNumber: '',
      NoteID: '',
    })
  }

  /**
   * Fetch MemberPlanElectionsDetailsDto
   *
   * @returns {Promise} axios promise
   * @param {RequestFilter} filters
   */
  fetchMemberPlanElectionsDetails(MemberID: number, MemberTypeID: number, AsOfDate: Date | null) {
    const ApplicationUser = AppData.associations.UserId
    return this.get(`/Enrollment/Get_MemberPlanElectionsDetails`, {
      MemberID,
      MemberTypeID: MemberTypeID ? MemberTypeID : 40,
      CompanyID: AppData.associations.CompanyId,
      ApplicationUser,
      AsOfDate: AsOfDate ? AsOfDate.toLocaleDateString() : '',
    })
  }

  /**
   * Fetch MemberEnrollmentAndPlanCoverageInfoDto
   *
   * @returns {Promise} axios promise
   * @param {number} MemberID
   * @param {Date | null} AsOfDate
   */
  fetchMemberEnrollmentAndPlanCoverageInfo(
    MemberID: number,
    MemberTypeID: number,
    AsOfDate: Date | null,
  ) {
    const ApplicationUser = AppData.associations.UserId
    return this.get(`/Enrollment/Get_MemberEnrollmentAndPlanCoverageInfo`, {
      MemberID,
      MemberTypeID: MemberTypeID ? MemberTypeID : 40,
      ApplicationUser,
      AsOfDate: AsOfDate ? AsOfDate.toLocaleDateString() : '',
    })
  }

  /**
   * Fetch EmployeeBillingDto
   *
   * @returns {Promise} axios promise
   * @param {number} memberId
   * @param {RequestFilter} filter
   */
  fetchBillingHistory(memberId: number, memberType: number, filters: RequestFilter) {
    const ApplicationUser = AppData.associations.UserId
    return this.get(`/Enrollment/Get_MemberBilling`, {
      MemberID: memberId,
      MemberTypeId: memberType ? memberType : 40, // on page refresh, member type is undefined
      ApplicationUser,
      GridHelper: filters,
    })
  }

  /**
   * Fetch Member status
   * @param requestData
   */
  fetchMemberStatus(memberId: number, memberType: number, filters: RequestFilter | null) {
    const ApplicationUser = AppData.associations.UserId
    interface Payload {
      MemberID: number
      MemberTypeId: number
      ApplicationUser: string
      GridHelper?: RequestFilter
    }
    const payload = {
      MemberID: memberId,
      MemberTypeId: memberType ? memberType : 40, // on page refresh, member type is undefined
      ApplicationUser,
    } as Payload
    if (filters) {
      // filters is null for current status
      payload.GridHelper = filters
    }
    return this.get('/Enrollment/Get_MemberStatus', payload)
  }

  /**
   * Fetch Employee Beneficiaries
   * @param requestData
   */
  fetchEmployeeBeneficiaries(memberId: number, filters: RequestFilter) {
    return this.get('/Enrollment/Get_EmployeeBeneficiaries', {
      MemberID: memberId,
      ApplicationUser: AppData.associations.UserId,
      GridHelper: filters,
    })
  }

  fetchMemberBillingDetails(memberId: number, billId: number, filter: RequestFilter) {
    const ApplicationUser = AppData.associations.UserId
    return this.get('/Enrollment/Get_MemberBillingDetails', {
      MemberID: memberId,
      BillBatchId: billId,
      applicationUser: ApplicationUser,
      GridHelper: filter,
    })
  }

  /**
   * Fetch member fringe periods
   * @param {MemberId} MemberId
   * @returns  {Promise} axios promise
   */
  fetchMemberFringePeriods(MemberId: number, filter: RequestFilter) {
    const ApplicationUser = AppData.associations.UserId
    return this.get('/Enrollment/Get_FringeCoveragePeriods', {
      EmployeeID: MemberId,
      ApplicationUser,
      GridHelper: filter,
    })
  }

  /**
   * Fetch member fringe periods
   * @param {MemberId}
   * @param {invoiceId}
   * @returns  {Promise} axios promise
   */
  fetchMemberFringePeriodDetails(
    MemberId: number,
    invoiceId: number,
    beginDate: string,
    endDate: string,
    filter: RequestFilter,
  ) {
    const ApplicationUser = AppData.associations.UserId
    return this.get('/Enrollment/Get_FringeCoveragePeriodDetails', {
      EmployeeId: MemberId,
      FringeInvoiceNumber: invoiceId,
      CoverageBeginDate: beginDate,
      CoverageEndDate: endDate,
      ApplicationUser,
      GridHelper: filter,
    })
  }

  /**
   * Fetch MemberProductElection
   *
   * @returns {Promise} axios promise
   * @param MemberID
   * @param PlanElectionEnrollmentID
   */
  fetchMemberProductElection(
    MemberID: number,
    PlanElectionEnrollmentID: number,
    filter: RequestFilter,
  ) {
    return this.get('/Enrollment/Get_MemberRiderEnrollmentAndCoverageInfo', {
      MemberID,
      PlanElectionEnrollmentID,
      CompanyID: AppData.associations.CompanyId,
      AsOfDate: '',
      ApplicationUser: AppData.associations.UserId,
      GridHelper: filter,
    })
  }

  /**
   * Fetch MemberProductEnrollment
   *
   * @returns {Promise} axios promise
   * @param MemberID
   */
  fetchMemberProductEnrollment(MemberID: number, filter: RequestFilter) {
    return this.get('/Enrollment/Get_MemberRiderEnrollmentAndCoverageInfo', {
      MemberID,
      GridHelper: filter,
    })
  }

  /**
   * Fetch MemberEligibilitySpans
   *
   * @returns {Promise} axios promise
   * @param MemberId
   */
  fetchMemberEligibilitySpans(
    MemberId: number,
    planElectionEnrollmentID: number,
    filter: RequestFilter,
  ) {
    return this.get('/Enrollment/Get_MemberEligibilitySpans', {
      MemberId,
      PlanElectionEnrollmentID: planElectionEnrollmentID,
      CompanyID: AppData.associations.CompanyId,
      ApplicationUser: AppData.associations.UserId,
      GridHelper: filter,
    })
  }
}

const enrollmentApi = new EnrollmentApi()

export default enrollmentApi
