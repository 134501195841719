import { AppData } from '@/AppData'

const THEME = {
  primary: '#4F4D83',
  secondary: '#F6F7FD',
  accent: '#C6C5F0',
  error: '#FD5F5F',
  btnSuccess: '#bbf6e3',
  anchor: '#4F4D83',
  success: '#BAF7D0',
  'lighten-1': '#7472AF',
  'gray-details': '#E2E8F0',
  'cadete-grey': '#94A3B8',
  'lighten-3': '#ECEFFF', // lightest-blue
}

if (AppData.whitelabel.insightDemo) {
  THEME.primary = '#FF671F'
}

export default THEME
