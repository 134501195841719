import Vue from 'vue'
import ColorCard from './ColorCard.vue'

export default Vue.extend({
  components: {
    ColorCard,
  },
  data() {
    return {
      primaryColors: [
        {
          name: 'Yale Blue',
          hex: '#094C9A',
          className: 'soluta primary',
        },
        {
          name: 'Cyan',
          hex: '#1DAFED',
          className: 'soluta lighten-1',
        },
      ],
      secondaryColorsBlue: [
        {
          name: 'Beau Blue',
          hex: '#C9E6FF',
          text: 'BLUE 1',
          className: 'soluta lighten-2',
        },
        {
          name: 'Alice Blue',
          hex: '#E9F5FF',
          text: 'BLUE 2',
          className: 'soluta lighten-3',
        },
      ],
      secondaryColors: [
        {
          name: 'Black',
          hex: '#000000',
          className: 'black',
        },
        {
          name: 'Text Grey',
          hex: '#4A4A4A',
          className: 'soluta text-grey',
        },
        {
          name: 'Cadet Grey',
          hex: '#94A3B8',
          className: 'soluta cadete-grey',
        },
        {
          name: 'Ghost White',
          hex: '#F6F7FD',
          className: 'soluta ghost-white',
        },
      ],
      tertiaryColors: [
        {
          name: 'Emerald - Success',
          hex: '#20D4A9',
          className: 'soluta emerald',
        },
        {
          name: 'Spearmint',
          hex: '#BAF7D0',
          className: 'soluta spearmint',
        },
        {
          name: 'Dark Coral - Error',
          hex: '#FD5F5F',
          className: 'soluta dark-coral',
        },
        {
          name: 'Salmon',
          hex: '#FDA7A7',
          className: 'soluta salmon',
        },
        {
          name: 'Old Yeller',
          hex: '#FFB748',
          className: 'soluta old-yeller',
        },
        {
          name: 'Deep Champagne',
          hex: '#FCDEAF',
          className: 'soluta deep-champagne',
        },
        {
          name: 'Denim',
          hex: '#1D60C1',
          className: 'soluta denim',
        },
        {
          name: 'French Blue',
          hex: '#599FFF',
          className: 'soluta french-blue',
        },
      ],
    }
  },
})
