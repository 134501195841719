import { Module } from 'vuex'
import { RootState, PageableComponentInterface } from '@/store/types'
import { EnrollmentBilling, Actions } from './types'
import { EmployeeBillingDto, EmployeeBillingDetailDto } from '@/autogen-interfaces'
import { ApiError, ActionTreeAdaptor } from '@/store/utils/types'

import EnrollmentApi from '@/api/EnrollmentApi'
import { withAsync } from '@/store/utils/withAsync'

import { loadableMutation, paginationMutation } from '@/store/mutations'

export const enrollmentBilling: Module<EnrollmentBilling, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    totalElements: 0,
    totalPages: 0,
    billingHistory: [],
    dateFilter: null,
    invoiceDetails: {
      loading: false,
      totalElements: 0,
      totalPages: 0,
      details: [],
    },
  },
  mutations: {
    setBillingHistory(state, billingHistory: EmployeeBillingDto[]) {
      state.billingHistory = [...billingHistory]
    },
    setDateFilter(state, dateFilter: Date | null) {
      state.dateFilter = dateFilter
    },
    setInvoiceDetails(state, details: EmployeeBillingDetailDto[]) {
      state.invoiceDetails.details = [...details]
    },
    setDetailsLoading(state, loading: boolean) {
      state.invoiceDetails.loading = loading
    },
    setDetailsPagination(state, pagination: PageableComponentInterface) {
      state.invoiceDetails.totalElements = pagination.totalElements
      state.invoiceDetails.totalPages = pagination.totalPages
    },
    ...loadableMutation,
    ...paginationMutation,
  },
  actions: {
    async fetchBillingHistory({ commit }, { memberId, memberType, filter }) {
      commit('setLoading', true)
      const { error, data }: { error: ApiError; data: EmployeeBillingDto[] } = await withAsync(
        EnrollmentApi.fetchBillingHistory,
        EnrollmentApi,
        memberId,
        memberType,
        filter,
      )
      if (error) {
        console.error(error)
      } else {
        commit('setPagination', {
          totalElements: data.length,
          totalPages: 1,
        })
        commit('setBillingHistory', data)
      }
      commit('setLoading', false)
    },
    async fetchMemberBillingDetails({ commit }, { memberId, billId, filter }) {
      commit('setDetailsLoading', true)
      const { error, data }: { error: ApiError; data: EmployeeBillingDetailDto[] } =
        await withAsync(
          EnrollmentApi.fetchMemberBillingDetails,
          EnrollmentApi,
          memberId,
          billId,
          filter,
        )
      if (error) {
        console.error(error)
      } else {
        commit('setInvoiceDetails', data)
        const pagination: PageableComponentInterface = {
          totalElements: data.length,
          totalPages: 1,
        }
        commit('setDetailsPagination', pagination)
      }
      commit('setDetailsLoading', false)
    },
  } as ActionTreeAdaptor<Actions, EnrollmentBilling, RootState>,
}
