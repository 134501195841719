import { MutationTree } from 'vuex'
import { AlertsState, Alert } from './types'

export const mutations: MutationTree<AlertsState> = {
  setPollingAlerts(state: AlertsState, pollingAlerts: number | null): void {
    state.polling = pollingAlerts
  },
  setAlerts(state, alerts: Array<Alert>): void {
    state.alerts = [...alerts]
  },
  setNewAlertAvailable(state, newAlertAvailable: boolean): void {
    state.newAlertAvailable = newAlertAvailable
  },
  setAlertRead(state, alertId: string): void {
    const alertIndex = state.alerts.findIndex((alert) => alert.id === alertId)
    if (alertIndex > -1) {
      state.alerts[alertIndex].read = true
    }
  },
  setFirstTimeMessage(state, firstTimeMessage: Alert): void {
    state.firstTimeMessage = { ...firstTimeMessage }
  },
  setFirstTimeMessageRead(state): void {
    state.firstTimeMessage.read = true
  },
}
