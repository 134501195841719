









































































































import Vue from 'vue'
import { AppData } from '@/AppData'
import { MemberDto } from '@/autogen-interfaces'

import VerticalTabs from '@/components/common/ui/VerticalTabs/Index.vue'
import HeaderPlain from '@/components/common/ui/HeaderPlain/Index.vue'

export default Vue.extend({
  name: 'EnrollmentDetails',
  components: { VerticalTabs, HeaderPlain },
  data() {
    return {
      isPageRefresh: false,
    }
  },
  async created() {
    await this.$store.dispatch('enrollment/getMember', {
      memberId: this.$route.params.memberId
        ? this.$route.params.memberId
        : this.$store.state.enrollment.currentMember.memberID,
    })

    this.$store.commit(
      'enrollment/setSelectedMember',
      this.$store.state.enrollment.currentMember.memberID,
    )
  },
  computed: {
    showMenu(): boolean {
      if (this.$route.name) {
        return (
          this.$route.name.includes('Product') ||
          this.$route.name.includes('Documents') ||
          this.$route.name.includes('Status')
        )
      }
      return false
    },
    selectedMember(): number {
      return this.$store.state.enrollment.selectedMember
    },
    permissions() {
      return AppData.associations.Permissions.Group
    },
  },
  watch: {
    selectedMember() {
      const member = this.$store.state.enrollment.availableMembers.find(
        (m: MemberDto) => m.memberID == this.selectedMember,
      )
      if (member) {
        this.$store.commit('enrollment/setPerson', member)
      }
    },
  },
})
