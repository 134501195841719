import Vue from 'vue'
import moment from 'moment'
import ExpandableTable from '@/components/common/ui/ExpandableTable/Index.vue'
import FilterSingleDate from '@/components/common/ui/FilterSingleDate/Index.vue'
import { DataTableHeader } from 'vuetify'
import { Operators, RequestFilter, PageableComponentInterface } from '@/store/types'
import { Product, PlanOptionDetails } from '@/store/modules/groupProducts/types'
import ProductDetail from '@/views/groups/products/productDetail/ProductDetail.vue'
import { GroupProductsDto } from '@/autogen-interfaces'

export default Vue.extend({
  components: { ExpandableTable, FilterSingleDate, ProductDetail },
  async created() {
    await this.$store.dispatch('groupProducts/fetchGroupProducts', {
      groupProductFilters: this.groupProductsFilter,
    })
  },
  data: () => ({
    showDescription: false,
    dataTableHeader: [
      { text: 'Product ID', value: 'productId', sortable: false, width: '10%', align: 'start' },
      { text: 'Carrier', value: 'carrier', align: 'start', sortable: false, width: '18%' },
      { text: 'Product', value: 'product', align: 'start', sortable: false, width: '14%' },
      { text: 'Description', value: 'description', align: 'start', sortable: false, width: '18%' },
      {
        text: 'Coverage Tier',
        value: 'coverageTier',
        align: 'start',
        sortable: false,
        width: '15%',
      },
      { text: 'Begin Date', value: 'beginDate', align: 'start', sortable: false, width: '10%' },
      { text: 'End Date', value: 'endDate', align: 'start', sortable: false, width: '10%' },
      { text: 'GPPO', value: 'data-table-expand', align: 'center', sortable: false, width: '5%' },
    ] as DataTableHeader[],
    filters: {} as RequestFilter,
    expanded: [] as GroupProductsDto[],
    planOptionsHeaders: [
      { text: '', value: 'groupProductPlanOptionId', align: 'center' },
      { text: '', value: 'groupProductPlanName', align: 'start' },
      { text: '', value: 'groupProductPlanNameDesc', align: 'start' },
      { text: '', value: 'details', align: 'center' },
    ],
  }),
  computed: {
    planOptionsItems() {
      return this.expanded.length > 0 ? this.expanded[0].planOptions : []
    },
    groupProducts(): Product[] {
      return this.$store.state.groupProducts.products
    },
    loading(): boolean {
      return this.$store.state.groupProducts.loading
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.groupProducts.totalElements,
        totalPages: this.$store.state.groupProducts.totalPages,
      }
    },
    dateFilter: {
      get(): Date {
        return this.$store.state.groupProducts.dateFilter
      },
      set(dateFilter) {
        this.$store.commit('groupProducts/setDateFilter', dateFilter)
      },
    },
    groupProductsFilter(): RequestFilter {
      return {
        ...this.filters,
        filter: [
          {
            operator: Operators.GREATER_OR_EQUAL_THAN,
            field: 'policyEffectiveDate',
            value: moment(this.dateFilter as Date).format(),
            logic: '',
            filters: [],
          },
        ],
      }
    },
  },
  watch: {
    async groupProductsFilter() {
      await this.$store.dispatch('groupProducts/fetchGroupProducts', {
        groupProductFilters: this.groupProductsFilter,
      })
    },
  },
  methods: {
    setSelectedPlan(selectedPlan: PlanOptionDetails): void {
      this.$store.commit('groupProducts/setSelectedPlan', selectedPlan)
      this.$store.dispatch('groupProducts/fetchPlanDocuments', {
        gppoid: selectedPlan.groupProductPlanOptionId,
        groupId: this.$store.state.selectedUserGroupId,
        filter: this.groupProductsFilter,
      })
      this.$store.dispatch('groupProducts/fetchGroupPlanOptions', {
        groupId: selectedPlan.groupProductId,
      })
      this.showDescription = true
    },
  },
})
