import Vue from 'vue'
import { DataTableHeader } from 'vuetify'
import { GroupProductDetail, GroupProductRider } from '@/store/modules/groupProducts/types'
import ExpandableTable from '@/components/common/ui/ExpandableTable/Index.vue'

export default Vue.extend({
  components: { ExpandableTable },
  data: () => ({
    expanded: [],
    dataTableHeader: [
      { text: 'Rider', value: 'rider', sortable: false, width: '25%', align: 'start' },
      { text: 'Plan Name', value: 'planName', align: 'start', sortable: false, width: '35%' },
      {
        text: 'Plan Option Begin',
        value: 'planOptionsBegin',
        align: 'start',
        sortable: false,
        width: '15%',
      },
      {
        text: 'Plan Option Termination',
        value: 'planOptionsTermination',
        align: 'start',
        sortable: false,
        width: '15%',
      },
      { text: 'Optional (Y/N)', value: 'optional', align: 'start', sortable: false, width: '10%' },
      {
        text: '',
        value: 'data-table-expand',
        align: 'center',
        sortable: false,
        cellClass: 'text-end',
      },
    ] as DataTableHeader[],
  }),
  computed: {
    productRiders(): GroupProductRider[] {
      return this.$store.state.groupProducts.selectedPlan.planDetailsTable.items.find(
        (detail: GroupProductDetail) =>
          detail.groupProductPlanOptionId ===
          this.$store.state.groupProducts.selectedPlan.plan.groupProductPlanOptionId,
      ).riders
    },
  },
})
