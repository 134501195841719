import Vue, { PropType } from 'vue'
import { AvailableMember } from '@/store/modules/enrollment/types'
import TagStatus from '@/components/common/ui/TagStatus/Index.vue'

export interface Person {
  name: string
  memberId: string | number
  city: string
  groupId: string | number
  groupPolicyId: string | number
  groupType: string
  active: boolean
  role: string
}

export default Vue.extend({
  name: 'HeaderPlain',
  data() {
    return {
      menuIsOpen: false,
    }
  },
  props: {
    person: {
      type: Object as PropType<Person>,
    },
    menuItems: {
      type: Array as PropType<AvailableMember[]>,
      default: () => [],
    },
    showMenu: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    selected: {
      type: Number as PropType<number>,
      default: () => 0,
    },
  },
  components: {
    TagStatus,
  },
  computed: {
    personNameClass: function (): string {
      let className = ''
      if (this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
        className = 'h2-text'
      } else {
        className = 'h3-text'
      }
      return className
    },
    menuItemsWithoutSelected: function (): AvailableMember[] {
      return this.menuItems.filter((item) => item.memberID !== this.selected)
    },
    displayMenu: function (): boolean {
      return this.showMenu && this.menuItemsWithoutSelected.length > 0
    },
  },
})
