import { ActionTree } from 'vuex'
import { PageableComponentInterface, RequestFilter, RootState } from '@/store/types'
import { Document, GroupDocuments, GroupNotes, Note } from '@/store/modules/groupDocuments/types'
import { ApiError } from '@/store/utils/types'
import { GroupDocumentsDto, GroupNotesDto } from '@/autogen-interfaces'
import { withAsync } from '@/store/utils/withAsync'
import DocumentsApi from '@/api/DocumentsApi'
import NotesApi from '@/api/NotesApi'
import {
  DocumentEntityToDto,
  NoteEntityToDto,
} from '@/store/modules/groupDocuments/DocumentEntityToDto'

export const groupDocumentsActions: ActionTree<GroupDocuments, RootState> = {
  async fetchGroupContacts({ commit, rootState }, requestFilter: RequestFilter) {
    commit('setLoading', true)
    const groupId = rootState.selectedUserGroupId
    const documentTypeID: number[] = []
    const mimeTypeID: number[] = []
    const { error, data }: { error: ApiError; data: GroupDocumentsDto[] } = await withAsync(
      DocumentsApi.fetchGroupDocuments,
      DocumentsApi,
      groupId,
      documentTypeID,
      mimeTypeID,
      requestFilter,
    )
    if (error) {
      console.error(error)
    } else {
      const documents: Document[] = []
      for (const groupDocument of data) {
        documents.push(DocumentEntityToDto.convert(groupDocument))
      }
      commit('setDocuments', documents)

      const pagination: PageableComponentInterface = {
        totalElements: documents.length,
        totalPages: 1,
      }
      commit('setPagination', pagination)
    }
    commit('setLoading', false)
  },
}

export const groupNotesActions: ActionTree<GroupNotes, RootState> = {
  async fetchGroupNotes({ commit, rootState }, requestFilter: RequestFilter) {
    commit('setLoading', true)
    const groupId = rootState.selectedUserGroupId
    const { error, data }: { error: ApiError; data: GroupNotesDto[] } = await withAsync(
      NotesApi.fetchGroupNoteDetails,
      NotesApi,
      groupId,
      requestFilter,
    )
    if (error) {
      console.error(error)
    } else {
      const notes: Note[] = []
      for (const groupNotes of data) {
        notes.push(NoteEntityToDto.convert(groupNotes))
      }
      commit('setNotes', notes)
      const pagination: PageableComponentInterface = {
        totalElements: notes.length,
        totalPages: 1,
      }
      commit('setPagination', pagination)
    }
    commit('setLoading', false)
  },
}
