import Vue from 'vue'

interface VFilter extends HTMLFormElement {
  computedId: string
}

export default Vue.extend({
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    vFilterFieldId: '',
  }),
  mounted() {
    this.vFilterFieldId = this.vFilter.computedId
  },
  computed: {
    vFilter: function (): VFilter {
      return this.$refs.vFilterField as VFilter
    },
  },
})
