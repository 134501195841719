




















import Vue from 'vue'

import CardProduct from '@/components/common/ui/CardProduct/Index.vue'
import FilterSingleDate from '@/components/common/ui/FilterSingleDate/Index.vue'
import ProductEnrollmentDetails from './details/ProductEnrollmentDetails.vue'

import { MemberEnrollmentAndPlanCoverageInfoDto } from '@/autogen-interfaces'
import { AvailableMember } from '@/store/modules/enrollment/types'

import { formatDate } from '@/helpers'

export default Vue.extend({
  name: 'EnrollmentProductProductEnrollment',
  components: {
    CardProduct,
    FilterSingleDate,
    ProductEnrollmentDetails,
  },
  beforeMount() {
    this.$store.commit('enrollmentProducts/setDateFilter', null)
    this.refetchData()
  },
  computed: {
    cardInfo(): any[] {
      return this.$store.state.enrollmentProducts.memberEnrollmentAndPlanCoverageInfo.map(
        (plan: MemberEnrollmentAndPlanCoverageInfoDto) => {
          return {
            headerInfo: {
              title: plan.groupProductPlanName,
              subtitle: plan.carrierProductName,
              active: Math.random() > 0.5,
              effective: formatDate(plan.originalEffectiveDate ? plan.originalEffectiveDate : ''),
              paid: formatDate(plan.paidThruDate ? plan.paidThruDate : ''),
            },
            carrier: plan.carrierName,
            coverage: {
              name: plan.coverageTier,
              value: plan.coverageVolume,
            },
            gppoId: plan.groupProductPlanOptionID,
            extPlanCode: plan.externalPlanCode,
            planElectionId: plan.planEnrollmentID,
          }
        },
      )
    },
    dateFilter: {
      get(): Date {
        return this.$store.state.enrollmentProducts.dateFilter
      },
      set(dateFilter) {
        this.$store.commit('enrollmentProducts/setDateFilter', dateFilter)
      },
    },
    selectedMember(): number {
      return this.$store.state.enrollment.selectedMember || this.$route.params.memberId
    },
    memberType(): number {
      return (
        this.$store.state.enrollment.availableMembers.find(
          (m: AvailableMember) => m.memberID === this.selectedMember,
        )?.memberTypeID || 40 // on page refresh, fetch employee data, default to 40
      )
    },
  },
  methods: {
    refetchData(): void {
      this.$store.dispatch('enrollmentProducts/getMemberEnrollmentAndPlanCoverageInfo', {
        memberId: this.selectedMember,
        memberTypeId: this.memberType,
        asOfDate: this.dateFilter,
      })
    },
    showEnrollmentDetails(card: any) {
      const selectedPlan =
        this.$store.state.enrollmentProducts.memberEnrollmentAndPlanCoverageInfo.find(
          (plan: MemberEnrollmentAndPlanCoverageInfoDto) =>
            plan.planEnrollmentID === card.planElectionId,
        )
      this.$store.commit('enrollmentProducts/setSelectedProductEnrollment', selectedPlan)
      this.$store.dispatch('enrollmentProducts/getDocuments', {
        parameterId: selectedPlan.planEnrollmentID,
        parameterName: 'PlanEnrollmentID',
        filter: { page: 1, pageSize: 10, skip: 0, take: 10, sort: [], filter: [] },
      })
      this.$store.commit('enrollmentProducts/setShowProductEnrollmentDetails', true)
    },
  },
  watch: {
    selectedMember() {
      this.refetchData()
    },
    dateFilter() {
      this.refetchData()
    },
  },
})
