import Vue from 'vue'
import moment from 'moment'
import CardEnrollmentAdvanced from '@/components/common/ui/CardEnrollmentAdvanced/Index.vue'
import FilterSingleDate from '@/components/common/ui/FilterSingleDate/Index.vue'
import { GridFilter, Operators } from '@/store/types'
import { EnrollmentStatus } from '@/store/modules/enrollmentStatus/types'
import { AvailableMember } from '@/store/modules/enrollment/types'

export default Vue.extend({
  name: 'EnrollmentStatusStatusHistory',
  components: { CardEnrollmentAdvanced, FilterSingleDate },
  async created() {
    await this.fetchMemberStatus()
  },
  computed: {
    selectedMember(): number {
      return this.$store.state.enrollment.selectedMember
    },
    memberRole(): string {
      return (
        this.$store.state.enrollment.availableMembers.find(
          (m: AvailableMember) => m.memberID === this.selectedMember,
        )?.role || ''
      )
    },
    statusHistory(): any {
      return this.$store.state.enrollmentStatus.statusHistory.map(
        (memberStatus: EnrollmentStatus) => {
          return { ...memberStatus, role: this.memberRole }
        },
      )
    },
    dateFilter: {
      get(): Date | null {
        return this.$store.state.enrollmentStatus.dateFilter
      },
      set(dateFilter): void {
        this.$store.commit('enrollmentStatus/setDateFilter', dateFilter)
      },
    },
    statusHistoryFilter(): GridFilter[] | any {
      if (this.dateFilter !== null) {
        return [
          {
            field: 'beginDate',
            filters: [],
            logic: 'AND',
            operator: Operators.GREATER_OR_EQUAL_THAN,
            value: moment(this.dateFilter as Date).format(),
          },
        ]
      }
      return []
    },
  },
  methods: {
    async fetchMemberStatus() {
      await this.$store.dispatch('enrollmentStatus/fetchMemberStatus', {
        memberId: this.$store.state.enrollment.selectedMember
          ? this.$store.state.enrollment.selectedMember
          : this.$route.params.memberId,
        requestFilter: {
          page: 1,
          pageSize: 10,
          skip: 0,
          take: 10,
          sort: [],
          filter: this.statusHistoryFilter,
        },
      })
    },
  },
  watch: {
    dateFilter() {
      this.fetchMemberStatus()
    },
    selectedMember() {
      this.fetchMemberStatus()
    },
  },
})
