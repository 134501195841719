import Vue from 'vue'
import moment from 'moment'
import FilterDate from '@/components/common/ui/FilterDate/Index.vue'
import { GridFilter, Operators, PageableComponentInterface, RequestFilter } from '@/store/types'
import { Note } from '@/store/modules/groupDocuments/types'
import { FilterDateModel } from '@/components/common/ui/FilterDate/filterDate'
import ExpandableTable from '@/components/common/ui/ExpandableTable/Index.vue'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'

export default Vue.extend({
  components: { FilterDate, ExpandableTable },
  data: () => ({
    expanded: [],
    filters: {} as RequestFilter,
  }),
  created() {
    this.$store.dispatch('enrollmentNotes/fetchNoteTypes')
  },
  methods: {
    fetchGroupDocumentNotes() {
      this.$store.dispatch('groupNotes/fetchGroupNotes', this.groupNotesFilter)
    },
  },
  computed: {
    dataTableHeader(): FilterableDataTableHeader[] {
      return [
        {
          text: 'Note ID',
          value: 'noteId',
          filterable: 'text',
          width: '10%',
          align: 'start',
          cellClass: 'notesCell',
        },
        {
          text: 'Date Updated',
          value: 'dateUpdated',
          align: 'center',
          filterable: 'text',
          width: '15%',
          cellClass: 'notesCell',
          inputRegex: '##/##/####',
          placeHolder: 'MM/DD/YYYY',
        },
        {
          text: 'Note Type',
          value: 'noteType',
          align: 'start',
          filterable: 'dropdown',
          selectOptions: this.$store.state.enrollmentNotes.noteTypes,
          width: '15%',
          cellClass: 'notesCell',
        },
        { text: 'Note', value: 'note', align: 'start', filterable: 'text' },
        {
          text: 'Phone Number',
          value: 'phoneNumber',
          align: 'center',
          filterable: 'text',
          width: '15%',
          cellClass: 'notesCell',
          inputRegex: '(###) ###-####',
          placeHolder: '(123) 123-12345',
        },
        {
          text: 'TIN',
          value: 'tin',
          align: 'center',
          filterable: 'text',
          width: '5%',
          cellClass: 'notesCell',
        },
        {
          text: 'Resolution Type',
          value: 'resolutionType',
          align: 'center',
          filterable: 'text',
          width: '15%',
          cellClass: 'notesCell',
        },
        {
          text: '',
          value: 'data-table-expand',
          align: 'center',
          filterable: 'text',
          width: '5%',
          cellClass: 'text-end notesCell',
        },
      ]
    },
    filterDates: {
      get(): FilterDateModel {
        return this.$store.state.groupNotes.filterDates
      },
      set(filterDates: FilterDateModel): void {
        this.$store.commit('groupNotes/setFilterDates', filterDates)
      },
    },
    groupNotes(): Note[] {
      return this.$store.state.groupNotes.notes
    },
    loading(): boolean {
      return this.$store.state.groupNotes.loading
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.groupContacts.totalElements,
        totalPages: this.$store.state.groupContacts.totalPages,
      }
    },
    groupNotesFilter(): RequestFilter {
      //insertDate
      const gridFilters: GridFilter[] = [
        {
          field: 'dateCreated',
          filters: [],
          logic: 'AND',
          operator: Operators.GREATER_OR_EQUAL_THAN,
          value: moment(this.filterDates.beginDate as Date).format(),
        },
        {
          field: 'dateCreated',
          filters: [],
          logic: 'AND',
          operator: Operators.LOWER_OR_EQUAL_THAN,
          value: moment(this.filterDates.endDate as Date).format(),
        },
      ]
      return { ...this.filters, filter: gridFilters.concat(this.filters.filter) }
    },
  },
  watch: {
    groupNotesFilter() {
      this.fetchGroupDocumentNotes()
    },
  },
})
