import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import FileCopy from '@/assets/icons/FileCopy.vue'
import Spreadsheet from '@/assets/icons/Spreadsheet.vue'
import PersonPinCircle from '@/assets/icons/PersonPinCircle.vue'
import AttachMoney from '@/assets/icons/AttachMoney.vue'
import PermContactCalendar from '@/assets/icons/PermContactCalendar.vue'
import ArrowBackIOS from '@/assets/icons/ArrowBackIOS.vue'
import ArrowForwardIOS from '@/assets/icons/ArrowForwardIOS.vue'
import MoreHorizontal from '@/assets/icons/MoreHorizontal.vue'
import Chart from '@/assets/icons/Chart.vue'
import BorderColor from '@/assets/icons/BorderColor.vue'
import FilePDF from '@/assets/icons/FilePDF.vue'
import News from '@/assets/icons/News.vue'
import { Iconfont } from 'vuetify/types/services/icons'
import '@mdi/font/css/materialdesignicons.css'
import defaultThemeColors from './defaultTheme'

Vue.use(Vuetify)
export const vuetifyProperties = {
  theme: {
    themes: {
      light: {
        ...defaultThemeColors,
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    iconfont: 'mdi' as Iconfont,
    values: {
      fileCopy: {
        component: FileCopy,
      },
      spreadsheet: {
        component: Spreadsheet,
      },
      personPinCircle: {
        component: PersonPinCircle,
      },
      attachMoney: {
        component: AttachMoney,
      },
      permContactCalendar: {
        component: PermContactCalendar,
      },
      arrowBackIOS: {
        component: ArrowBackIOS,
      },
      arrowForwardIOS: {
        component: ArrowForwardIOS,
      },
      moreHorizontal: {
        component: MoreHorizontal,
      },
      chart: {
        component: Chart,
      },
      borderColor: {
        component: BorderColor,
      },
      filePDF: {
        component: FilePDF,
      },
      news: {
        component: News,
      },
    },
  },
}

export default new Vuetify(vuetifyProperties)
