import { GroupDto } from '@/autogen-interfaces'
import { Group } from '@/store/modules/groupUserSelection/type'
import { formatDate } from '@/helpers'

export class GroupEntityToDto {
  public static convert(groupDto: GroupDto): Group {
    return {
      effectiveDate: groupDto.groupEffectiveDate ? formatDate(groupDto.groupEffectiveDate) : '',
      externalId: this.nullCheck(groupDto.externalGroupNumber),
      groupDescription: this.nullCheck(groupDto.masterGrouperName),
      groupId: groupDto.groupId ? groupDto.groupId : -1,
      groupName: this.nullCheck(groupDto.groupName),
      terminationDate: groupDto.groupTermDate ? formatDate(groupDto.groupTermDate) : '',
    }
  }

  private static nullCheck(value: string | null | undefined): string {
    return value || ''
  }
}
