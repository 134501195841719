import Vue from 'vue'

interface VCombobox extends HTMLFormElement {
  computedId: string
  clearableCallback(): void
}

export default Vue.extend({
  props: {
    placeHolder: {
      type: String,
      default: 'Search keyword',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    showAdvance: false as boolean,
    value: '' as string,
    vAutoCompleteId: '' as string,
    active: false as boolean,
    searchInput: '' as string,
  }),
  mounted: function () {
    this.vAutoCompleteId = 'div_menu' + this.vAutoComplete.computedId
  },
  watch: {
    searchInput(str: string) {
      this.$emit('input', str)
    },
  },
  methods: {
    handleInput(): void {
      this.$emit('input', this.value)
    },
    handleClear(): void {
      this.vAutoComplete.clearableCallback()
    },
    setShowAdvance(value: boolean): void {
      this.showAdvance = value
      this.$emit('show-advanced-search', this.showAdvance)
    },
  },
  computed: {
    vAutoComplete: function (): VCombobox {
      return this.$refs.vAutoComplete as VCombobox
    },
  },
})
