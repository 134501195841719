

























































import Vue from 'vue'
import mixin from '@/mixin'

import Tag from '@/components/common/ui/Tag/Index.vue'
import FilterSingleDate from '@/components/common/ui/FilterSingleDate/Index.vue'
import BillingHistoryDetails from './details/BillingHistoryDetails.vue'
import DataTable from '@/components/common/ui/DataTable/Index.vue'

import { DataTableHeader } from 'vuetify/types'
import { EmployeeBillingDto } from '@/autogen-interfaces'
import { RequestFilter, Operators, GridFilter, PageableComponentInterface } from '@/store/types'

import moment from 'moment'

export default Vue.extend({
  components: { FilterSingleDate, Tag, BillingHistoryDetails, DataTable },
  data: () => ({
    dataTableHeader: [
      { text: 'Invoice Name', value: 'invoiceName', sortable: false },
      { text: 'Invoice #', value: 'invoiceNumber', align: 'start', sortable: false },
      { text: 'Bill Date', value: 'billDate', align: 'start', sortable: false },
      { text: 'Status', value: 'billStatus', align: 'start', sortable: false },
      { text: 'Total Amt.', value: 'totalAmount', align: 'end', sortable: false },
      { text: 'Amt. Applied', value: 'cashAmountApplied', align: 'end', sortable: false },
      { text: 'Net Due', value: 'netDue', align: 'center', sortable: false },
      { text: '', value: 'expand', align: 'start', sortable: false },
    ] as DataTableHeader[],
    showDetails: false,
    invoiceId: '',
    filters: {
      page: 1,
      pageSize: 10,
      skip: 0,
      take: 10,
      sort: [],
      filter: [],
    } as RequestFilter,
  }),
  mixins: [mixin],
  computed: {
    items(): EmployeeBillingDto[] {
      return this.$store.state.enrollmentBilling.billingHistory
    },
    loading(): boolean {
      return this.$store.state.enrollmentBilling.loading
    },
    dateFilter: {
      get(): Date {
        return this.$store.state.enrollmentBilling.dateFilter
      },
      set(dateFilter) {
        this.$store.commit('enrollmentBilling/setDateFilter', dateFilter)
      },
    },
    enrollmentBillingHistoryFilter(): RequestFilter {
      const filter: GridFilter[] = []
      // only send date if not null
      if (this.dateFilter) {
        filter.push({
          operator: Operators.GREATER_OR_EQUAL_THAN,
          field: 'billDate',
          value: moment(this.dateFilter as Date).format(),
          logic: '',
          filters: [],
        })
      }
      return {
        ...this.filters,
        filter,
      }
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.enrollmentBilling.totalElements,
        totalPages: this.$store.state.enrollmentBilling.totalPages,
      }
    },
  },
  methods: {
    async fetchBillingHistory(): Promise<void> {
      await this.$store.dispatch('enrollmentBilling/fetchBillingHistory', {
        memberId: this.$route.params.memberId,
        memberType: this.$store.state.enrollment.currentMember.memberTypeID,
        filter: this.enrollmentBillingHistoryFilter,
      })
    },
    showBillDetails(item: EmployeeBillingDto): void {
      this.invoiceId = item.invoiceNumber || 'None'
      this.showDetails = true
      this.$store.dispatch('enrollmentBilling/fetchMemberBillingDetails', {
        memberId: this.$route.params.memberId,
        billId: item.billBatchID,
        filter: {
          page: 1,
          pageSize: 10,
          skip: 0,
          take: 10,
          sort: [],
          filter: [],
        },
      })
    },
  },
  watch: {
    async enrollmentBillingHistoryFilter() {
      await this.fetchBillingHistory()
    },
  },
})
