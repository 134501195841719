import {
  GroupStructureBenefits,
  GroupStructureClasses,
  GroupStructureDivision,
  GroupStructurePage,
} from '@/store/modules/groupStructure/types'
import {
  GroupBenefitGroupDto,
  GroupClassDto,
  GroupContactsDto,
  GroupDivisionDto,
} from '@/autogen-interfaces'
import moment from 'moment'

const groupStructureDivision: GroupStructureDivision = {
  invoiceSettings: [],
  dateFilter: moment().toDate(),
  loading: false,
  groupId: 0,
  groupDivisions: [] as GroupDivisionDto[],
  groupContacts: [] as GroupContactsDto[],
  totalElements: 10,
  totalPages: 1,
}

const groupStructureClass: GroupStructureClasses = {
  dateFilter: moment().toDate(),
  totalElements: 10,
  totalPages: 1,
  loading: false,
  groupId: 0,
  groupClasses: [] as GroupClassDto[],
}

const groupStructureBenefits: GroupStructureBenefits = {
  dateFilter: moment().toDate(),
  totalElements: 10,
  totalPages: 1,
  loading: false,
  groupId: 0,
  groupBenefitGroups: [] as GroupBenefitGroupDto[],
}

const state: GroupStructurePage = {
  divisions: groupStructureDivision as GroupStructureDivision,
  classes: groupStructureClass,
  benefitGroups: groupStructureBenefits,
}

export default state
