import Vue from 'vue'
import Drawer from '@/components/common/ui/Drawer/Index.vue'
import DataTable from '@/components/common/ui/DataTable/Index.vue'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'
import { PageableComponentInterface, RequestFilter } from '@/store/types'
import {
  GroupBillingHistoryItem,
  GroupInfo,
  PaymentHistoryTableItem,
  SelectedGroupBillingHistoryItem,
} from '@/store/modules/groups/types'

export default Vue.extend({
  components: { Drawer, DataTable },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    tabModel: 'tabPayments',
    filters: {} as RequestFilter,
    dataTableHeader: [
      {
        text: 'Deposit',
        value: 'deposit',
        align: 'start',
      },
      {
        text: 'Deposit Date',
        value: 'depositDate',
        align: 'start',
      },
      {
        text: 'Posted',
        value: 'posted',
        align: 'start',
      },
      {
        text: 'Deposit Amount',
        value: 'depositAmount',
        align: 'start',
      },
      {
        text: 'Check Number',
        value: 'checkNumber',
        align: 'start',
      },
      {
        text: 'Status',
        value: 'status',
        align: 'start',
      },
    ] as FilterableDataTableHeader[],
  }),
  computed: {
    historyBilling(): GroupBillingHistoryItem {
      return this.selectedItem.groupBillingHistory
    },
    groupInfo(): GroupInfo {
      return this.$store.state.groups.info
    },
    selectedItem(): SelectedGroupBillingHistoryItem {
      return this.$store.state.groups.billingHistory.selectedItem
    },
    loading(): boolean {
      return this.$store.state.groups.loading
    },
    paymentItems(): PaymentHistoryTableItem[] {
      return this.selectedItem.paymentItems
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.groups.billingHistory.selectedItem.totalElements,
        totalPages: this.$store.state.groups.billingHistory.selectedItem.totalPages,
      }
    },
  },
  watch: {
    historyBilling() {
      this.fetchGroupPaymentsHistory()
    },
    filters() {
      this.fetchGroupPaymentsHistory()
    },
  },
  methods: {
    fetchGroupPaymentsHistory() {
      this.$store.dispatch('groups/fetchGroupPaymentsHistory', {
        groupId: this.groupInfo.id,
        invoiceNumber: this.historyBilling.invoiceNumber,
        requestFilter: this.filters,
      })
    },
  },
})
