import { MutationTree } from 'vuex'
import { BillingPage, BillingStatementsPayments } from '@/store/modules/billing/types'
import {
  BillingStatementGridDto,
  GroupPaymentHistoryDto,
  BillingDivisionsDto,
  DocumentDataDto,
} from '@/autogen-interfaces'
import { getUniqueItems } from '@/helpers'
import { PageableComponentInterface } from '@/store/types'

export const mutations: MutationTree<BillingPage> = {
  setSelectedBillingStatement(state, item: BillingStatementGridDto) {
    state.billingStatements.selectedItem = { ...item }
  },
  setSelectedBillingStatementDetail(state, payments: BillingStatementsPayments[]) {
    state.billingStatements.selectedItemDetail.payments = [...payments]
  },
  setBillingStatementsPaymentsPagePagination(state, pageable: PageableComponentInterface) {
    state.billingStatements.selectedItemDetail.totalPages = pageable.totalPages
    state.billingStatements.selectedItemDetail.totalElements = pageable.totalElements
  },
  setDownloadInvoiceDate(state, documentData: DocumentDataDto) {
    state.billingStatements.selectedItemDetail.downloadInvoice = { ...documentData }
  },
  setLoading(state, loading: boolean) {
    state.loading = loading
  },
  setBillingGroups(state, billingStatements: BillingStatementGridDto[]) {
    const groups = getUniqueItems(billingStatements, 'groupName')
    // Keep only groupInvoiceSettingID and groupName from each group
    const filteredGroups = groups.map((g: BillingStatementGridDto) => {
      return (({ groupInvoiceSettingID, groupName }) => ({ groupInvoiceSettingID, groupName }))(g)
    })
    state.billingGroups = [{ groupInvoiceSettingID: -1, groupName: 'All' }, ...filteredGroups]
  },
  setBillingDivisions(state, billingDivisions: BillingDivisionsDto[]) {
    state.billingDivisions = [
      { groupInvoiceSettingID: -1, invoiceDesc: 'All' },
      ...billingDivisions,
    ]
  },
  setBillingStatements(state, billingStatements: BillingStatementGridDto[]) {
    state.billingStatements.items = [...billingStatements]
  },
  setBillingStatementsPagination(state, pageable: PageableComponentInterface) {
    state.billingStatements.totalPages = pageable.totalPages
    state.billingStatements.totalElements = pageable.totalElements
  },
  setBillingSuspense(state, billingSuspense: GroupPaymentHistoryDto[]) {
    state.billingSuspense.items = [...billingSuspense]
  },
  setBillingSuspensePagination(state, pageable: PageableComponentInterface) {
    state.billingSuspense.totalPages = pageable.totalPages
    state.billingSuspense.totalElements = pageable.totalElements
  },
}
