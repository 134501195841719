<template>
  <v-btn elevation="0" v-bind="$attrs" v-on="$listeners" class="v-btn--has-icon" ref="vBtnIcon">
    <v-icon left class="mx-2"> {{ icon }} </v-icon>
    <div class="divider"></div>
    <div class="ml-2">{{ text }}</div>
  </v-btn>
</template>
<script>
export default {
  props: {
    icon: String,
    text: String,
  },
}
</script>
