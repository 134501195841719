import { MutationTree } from 'vuex'
import { loadableMutation, paginationMutation } from '@/store/mutations'
import {
  Contact,
  GroupContacts,
  GroupVendors,
  Vendor,
} from '@/store/modules/groupContactsVendors/types'

export const contactsMutation: MutationTree<GroupContacts> = {
  setContacts(state, contacts: Contact[]) {
    state.contacts = contacts
  },
  ...loadableMutation,
  ...paginationMutation,
}

export const groupVendorsMutation: MutationTree<GroupVendors> = {
  setVendors(state, vendors: Vendor[]) {
    state.vendors = vendors
  },
  setDateFilter(state, dateFilter: Date) {
    state.dateFilter = dateFilter
  },
  ...loadableMutation,
  ...paginationMutation,
}
