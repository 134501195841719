import { ActionTree } from 'vuex'
import { RootState, RequestFilter, PageableComponentInterface } from '@/store/types'
import { GroupBillingHistoryItem, Groups, PaymentHistoryTableItem } from './types'
import { ApiError } from '@/store/utils/types'
import {
  GroupPageInfoDto,
  GroupBillingHistoryDto,
  GroupPaymentHistoryDto,
  DocumentDto,
  DocumentDataDto,
} from '@/autogen-interfaces'
import { GroupInfoEntityToDto } from '@/store/modules/groups/EntityToDto'

import GroupsApi from '@/api/GroupsApi'
import DocumentsApi from '@/api/DocumentsApi'
import { withAsync } from '@/store/utils/withAsync'

export const actions: ActionTree<Groups, RootState> = {
  async fetchGroupDetails({ commit, rootState }) {
    const groupID = rootState.selectedUserGroupId
    const DocumentTypeID: number[] = [] // TODO Change this implementation
    const MimeTypeID: number[] = []
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: GroupPageInfoDto } = await withAsync(
      GroupsApi.fetchGroupDetails,
      GroupsApi,
      groupID,
      DocumentTypeID,
      MimeTypeID,
    )
    if (error) {
      console.error(error)
    } else {
      const info = GroupInfoEntityToDto.convert(data)
      commit('setInfo', info)
      commit('setLoading', false)
    }
  },

  async fetchGroupBillingHistory({ commit, rootState }, requestFilter: RequestFilter) {
    const groupId = rootState.selectedUserGroupId
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: GroupBillingHistoryDto[] } = await withAsync(
      GroupsApi.fetchGroupBillingHistory,
      GroupsApi,
      groupId,
      requestFilter,
    )
    commit('setLoading', false)
    if (error) {
      console.error(error)
    } else {
      const billingHistories: GroupBillingHistoryItem[] = []
      for (const dto of data) {
        billingHistories.push(GroupInfoEntityToDto.convertBillingHistory(dto))
      }
      commit('setBillingHistory', billingHistories)
      const pagination: PageableComponentInterface = {
        totalElements: billingHistories.length,
        totalPages: 1,
      }
      commit('setBillingHistoryPagination', pagination)
    }
  },

  async fetchGroupPaymentHistory({ commit, rootState }, requestFilter: RequestFilter) {
    const groupId = rootState.selectedUserGroupId
    const invoiceNumber = '11189AA20180501' // TODO change this impl
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: GroupPaymentHistoryDto[] } = await withAsync(
      GroupsApi.fetchGroupPaymentHistory,
      GroupsApi,
      groupId,
      invoiceNumber,
      requestFilter,
    )
    commit('setLoading', false)
    if (error) {
      console.error(error)
    } else {
      commit('setPaymentHistory', data)
      const pagination: PageableComponentInterface = {
        totalElements: data.length,
        totalPages: 1,
      }
      commit('setPaymentHistoryPagination', pagination)
    }
  },

  async fetchGroupPaymentsHistory(
    { commit },
    {
      groupId,
      invoiceNumber,
      requestFilter,
    }: { groupId: number; invoiceNumber: string; requestFilter: RequestFilter },
  ) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: GroupPaymentHistoryDto[] } = await withAsync(
      GroupsApi.fetchGroupPaymentHistory,
      GroupsApi,
      groupId, // TODO change this implementation
      invoiceNumber,
      requestFilter,
    )
    commit('setLoading', false)
    if (error) {
      console.error(error)
    } else {
      const paymentsHistory: PaymentHistoryTableItem[] = []
      for (const dto of data) {
        paymentsHistory.push(GroupInfoEntityToDto.convertPaymentHistory(dto))
      }
      const pageableComponent: PageableComponentInterface = {
        totalElements: paymentsHistory.length,
        totalPages: 1,
      }
      commit('setSelectedBillingHistoryPaymentHistory', { paymentsHistory, pageableComponent })
    }
  },
  async getInvoiceDocumentId(
    { commit, rootState },
    invoiceId: number,
  ): Promise<number | undefined> {
    commit('setLoading', true)
    const groupID = rootState.selectedUserGroupId
    const { error, data }: { error: ApiError; data: DocumentDto[] } = await withAsync(
      DocumentsApi.fetchDocuments,
      DocumentsApi,
      { InvoiceNumber: invoiceId, GroupId: groupID },
      { filter: [], page: 1, pageSize: 1, skip: 0, sort: [], take: 1 },
    )
    if (error) {
      console.error(error)
      commit('setLoading', false)
    } else {
      commit('setLoading', false)
      if (data && data.length > 0) return (data[0] as DocumentDto).documentID
    }
    return undefined
  },
  async fetchDocumentData({ commit }, documentId: number) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: DocumentDataDto } = await withAsync(
      DocumentsApi.fetchDocumentData,
      DocumentsApi,
      documentId,
      {},
    )
    if (error) {
      console.error(error)
    } else {
      commit('setDownloadInvoiceDate', data)
    }
    commit('setLoading', false)
  },
  async fetchInvoiceData({ dispatch }, invoiceId: number) {
    const documentId = await dispatch('getInvoiceDocumentId', invoiceId)
    if (documentId) await dispatch('fetchDocumentData', documentId)
  },
}
