import Vue from 'vue'
import mixin from '@/mixin'
import { GroupPaymentHistoryDto } from '@/autogen-interfaces'
import { PageableComponentInterface, RequestFilter } from '@/store/types'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'
import DataTable from '@/components/common/ui/DataTable/Index.vue'

export default Vue.extend({
  name: 'BillingHistorySuspense',
  components: { DataTable },
  data: () => ({
    filters: {} as RequestFilter,
    headers: [
      { text: 'Deposit Category', sortable: false, align: 'start', value: 'depositCategory' },
      { text: 'Deposit Date', sortable: false, align: 'start', value: 'depositDate' },
      { text: 'Deposit Desc', sortable: false, align: 'start', value: 'depositDesc' },
      { text: 'Invoice ID', sortable: false, align: 'start', value: 'invoiceNumber' },
      { text: 'Coverage Begin Date', sortable: false, align: 'start', value: 'coverageBeginDate' },
      { text: 'Check Number', sortable: false, align: 'start', value: 'checkNo' },
      { text: 'Bank Code', sortable: false, align: 'start', value: 'bankCode' },
      { text: 'Amount Posted', sortable: false, align: 'start', value: 'masCashAmountApplied' },
      { text: 'Notes', sortable: false, align: 'start', value: 'notes' },
    ] as FilterableDataTableHeader[],
    showDetails: false,
  }),
  mixins: [mixin],
  methods: {
    fetchGroupPaymentHistory() {
      this.$store.dispatch('groups/fetchGroupPaymentHistory', this.filters)
    },
  },
  watch: {
    filters() {
      this.fetchGroupPaymentHistory()
    },
  },
  computed: {
    items(): GroupPaymentHistoryDto[] {
      return this.$store.state.groups.paymentHistory.items
    },
    loading(): boolean {
      return this.$store.state.groups.loading
    },
    totalSuspense(): number {
      let accumulator = 0
      this.items.forEach((item: GroupPaymentHistoryDto) => {
        accumulator += item.masCashAmountApplied ? +item.masCashAmountApplied : 0
      })
      return accumulator
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.groups.paymentHistory.totalElements,
        totalPages: this.$store.state.groups.paymentHistory.totalPages,
      }
    },
  },
})
