




































































































































































































import Vue from 'vue'
import moment from 'moment'

import mixin from '@/mixin'
import { roundNumber } from '@/helpers'

import FilterDate from '@/components/common/ui/FilterDate/Index.vue'
import CardEnrollmentGeneric from '@/components/common/ui/CardEnrollmentGeneric/Index.vue'

import { FilterDateModel } from '@/components/common/ui/FilterDate/filterDate'
import { DataTableHeader } from 'vuetify/types'
import { RequestFilter, Operators } from '@/store/types'

export default Vue.extend({
  name: 'EnrollmentFringeAllocation',
  components: {
    FilterDate,
    CardEnrollmentGeneric,
  },
  mixins: [mixin],
  data() {
    return {
      headers: [
        { text: 'Product', sortable: false, value: 'carrierProductName' },
        { text: 'Plan Name', sortable: false, value: 'groupProductPlanName' },
        { text: 'Rider Name', sortable: false, value: 'riderPlanName' },
        { text: 'Hours', sortable: false, value: 'hoursAllocated', align: 'right' },
        { text: 'Rate', sortable: false, value: 'rate', align: 'right' },
        { text: 'Fringe Spent', sortable: false, value: 'total', align: 'right' },
      ] as DataTableHeader[],
      dateRangeFilter: {
        beginDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate(),
      } as FilterDateModel,
    }
  },
  async created() {
    if (this.$store.state.enrollment.currentMember) {
      // default to employee, no menu selection on this page, but only if this is not page refresh
      this.$store.commit('enrollment/setPerson', this.$store.state.enrollment.currentMember)
      this.$store.commit(
        'enrollment/setSelectedMember',
        this.$store.state.enrollment.currentMember.memberID,
      )
    }
    await this.fetchFringePeriods()
  },
  computed: {
    fringePeriods() {
      return this.$store.state.enrollmentFringe.fringe
    },
    filter(): RequestFilter {
      return {
        page: -1,
        pageSize: -1,
        skip: 0,
        take: -1,
        sort: [],
        filter: [
          {
            operator: Operators.GREATER_OR_EQUAL_THAN,
            field: 'payDate',
            value: this.dateRangeFilter.beginDate.toString(),
            logic: 'and',
            filters: [],
          },
          {
            operator: Operators.LOWER_OR_EQUAL_THAN,
            field: 'payDate',
            value: this.dateRangeFilter.endDate.toString(),
            logic: 'and',
            filters: [],
          },
        ],
      }
    },
  },
  watch: {
    dateRangeFilter: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.beginDate !== oldVal.beginDate || newVal.endDate !== oldVal.endDate) {
          this.fetchFringePeriods()
        }
      },
    },
  },
  methods: {
    async fetchFringePeriods() {
      await this.$store.dispatch('enrollmentFringe/fetchFringePeriods', {
        memberId: this.$store.state.enrollment.selectedMember
          ? this.$store.state.enrollment.selectedMember
          : this.$route.params.memberId,
        filter: this.filter,
      })
    },
    roundNumber(num: number) {
      return roundNumber(num)
    },
  },
})
