import Vue from 'vue'

export default Vue.extend({
  props: {
    backgroundColor: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: 0,
    },
    length: {
      type: Number,
      default: 0,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      availableItemsPerPage: [10, 20, 30],
      selectedItemsPerPage: 10,
    }
  },
  computed: {
    from: function (): number {
      return this.value * this.selectedItemsPerPage - this.selectedItemsPerPage + 1
    },
    to: function (): number {
      const toNumber = this.value * this.selectedItemsPerPage
      return toNumber > this.totalItems ? this.totalItems : toNumber
    },
  },
  created() {
    this.selectedItemsPerPage = this.perPage
  },
})
