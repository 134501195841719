import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=45249c76&scoped=true&"
import script from "./tag.ts?vue&type=script&lang=ts&"
export * from "./tag.ts?vue&type=script&lang=ts&"
import style0 from "./tag.scss?vue&type=style&index=0&id=45249c76&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45249c76",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VChip,VList,VMenu})
