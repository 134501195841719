import { MutationTree } from 'vuex'
import { loadableMutation, paginationMutation } from '@/store/mutations'
import { Document, GroupDocuments, GroupNotes, Note } from '@/store/modules/groupDocuments/types'
import { FilterDateModel } from '@/components/common/ui/FilterDate/filterDate'

export const groupDocumentsMutation: MutationTree<GroupDocuments> = {
  setDocuments(state, documents: Document[]) {
    state.documents = documents
  },
  setFilterDates(state, filterDates: FilterDateModel) {
    state.filterDates = filterDates
  },
  ...loadableMutation,
  ...paginationMutation,
}

export const groupNotesMutation: MutationTree<GroupNotes> = {
  setNotes(state, notes: Note[]) {
    state.notes = notes
  },
  setFilterDates(state, filterDates: FilterDateModel) {
    state.filterDates = filterDates
  },
  ...loadableMutation,
  ...paginationMutation,
}
