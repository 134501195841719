import moment from 'moment'
import { RootState } from '@/store/types'
import { Module, MutationTree } from 'vuex'
import {
  GroupProducts,
  Product,
  SelectedPlanOption,
  PlanOptionDetails,
  GroupProductDetail,
} from '@/store/modules/groupProducts/types'
import { loadableMutation, paginationMutation } from '@/store/mutations'
import { actions } from './actions'
import { DocumentDto } from '@/autogen-interfaces'
import { PageableComponentInterface } from '@/store/types'

const state: GroupProducts = {
  dateFilter: moment().toDate(),
  totalElements: 0,
  totalPages: 0,
  loading: false,
  products: [] as Product[],
  selectedPlan: {
    plan: {} as PlanOptionDetails,
    planDocumentsTable: {
      items: [] as DocumentDto[],
      loading: false,
      totalElements: 0,
      totalPages: 0,
    },
    planDetailsTable: {
      items: [] as GroupProductDetail[],
      loading: false,
    },
  } as SelectedPlanOption,
}

const mutations: MutationTree<GroupProducts> = {
  setProducts(state, products: Product[]) {
    state.products = products
  },
  setDateFilter(state, dateFilter: Date) {
    state.dateFilter = dateFilter
  },
  setSelectedPlan(state, selectedProduct: PlanOptionDetails) {
    state.selectedPlan.plan = selectedProduct
  },
  ...loadableMutation,
  ...paginationMutation,
  setPlanDocumentsLoading(state, loading: boolean) {
    state.selectedPlan.planDocumentsTable.loading = loading
  },
  setPlanDocuments(state, documents: DocumentDto[]) {
    state.selectedPlan.planDocumentsTable.items = documents
  },
  setPlanDocumentsPagination(state, pagination: PageableComponentInterface) {
    state.selectedPlan.planDocumentsTable.totalElements = pagination.totalElements
    state.selectedPlan.planDocumentsTable.totalPages = pagination.totalPages
  },
  setPlanDetailsLoading(state, loading: boolean) {
    state.selectedPlan.planDetailsTable.loading = loading
  },
  setPlanDetails(state, planDetails: GroupProductDetail[]) {
    state.selectedPlan.planDetailsTable.items = planDetails
  },
}

export const groupProducts: Module<GroupProducts, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}
