import { formatDate } from '@/helpers'
import { GroupContactsDto, GroupVendorsDto } from '@/autogen-interfaces'
import { Contact, Vendor } from '@/store/modules/groupContactsVendors/types'

export class ContactEntityToDto {
  public static convert(groupContactsDto: GroupContactsDto): Contact {
    return {
      address: this.getContactAddress(groupContactsDto),
      contactType: this.nullCheck(groupContactsDto.contactType),
      email: this.nullCheck(groupContactsDto.contactEmailAddress),
      expiredDate: groupContactsDto.expiredDate ? formatDate(groupContactsDto.expiredDate) : '',
      fax: groupContactsDto.contactFax ? groupContactsDto.contactFax : '-1',
      name:
        this.nullCheck(groupContactsDto.contactFirstName) +
        ' ' +
        this.nullCheck(groupContactsDto.contactLastName),
      phone: groupContactsDto.contactPhone ? groupContactsDto.contactPhone : '-1',
    }
  }

  private static getContactAddress({
    contactMailingAddr1 = '',
    contactMailingAddr2 = '',
    contactMailingAddrCity = '',
    contactMailingAddrState = '',
    contactMailingAddrZip = -1,
  }: GroupContactsDto): string {
    return (
      this.nullCheck(contactMailingAddr1) +
      ' ' +
      this.nullCheck(contactMailingAddr2) +
      ' <br/>' +
      this.nullCheck(contactMailingAddrCity) +
      ', ' +
      this.nullCheck(contactMailingAddrState) +
      ' ' +
      contactMailingAddrZip
    )
  }

  private static nullCheck(value: string | null | undefined): string {
    return value || ''
  }
}

export class VendorEntityToDto {
  public static convert(groupVendorsDto: GroupVendorsDto): Vendor {
    return {
      address: '',
      contactName: this.nullCheck(groupVendorsDto.contactName),
      emailAddress: this.nullCheck(groupVendorsDto.emailAddress),
      phoneNumber: this.nullCheck(groupVendorsDto.phoneNumber),
      groupVendorId: groupVendorsDto.groupVendorId ? groupVendorsDto.groupVendorId : -1,
      effectiveDate: groupVendorsDto.startDate ? formatDate(groupVendorsDto.startDate) : '',
      endDate: groupVendorsDto.endDate ? formatDate(groupVendorsDto.endDate) : '',
      legalName: this.nullCheck(groupVendorsDto.legalName),
      notes: this.nullCheck(groupVendorsDto.notes),
      serviceName: this.nullCheck(groupVendorsDto.serviceDesc),
    }
  }

  private static nullCheck(value: string | null | undefined): string {
    return value || ''
  }
}
