import Vue, { PropType } from 'vue'
import {
  GridFilter,
  Operators,
  PageableComponentInterface,
  PaginationFilter,
  RequestFilter,
} from '@/store/types'
import Pagination from '@/components/common/ui/Pagination/Index.vue'

export interface FilterableDataTableHeader {
  text: string
  value: string
  align?: 'start' | 'center' | 'end'
  class?: string | string[]
  cellClass?: string | string[]
  width?: string | number
  sortable?: boolean
  filterable?: 'text' | 'dropdown'
  inputRegex?: string // for date ##/##/## for phoneNumber (###)-###-####
  placeHolder?: string
  filterColumn?: string
  selectOptions?: SelectOption[]
}

export interface SelectOption {
  text: string
  value: string | number
}

export default Vue.extend({
  components: { Pagination },
  name: 'dataTable',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object as PropType<PageableComponentInterface>,
      default: () => ({
        totalElements: 10,
        totalPages: 1,
      }),
    },
  },
  data: () => ({
    filters: {} as any,
    itemsPerPage: 10,
    page: 1,
  }),
  mounted() {
    this.$emit('request-filter', { ...this.requestFilter })
  },
  methods: {
    updateFilters(): void {
      this.$emit('request-filter', { ...this.requestFilter })
    },
    clearFilter(prop: string): void {
      this.filters[prop] = undefined
      this.updateFilters()
    },
  },
  computed: {
    paginationFilter(): PaginationFilter {
      return {
        page: this.page,
        pageSize: this.itemsPerPage,
        skip: 0,
        take: this.itemsPerPage,
      }
    },
    requestFilter(): RequestFilter {
      return {
        sort: [],
        filter: [...this.updatedFilters],
        ...this.paginationFilter,
      }
    },
    updatedFilters(): GridFilter[] {
      const updatedFilters: GridFilter[] = []
      Object.entries(this.filters).forEach(([key, value]) => {
        if (value && value !== '') {
          updatedFilters.push({
            field: key,
            filters: [],
            logic: 'AND',
            operator: Operators.EQUAL,
            value: value as string,
          })
        }
      })
      return updatedFilters
    },
    attributeHeaders(): FilterableDataTableHeader[] {
      const attributeHeaders = this.$attrs.headers as unknown as FilterableDataTableHeader[]
      attributeHeaders.forEach((header) => {
        if (!header.sortable) {
          // default sortable to false
          header.sortable = false
        }
      })
      return attributeHeaders
    },
  },
  watch: {
    paginationFilter(): void {
      this.updateFilters()
    },
  },
})
