import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { VueMaskDirective } from 'v-mask'
import { AppData } from '@/AppData'
import UserApi from '@/api/UserApi'

Vue.directive('mask', VueMaskDirective)
Vue.config.productionTip = false

const queryParams = new URLSearchParams(window.location.search)
const username = queryParams.get('username') || 'casey.bright@solutainc.com'
const password = queryParams.get('password') || 'Sunshine1!'

const load = async () => {
  // Force login with casey.bright. Use this token to password reset the user we actually want to use.
  // const fakeAuthResponse = await UserApi.auth('casey.bright@solutainc.com', 'Sunshine1!')
  // AppData.setAuth(fakeAuthResponse.data)
  // await UserApi.updatePassword(username, password, password)

  // Now that casey.bright's login has set the password for the user of interest, log in with that user.
  const authResponse = await UserApi.auth(username, password)
  AppData.setAuth(authResponse.data)

  const userAssociations = await UserApi.userAssociations(authResponse.data.id)
  AppData.setAssociations(userAssociations.data)

  new Vue({
    router,
    vuetify,
    store,
    render: (h) => h(App),
  }).$mount('#app')
}

load()
