import { Enrollment } from './types'

export const state: Enrollment = {
  loading: true,
  totalElements: 10,
  totalPages: 1,
  person: {
    name: '',
    memberId: '',
    city: '',
    groupId: '',
    groupPolicyId: '',
    groupType: '',
    active: false,
    role: '',
  },
  items: [],
  availableMembers: [],
  selectedMember: 0,
  selectedDocument: {},
  currentMember: {},
}
