export const base64ToBlob = (
  b64Data: string,
  contentType = '',
  docName: string,
  docType: string,
  sliceSize = 512,
) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })

  const blobUrl = URL.createObjectURL(blob)
  const a: HTMLAnchorElement = document.createElement('a')
  document.body.appendChild(a)
  a.setAttribute('style', 'display: none;')
  a.href = blobUrl
  a.download = `${docName}.${docType}`
  a.click()
  window.URL.revokeObjectURL(blobUrl)
  a.remove()
}
