import Vue from 'vue'
import HeaderBasic from '@/components/common/ui/HeaderBasic/Index.vue'
import SearchBar from '@/components/common/ui/SearchBar/Index.vue'

export default Vue.extend({
  props: {
    placeHolder: {
      type: String,
      default: 'Search keyword',
    },
    items: {
      type: Array,
    },
  },
  components: {
    HeaderBasic,
    SearchBar,
  },
  data: () => ({
    value: '',
  }),
  methods: {
    handleInput: function (): void {
      this.$emit('input', this.value ? this.value : '')
    },
  },
})
