import moment from 'moment'
import { RootState } from '@/store/types'
import {
  EnrollmentStatus,
  EnrollmentStatusPage,
  Actions,
} from '@/store/modules/enrollmentStatus/types'
import { ApiError, ActionTreeAdaptor } from '@/store/utils/types'
import { MemberStatusDto } from '@/autogen-interfaces'
import { withAsync } from '@/store/utils/withAsync'
import EnrollmentApi from '@/api/EnrollmentApi'
import { MemberStatusToDto } from '@/store/modules/enrollmentStatus/MemberStatusToDto'

export const actions: ActionTreeAdaptor<Actions, EnrollmentStatusPage, RootState> = {
  async fetchMemberStatus({ commit }, { memberId, memberType, requestFilter }) {
    commit('setLoading', true)
    const { error, data }: { error: ApiError; data: MemberStatusDto[] } = await withAsync(
      EnrollmentApi.fetchMemberStatus,
      EnrollmentApi,
      memberId,
      memberType,
      requestFilter,
    )
    if (error) {
      console.error(error)
    } else {
      const enrollmentStatuses: EnrollmentStatus[] = []
      for (const enrollmentStatus of data) {
        enrollmentStatuses.push(MemberStatusToDto.convert(enrollmentStatus))
      }
      // Sorting Statuses by date
      enrollmentStatuses.sort(function (a, b) {
        if (a.toDate === null) {
          return -1
        }
        if (b.toDate === null) {
          return 1
        }
        const aDate = moment(a.toDate, 'MM/DD/YYYY').toDate()
        const bDate = moment(b.toDate, 'MM/DD/YYYY').toDate()
        return bDate.getTime() - aDate.getTime()
      })
      commit('setStatusHistory', enrollmentStatuses)
      // Looking for current status, which is the one with null at toDate prop
      if (enrollmentStatuses.length > 1) {
        for (const enrollmentStatus of enrollmentStatuses) {
          if (enrollmentStatus.toDate === null) {
            enrollmentStatus.isActive = true
            enrollmentStatus.toDate = 'present'
            commit('setCurrentStatus', enrollmentStatus)
            break
          }
        }
      } else {
        // If there is only one status, it is the current status
        const enrollmentStatus = enrollmentStatuses[0]
        if (enrollmentStatus) {
          enrollmentStatus.isActive = true
          enrollmentStatus.toDate = 'present'
          commit('setCurrentStatus', enrollmentStatus)
        } else {
          commit('setCurrentStatus', {
            employeeStatusId: 'No data',
            isActive: false,
            title: 'No data',
            fromDate: 'No data',
            toDate: 'No data',
            division: 'No data',
            employeeClass: 'No data',
            benefitGroup: 'No data',
            salary: 'No data',
            audit: {
              addedBy: 'No data',
              updatedBy: 'No data',
              addedDate: 'No data',
              updatedDate: 'No data',
            },
            statusNote: 'No data',
            role: '',
          })
        }
      }
    }
    commit('setLoading', false)
  },
}
