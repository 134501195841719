import Vue from 'vue'
import { base64ToBlob, formatDate, formatMoney } from '@/helpers'
import Tag from '@/components/common/ui/Tag/Index.vue'
import Drawer from '@/components/common/ui/Drawer/Index.vue'
import { BillingStatementGridDto } from '@/autogen-interfaces'
import DataTable from '@/components/common/ui/DataTable/Index.vue'
import { PageableComponentInterface, RequestFilter } from '@/store/types'
import { BillingStatementsPaymentsPage } from '@/store/modules/billing/types'
import { FilterableDataTableHeader } from '@/components/common/ui/DataTable/dataTable'

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  components: { Drawer, DataTable, Tag },
  data: () => ({
    tabModel: 'tabPayments',
    dataTableHeader: [
      { text: 'Deposit', width: '15%', value: 'deposit' },
      { text: 'Deposit Date', width: '15%', value: 'depositDate' },
      { text: 'Posted', width: '15%', value: 'posted' },
      { text: 'Deposit Amount', width: '15%', value: 'depositAmount' },
      { text: 'Check Number', width: '15%', value: 'checkNumber' },
      { text: 'Status', value: 'status' },
    ] as FilterableDataTableHeader[],
    filters: {} as RequestFilter,
  }),
  computed: {
    selectedBillingStatement(): BillingStatementGridDto {
      return this.$store.state.billing.billingStatements.selectedItem
    },
    selectedItemDetail(): BillingStatementsPaymentsPage {
      return this.$store.state.billing.billingStatements.selectedItemDetail
    },
    loading(): boolean {
      return this.$store.state.billing.loading
    },
    pagination(): PageableComponentInterface {
      return {
        totalElements: this.$store.state.billing.billingStatements.selectedItemDetail.totalElements,
        totalPages: this.$store.state.billing.billingStatements.selectedItemDetail.totalPages,
      }
    },
  },
  methods: {
    formatDate(date: string) {
      return date ? formatDate(date, 'MM/DD/YYYY') : ''
    },
    formatMoney: function (number: number): string {
      return number ? formatMoney(number) : '$0.00'
    },
    async downloadInvoice(invoiceNumber: string) {
      await this.$store.dispatch('billing/fetchInvoiceData', invoiceNumber)
      if (
        this.$store.state.billing.billingStatements.selectedItemDetail.downloadInvoice &&
        this.$store.state.billing.billingStatements.selectedItemDetail.downloadInvoice.documentData
      ) {
        const document =
          this.$store.state.billing.billingStatements.selectedItemDetail.downloadInvoice
        base64ToBlob(
          document.documentData,
          document.mimeType,
          document.documentName || 'document',
          document.fileExtension,
        )
      }
    },
  },
  watch: {
    async filters() {
      await this.$store.dispatch('billing/getBillingStatementDetails', this.filters)
    },
    async selectedBillingStatement() {
      await this.$store.dispatch('billing/getBillingStatementDetails', this.filters)
    },
  },
})
