import GroupsApi from '@/api/GroupsApi'
import DocumentsApi from '@/api/DocumentsApi'

import { withAsync } from '@/store/utils/withAsync'

import { RootState } from '@/store/types'
import { GroupProductsDto, DocumentDto, GroupPlanOptionsDto } from '@/autogen-interfaces'
import { ApiError, ActionTreeAdaptor } from '@/store/utils/types'
import { GroupProducts, Product, Actions } from '@/store/modules/groupProducts/types'
import ProductEntityToDto from '@/store/modules/groupProducts/ProductEntityToDto'

export const actions: ActionTreeAdaptor<Actions, GroupProducts, RootState> = {
  async fetchGroupProducts({ commit, rootState }, { groupProductFilters }) {
    commit('setLoading', true)
    const groupId = rootState.selectedUserGroupId
    const { error, data }: { error: ApiError; data: GroupProductsDto[] } = await withAsync(
      GroupsApi.fetchGroupProducts,
      GroupsApi,
      groupId,
      groupProductFilters,
    )
    if (error) {
      console.error(error)
    } else {
      const products: Product[] = []
      const productEntityToDto = new ProductEntityToDto()
      for (const groupProduct of data) {
        products.push(productEntityToDto.convert(groupProduct))
      }
      commit('setProducts', products)
      commit('setPagination', {
        totalElements: products.length,
        totalPages: 1,
      })
    }
    commit('setLoading', false)
  },

  async fetchPlanDocuments({ commit }, { gppoid, groupId, filter }) {
    commit('setPlanDocumentsLoading', true)
    const payload = {
      GroupProductPlanOptionID: gppoid,
      GroupID: groupId,
    }
    const { error, data }: { error: ApiError; data: DocumentDto[] } = await withAsync(
      DocumentsApi.fetchDocuments,
      DocumentsApi,
      payload,
      filter,
    )
    if (error) {
      console.error(error)
    } else {
      commit('setPlanDocumentsLoading', false)
      commit('setPlanDocuments', data)
      commit('setPlanDocumentsPagination', {
        totalElements: data.length,
        totalPages: 1,
      })
    }
  },

  async fetchGroupPlanOptions({ commit }, { groupId }) {
    commit('setPlanDetailsLoading', true)
    const { error, data }: { error: ApiError; data: GroupPlanOptionsDto[] } = await withAsync(
      GroupsApi.fetchGroupPlanOptions,
      GroupsApi,
      groupId,
    )
    if (error) {
      console.error(error)
    } else {
      const productEntityToDto = new ProductEntityToDto()
      const planDetails = productEntityToDto.convertGroupProductDetail(data)
      commit('setPlanDetailsLoading', false)
      commit('setPlanDetails', planDetails)
    }
  },
}
