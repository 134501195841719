import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { EnrollmentFringeState, Actions } from './types'
import {
  EmployeeFringeCoveragePeriodDto,
  EmployeeFringeCoveragePeriodDetailDto,
} from '@/autogen-interfaces'
import { ApiError, ActionTreeAdaptor } from '@/store/utils/types'

import EnrollmentApi from '@/api/EnrollmentApi'
import { withAsync } from '@/store/utils/withAsync'

import { loadableMutation, pageableMutation } from '@/store/mutations'

export const enrollmentFringe: Module<EnrollmentFringeState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    page: -1,
    pageCount: -1,
    itemsPerPage: -1,
    fringe: [],
  },
  mutations: {
    clearCoveragePeriod(state) {
      state.fringe = []
    },
    setCoveragePeriods(state, periods: EmployeeFringeCoveragePeriodDto[]) {
      periods.forEach((period: EmployeeFringeCoveragePeriodDto) => {
        state.fringe.push({
          fringeCoveragePeriod: period,
          fringeCoveragePeriodDetails: [] as EmployeeFringeCoveragePeriodDetailDto[],
          totalHours: 0,
          totalFringe: 0,
          totalForPeriod: 0,
        })
      })
    },
    addCoveragePeriodDetails(
      state,
      payload: { details: EmployeeFringeCoveragePeriodDetailDto[]; index: number },
    ) {
      state.fringe[payload.index].fringeCoveragePeriodDetails = payload.details
      payload.details.forEach((detail: EmployeeFringeCoveragePeriodDetailDto) => {
        state.fringe[payload.index].totalHours += detail.hoursAllocated || 0
        state.fringe[payload.index].totalFringe += detail.rate || 0
        state.fringe[payload.index].totalForPeriod +=
          (detail.hoursAllocated || 0) * (detail.rate || 0)
      })
    },
    ...loadableMutation,
    ...pageableMutation,
  },
  actions: {
    async fetchFringePeriods({ commit, dispatch }, { memberId, filter }) {
      commit('setLoading', true)
      const { error, data }: { error: ApiError; data: EmployeeFringeCoveragePeriodDto[] } =
        await withAsync(EnrollmentApi.fetchMemberFringePeriods, EnrollmentApi, memberId, filter)
      if (error) {
        console.error(error)
      } else {
        commit('clearCoveragePeriod')
        commit('setCoveragePeriods', data)
        data.forEach((period: EmployeeFringeCoveragePeriodDto, i: number) => {
          dispatch('fetchFringePeriodDetails', {
            memberId,
            invoiceId: period.fringeInvoiceNumber,
            index: i,
            beginDate: period.coverageBeginDate,
            endDate: period.coverageEndDate,
            filter: {
              page: 1,
              pageSize: 20,
              skip: 0,
              take: 10,
              sort: [],
              filter: [],
            },
          })
        })
      }
      commit('setLoading', false)
    },
    async fetchFringePeriodDetails(
      { commit },
      { memberId, invoiceId, beginDate, endDate, filter, index },
    ) {
      commit('setLoading', true)
      const { error, data }: { error: ApiError; data: EmployeeFringeCoveragePeriodDetailDto[] } =
        await withAsync(
          EnrollmentApi.fetchMemberFringePeriodDetails,
          EnrollmentApi,
          memberId,
          invoiceId,
          beginDate,
          endDate,
          filter,
        )
      if (error) {
        console.error(error)
      } else {
        commit('addCoveragePeriodDetails', { details: data, index })
      }
      commit('setLoading', false)
    },
  } as ActionTreeAdaptor<Actions, EnrollmentFringeState, RootState>,
}
