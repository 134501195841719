import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { GridFilter, GridFilterQuery, GridHelperQuery, RequestFilter } from '@/store/types'
import { AppData } from '@/AppData'
import Configuration from '@/Configuration'

export class Api {
  axios: AxiosInstance

  // TODO enable when auth is implemented in APIs
  AUTH_ENABLED = true

  static BASE_URL = Configuration.value('backendHost')
  //static BASE_URL = 'http://localhost:4000/api/v1'
  //static BASE_URL = process.env.API_URL
  // static BASE_URL = 'https://insighttest.solutainc.com/api/v1'
  // static BASE_URL =
  //   process.env.NODE_ENV === 'production'
  //     ? 'https://soluta-insight.herokuapp.com/api/v1'
  //     : 'http://localhost:3000/api/v1'

  constructor() {
    this.axios = axiosInstance
  }

  convertGridFilterToGridFilterQuery(requestFilter: GridFilter): GridFilterQuery {
    return {
      Field: requestFilter.field,
      Filters: [],
      Logic: requestFilter.logic,
      Operator: requestFilter.operator,
      Value: requestFilter.value,
    }
  }

  get(url: string, params?: any) {
    let encodedParams: any = null
    if (params) {
      encodedParams = {}
      for (const prop in params) {
        let value = params[prop]
        if (prop === 'GridHelper') {
          const gridHelper: RequestFilter = params[prop]
          const gridHelperQuery: GridHelperQuery = {
            Filter: null,
            Page: gridHelper.page,
            PageSize: gridHelper.pageSize,
            Skip: gridHelper.skip,
            Sort: gridHelper.sort,
            Take: gridHelper.take,
          }
          for (const filter of gridHelper.filter) {
            if (!gridHelperQuery.Filter) {
              gridHelperQuery.Filter = {
                ...this.convertGridFilterToGridFilterQuery(filter),
              }
            } else {
              gridHelperQuery.Filter.Filters.push({
                ...this.convertGridFilterToGridFilterQuery(filter),
              })
            }
          }
          value = gridHelperQuery
        }
        encodedParams[prop] = value
      }
    }

    if (encodedParams) console.log(encodedParams)

    const headers: any = {}
    if (this.AUTH_ENABLED) {
      headers.Authorization = `Bearer ${AppData.auth.token}`
    }
    return this.axios.get(url, {
      headers,
      params: encodedParams
        ? {
            encodedParams: btoa(JSON.stringify(encodedParams)),
          }
        : {},
    })
  }

  post(url: string, data?: any, config?: AxiosRequestConfig, encodedParams?: any) {
    if (encodedParams) console.log(encodedParams)

    const headers: any = {}
    if (url.indexOf('Users/Auth') < 0 && this.AUTH_ENABLED) {
      headers.Authorization = `Bearer ${AppData.auth.token}`
    }
    return this.axios.post(url, data, {
      headers,
      params: encodedParams
        ? {
            encodedParams: btoa(JSON.stringify(encodedParams)),
          }
        : {},
      ...config,
    })
  }

  put(url: string, data?: any, config?: AxiosRequestConfig) {
    const headers: any = {}
    if (this.AUTH_ENABLED) {
      headers.Authorization = `Bearer ${AppData.auth.token}`
    }
    return this.axios.put(url, data, {
      headers,
      ...config,
    })
  }

  delete(url: string, config?: AxiosRequestConfig) {
    const headers: any = {}
    if (this.AUTH_ENABLED) {
      headers.Authorization = `Bearer ${AppData.auth.token}`
    }
    return this.axios.delete(url, {
      headers,
      ...config,
    })
  }
}

export const axiosInstance: AxiosInstance = axios.create({ baseURL: Api.BASE_URL })
