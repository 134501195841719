var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"col-2 ml-auto"},[_c('filter-single-date',{model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-12"},[_c('data-table',{attrs:{"loading":_vm.loading,"headers":_vm.dataTableHeader,"items":_vm.items,"item-key":"invoiceNumber","pagination":_vm.pagination},on:{"request-filter":function($event){_vm.filters = $event}},scopedSlots:_vm._u([{key:"item.billDate",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.billDate))])]}},{key:"item.billStatus",fn:function(ref){
var item = ref.item;
return [_c('tag',{attrs:{"error":item.billStatus === 'Past Due',"warning":item.billStatus === 'Pending',"success":item.billStatus === 'Completed'}},[_vm._v(_vm._s(item.billStatus))])]}},{key:"item.netDue",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.netDue))])]}},{key:"item.billingRecordTotal",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.billingRecordTotal))])]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.downloadInvoice(item.invoiceNumber)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-tray-arrow-down")])],1)]}},{key:"item.expand",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.setSelectedGroupBillingHistoryDto(item)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}],null,true)})],1)],1),_c('billing-history-details',{attrs:{"show":_vm.showDetails},on:{"download-invoice":function($event){return _vm.downloadInvoice($event)},"closeDrawer":function($event){_vm.showDetails = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }