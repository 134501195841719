import { RouteConfig } from 'vue-router'

import Group from '@/views/groups/Groups.vue'

import GroupInfo from '@/views/groups/group-info/GroupInfo.vue'
import GroupInfoGeneral from '@/views/groups/group-info/GroupInfoGeneral.vue'
import GroupInfoSettings from '@/views/groups/group-info/GroupInfoSettings.vue'
import GroupStructure from '@/views/groups/group-structure/GroupStructure.vue'
import GroupStructureDivision from '@/views/groups/group-structure/division/GroupStructureDivision.vue'
import GroupStructureClasses from '@/views/groups/group-structure/classes/GroupStructureClasses.vue'
import GroupStructureBenefitGroups from '@/views/groups/group-structure/benefits/GroupStructureBenefitGroups.vue'
import BillingHistory from '@/views/groups/billing-history/BillingHistory.vue'
import BillingHistoryBillingHistory from '@/views/groups/billing-history/BillingHistoryBillingHistory.vue'
import BillingHistorySuspense from '@/views/groups/billing-history/BillingHistorySuspense.vue'
import Products from '@/views/groups/products/Products.vue'
import ContactsVendors from '@/views/groups/contacts-vendors/ContactsVendors.vue'
import ContactsVendorsContacts from '@/views/groups/contacts-vendors/contacts/Contacts.vue'
import ContactsVendorsVendors from '@/views/groups/contacts-vendors/vendors/GroupVendors.vue'
import Documents from '@/views/groups/documents/Documents.vue'
import DocumentsDocuments from '@/views/groups/documents/documents/DocumentsDocuments.vue'
import DocumentsNotes from '@/views/groups/documents/notes/DocumentsNotes.vue'

const groupRoutes: Array<RouteConfig> = [
  {
    path: '/group',
    name: 'Group',
    redirect: '/group/info',
    component: Group,
    children: [
      {
        path: '/group/info',
        name: 'Group - Info',
        redirect: '/group/info/general',
        component: GroupInfo,
        children: [
          {
            path: '/group/info/general',
            name: 'Group - Info - General',
            component: GroupInfoGeneral,
          },
          {
            path: '/group/info/address',
            name: 'Group - Group Info - Address',
            component: GroupInfoSettings,
          },
        ],
      },
      {
        path: '/group/structure',
        name: 'Group - Structure',
        redirect: '/group/structure/division',
        component: GroupStructure,
        children: [
          {
            path: '/group/structure/division',
            name: 'Group - Group Structure - Division',
            component: GroupStructureDivision,
          },
          {
            path: '/group/structure/classes',
            name: 'Group - Group Structure - Classes',
            component: GroupStructureClasses,
          },
          {
            path: '/group/structure/benefit-groups',
            name: 'Group - Group Structure - Benefit Groups',
            component: GroupStructureBenefitGroups,
          },
        ],
      },
      {
        path: '/group/products',
        name: 'Group - Products',
        component: Products,
        children: [
          {
            path: '/group/products',
            name: 'Group - Products - Products',
            component: Products,
          },
        ],
      },
      {
        path: '/group/billing-history',
        name: 'Group - Billing History',
        component: BillingHistory,
        children: [
          {
            path: '/group/billing-history',
            name: 'Group - Billing History - Billing History',
            component: BillingHistoryBillingHistory,
          },
          {
            path: '/group/billing-history/suspense',
            name: 'Group - Billing History - Suspense',
            component: BillingHistorySuspense,
          },
        ],
      },
      {
        path: '/group/contacts-vendors',
        name: 'Group - Contacts',
        component: ContactsVendors,
        redirect: '/group/contacts-vendors/contacts',
        children: [
          {
            path: '/group/contacts-vendors/contacts',
            name: 'Group - Contacts - Contacts',
            component: ContactsVendorsContacts,
          },
          {
            path: '/group/contacts-vendors/vendors',
            name: 'Group - Contacts - Vendors',
            component: ContactsVendorsVendors,
          },
        ],
      },
      {
        path: '/group/documents',
        name: 'Group - Documents',
        component: Documents,
        children: [
          {
            path: '/group/documents',
            name: 'Group - Documents - Documents',
            component: DocumentsDocuments,
          },
          {
            path: '/group/documents/notes',
            name: 'Group - Documents - Notes',
            component: DocumentsNotes,
          },
        ],
      },
    ],
  },
]

export default groupRoutes
